import styled from 'styled-components';
import { MdChat } from 'react-icons/md';
interface TableProps {
  index: number;
}

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .mestres-modal {
    color: red;
  }

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const ContentInfos = styled.div`
  button {
    background-color: #21e270;
    color: #fff;

    &:disabled {
      background-color: #c7c7cc;
    }
  }
`;

export const ContentPreview = styled.div``;

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 1.25rem;
  font-family: 'FontsFreeNetSFProTextMedium';
  color: #8e8e93;
`;

export const Subtitle = styled.p`
  font-size: 23px;
  margin-bottom: 1.25rem;
  color: #8e8e93;
  font-family: 'FontsFreeNetSFProTextLight';
`;

export const LocationItem = styled.div`
  width: 159px;
  height: 159px;
  background: #dfffec;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'FontsFreeNetSFProTextMedium';

  h1 {
    color: #4cd964;

    font-size: 63px;
  }

  p {
    color: #4cd964;
    font-size: 27px;
  }
`;

export const TableItem = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 17px;
  background: #21e270;

  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  > div {
    display: flex;

    margin-right: 20px;
  }

  svg {
    fill: #fff;
  }

  span {
    font-size: 12px;
    color: #fff;
  }

  &#not-avaliable {
    background: #cccccc;
    color: #fff;
    cursor: not-allowed;

    &.is-selected {
      border: none;
      color: #fff;

      span {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }

    &:hover {
      border: none;
      color: #fff;
    }
  }

  &:hover {
    border: 1px solid #21e270;
    cursor: pointer;
    background: #dfffec;

    span {
      color: #21e270;
    }

    svg {
      fill: #21e270;
    }
  }

  &.is-selected {
    border: 1px solid #21e270;
    cursor: pointer;
    background: #dfffec;

    span {
      color: #21e270;
    }

    svg {
      fill: #21e270;
    }
  }
`;

export const TablePreviewContent = styled.div<TableProps>`
  background: #f3f3f3;
  height: 580px;
  min-width: 590px;
  border-radius: 27px;
  align-items: ${({ index }) => (index > 3 ? 'center' : 'baseline')};
  justify-content: flex-start;
  padding: 2.1875rem 3rem;
  overflow: auto;

  @media screen and (max-width: 720px) {
    min-width: unset;
  }

  > div {
    width: 100%;

    gap: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media screen and (max-width: 720px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ItemStatusIcon = styled(MdChat)``;

export const Icon = styled.img`
  height: 90px;
  display: block;
  margin-top: 20px;
  margin: 20px auto 0px auto;
`;
