import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { WrapperPage } from '../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../shared/components/Input/Input';
import { Button } from '../../shared/components/Button/Button';
import { Select } from '../../shared/components/Select/Select';
import { SelectHour } from '../../shared/components/SelectHour/SelectHour';
import { SuccessModal } from '../../shared/components/SuccessModal/SuccesModal';

import {
  Title,
  Row,
  FormContainer,
  Form,
  RowInput,
  InputContainer,
  Next,
  Step,
  SelectContainer,
  ServicesDayContainer,
  Informations,
  SelectHourContainer,
  RowInputHour,
} from './_AdditionalInformation';

export const AdditionalInformation = () => {
  const history = useHistory();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const categories = [
    'Pizzarias',
    'Carnes e Massas',
    'Sushi',
    'Lanches',
    'Bebidas',
  ];

  const days = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];

  const handleSaveAdditionalInformations = () => {
    setShowSuccessModal(true);
  };

  return (
    <WrapperPage title="Cadastro">
      <Title>Informações Adicionais</Title>
      <Row>
        <FormContainer>
          <Form>
            <p>Categoria</p>
            <label>
              Selecione qual a <span>categoria geral</span> do seu
              estabelecimento.
            </label>
            <label>Categoria Geral:</label>
            <SelectContainer>
              <Select placeholder="Selecione a categoria" data={categories} />
            </SelectContainer>
            <p>Capacidade</p>
            <label>
              Insira qual a <span>quantidade total</span> de lugares do seu
              estabelecimento.
            </label>
            <label>Capacidade de Atendimento:</label>
            <InputContainer>
              <Input type="number" placeholder="Nº de pessoas.." />
            </InputContainer>
          </Form>
        </FormContainer>
        <ServicesDayContainer>
          <Informations>
            <div>Funcionamento</div>
            <label>
              Defina os dias e<br /> horários de funcionamento
            </label>
            <label style={{ fontSize: '1.4rem' }}>Dias de Funcionamento:</label>
            <label>
              Você pode selecionar
              <br /> quantos quiser
            </label>
            <SelectContainer>
              <Select mode="multiple" data={days} />
            </SelectContainer>
            <label>Horário de Funcionamnto:</label>
            <RowInputHour>
              <SelectHour /> <div>ás</div> <SelectHour />
            </RowInputHour>
          </Informations>
          <Button onClick={() => handleSaveAdditionalInformations()}>
            Salvar Alterações
          </Button>
        </ServicesDayContainer>
      </Row>
      <SuccessModal
        openModal={showSuccessModal}
        setOpenModal={setShowSuccessModal}
        text="Alterações salvas com sucesso!"
      />
    </WrapperPage>
  );
};
