import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 1020px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 50px;

  @media screen and (max-width: 1020px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.8rem;
  }
`;
