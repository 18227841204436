import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin-top: 40px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 450px){
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 40px;

  @media (max-width: 450px){
    padding: 20px;
  }
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const Form = styled.form`
  > p {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.6rem;
    color: var(--greenText);
  }

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: var(--descriptionText);
    margin-top: 10px;
    display: block;
  }

  > button {
    width: 200px;
    height: 40px;
    background-color: var(--greenText);
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: #fff;
    overflow-wrap: anywhere;
    overflow: hidden;
  }

  > div:nth-of-type(1) > div{
    height: 44px;
    min-height: auto;
    max-width: 200px;
    width: 100%;
  }
`;

export const Next = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.4rem;
  color: #4CD964;
  text-align: right;
  margin-top: 40px;
  cursor: pointer;

  > svg {
    margin-left: 20px;
  }
`;

export const Step = styled.div`
  > img {
    display: block;
    margin: 0 auto;
  }

  > button {
    width: 260px;
  }

  @media (max-width: 1024px) {
    > button {
      margin: 20px auto 0 auto;
    }

    > img {
      margin-top: 20px;
    }
  }

  @media (max-width: 320px){
    > button {
      width: 100%;
    }
  }
`;

export const DocumentsContainer = styled.div`
  width: 100%;
  background-color: #EFEFF4;
  padding: 20px;
  border-radius: 30px;

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: var(--descriptionText);
    margin-top: 10px;
    display: block;
  }


  > button {
    width: 200px;
    height: 40px;
    background-color: var(--greenText);
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: #fff;
    overflow-wrap: anywhere;
    overflow: hidden;
  }

  @media (max-width: 450px){
    > button {
      width: 100%;
    }
  }
`;
