import React, { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { Modal } from 'antd';
import { useTableManagement } from '../../../../hooks/TableManagement';
import { SuccessModal } from 'shared/components/SuccessModal/SuccesModal';
import { Button } from 'shared/components/Button/Button';
import { LoadingOutlined } from '@ant-design/icons';
import {
  LastTableNumber,
  LastTableTitle,
  Wrapper,
} from './_ChangeTableNumberModal';
import { Input } from 'shared/components/Input/Input';

import './modal-responsive.css';
import { toast } from 'react-toastify';

interface ChangeTableNumberProps {
  tablesNumbers: number;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  handleTableNumber(): Promise<void>;
  handleCountTableNumber(e: FormEvent<HTMLInputElement>): void;
}

const ChangeTableNumberModal = ({
  tablesNumbers,
  isModalVisible,
  setIsModalVisible,
  handleCountTableNumber,
  handleTableNumber,
}: ChangeTableNumberProps) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    handleNewNumberOfTables,
    getEstablishmentTables,
    handleDeleteNumberOfTables,
  } = useTableManagement();

  const [successRequest, setSuccessRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newNumberTables, setNewNumberTables] = useState('');

  async function changeFullNumberOfTables() {
    setLoading(true);
    try {
      if (Number(newNumberTables) > tablesNumbers) {
        await handleNewNumberOfTables(Number(newNumberTables));
      } else {
        await handleDeleteNumberOfTables(Number(newNumberTables));
      }

      await getEstablishmentTables();
      setIsModalVisible(false);
      setSuccessRequest(true);
    } catch (error) {
      setSuccessRequest(false);
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <SuccessModal
        text="Alteração salva com sucesso!"
        openModal={successRequest}
        setOpenModal={setSuccessRequest}
      />
      <Modal
        visible={isModalVisible}
        footer={null}
        title={''}
        onCancel={handleCancel}
      >
        <Wrapper>
          <LastTableNumber>{tablesNumbers}</LastTableNumber>
          <div>
            <LastTableTitle>Alterar quantidade de mesas:</LastTableTitle>
            <Input
              onChange={(e) => setNewNumberTables(e.currentTarget.value)}
            />
          </div>
          <Button onClick={(e) => changeFullNumberOfTables()}>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Salvar Alterações'
            )}
          </Button>
        </Wrapper>
      </Modal>
    </>
  );
};

export default ChangeTableNumberModal;
