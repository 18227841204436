import styled from 'styled-components';
import { FiEdit3 } from 'react-icons/fi';

export const Content = styled.div``;

export const WrapperInput = styled.div`
  border-radius: 32px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  max-width: 336px;
  padding: 1rem;
  margin-top: 1rem;

  > div {
    width: 100%;
  }

  button {
    color: #fff;
    font-size: 18px;
    background: #21e270;
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: #8e8e93;
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.75rem;
  margin-bottom: 1.5625rem;
`;

export const Subtitle = styled.p`
  color: #8e8e93;
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1.125rem;
`;

export const SettledNumber = styled.div`
  background: #dfffec;
  width: 119px;
  height: 117px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1.5625rem;

  p {
    color: #4cd964;
    font-size: 56px;
  }

  span {
    margin-left: auto;
    margin-right: 11px;
    margin-top: -15px;
    cursor: pointer;
  }
`;

export const EditTableNumberIcon = styled(FiEdit3)`
  color: #4cd964;
  margin-left: auto;
`;
