import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin-left: 30px;
    width: 100%;

    @media (max-width: 1126px) {
      justify-content: center;
    }

    @media (max-width: 460px) {
      margin: 0px;
    }
`;

export const ShowMore = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  color: var(--greenText);
  margin: 20px auto;
  text-align: center;
  cursor: pointer;
`;
