import React, {
  useContext,
  createContext,
  useState,
  SetStateAction,
  Dispatch,
  useMemo,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { idText } from 'typescript';
import api from '../services/api';

import useSound from 'use-sound';
import Sound from '../assets/push.wav'

import socketio from 'socket.io-client';

import { useAuth } from './Auth';

interface SocketProps {
  iconsFlags: FlagProps;
  handleRefuseReservation: () => void;
  handleOrderPending: () => void;
  recallSolicitationList: boolean;
  setRecallSolicitationList: Dispatch<SetStateAction<boolean>>;
  recallOrderList: boolean;
  setRecallOrderList: Dispatch<SetStateAction<boolean>>;
  notificationsCheckin: any;
  notificationsResume: any;
  notificationsReview: any;
  modalCheckinOpen: any;
  modalReviewOpen: any;
  modalResumeOpen: any;
  setModalCheckinOpen: any;
  setModalReviewOpen: any;
  setModalResumeOpen: any;
  setNotificationsCheckin: any;
  setNotificationsResume: any;
  setNotificationsReview: any;
}

interface FlagProps {
  total_order_pending: number;
  total_reservation_pending: number;
}

const SocketContext = createContext<SocketProps>({} as SocketProps);

const SocketProvider: React.FC = ({ children }) => {
  const { data, setData } = useAuth();

  const [playOn] = useSound(Sound);

  const [notificationsCheckin, setNotificationsCheckin] = useState<any[]>([]);
  const [notificationsResume, setNotificationsResume] = useState<any[]>([]);
  const [notificationsReview, setNotificationsReview] = useState<any[]>([]);
  const [modalCheckinOpen, setModalCheckinOpen] = useState(false);
  const [modalReviewOpen, setModalReviewOpen] = useState(false);
  const [modalResumeOpen, setModalResumeOpen] = useState(false);

  const [iconsFlags, setIconsFlag] = useState<FlagProps>({} as FlagProps);
  const [recallSolicitationList, setRecallSolicitationList] =
    useState<boolean>(false);
  const [recallOrderList, setRecallOrderList] = useState<boolean>(false);

  const getNotificationValue = () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    api
      .get(
        `/establishments/${data.user.establishment?.id}/reservations/count`,
        { headers },
      )
      .then((response) => {
        setIconsFlag(response.data);
      })
      .catch((error) =>
        toast.error(error.response?.data?.message || error.toString()),
      );
  };

  const handleRefuseReservation = () => {
    if (iconsFlags.total_reservation_pending) {
      setIconsFlag({
        ...iconsFlags,
        total_reservation_pending: iconsFlags.total_reservation_pending - 1,
      });
    }
  };

  const handleOrderPending = () => {
    if (iconsFlags.total_order_pending) {
      setIconsFlag({
        ...iconsFlags,
        total_order_pending: iconsFlags.total_order_pending - 1,
      });
    }
  };

  const apiURL = process.env.REACT_APP_API as string;

  const socket = useMemo(
    () =>
      socketio(apiURL, {
        transports: ['websocket', 'polling'],
        query: {
          user_id: data?.user?.establishment?.id || '',
        },
      }),
    [data?.user?.establishment?.id],
  );


  useEffect(() => {
    socket.on('create_reservation', (notification) => {
      playOn();
      getNotificationValue();
      setRecallSolicitationList(true);
    });

    socket.on('create_order', (notification) => {
      playOn();
      getNotificationValue();
      setRecallOrderList(true);
    });

    socket.on('checkin_requested', (notification) => {
      playOn();
      setNotificationsCheckin(
        [notification, ...notificationsCheckin].slice(0, 4),
      );
      setModalCheckinOpen(true);
    });

    socket.on('add_payment_method_in_order', (notification) => {
      playOn();
      setNotificationsResume(
        [notification, ...notificationsResume].slice(0, 4),
      );
      setModalResumeOpen(true);
      setRecallOrderList(true);
    });

    socket.on('evaluate_customer', (notification) => {
      playOn();
      setNotificationsReview(
        [notification, ...notificationsReview].slice(0, 4),
      );
      setModalReviewOpen(true);
    });
  }, [socket]);

  useEffect(() => {
    if (data?.user?.establishment?.id) {
      getNotificationValue();
    }
  }, []);

  return (
    <SocketContext.Provider
      value={{
        iconsFlags,
        handleRefuseReservation,
        recallSolicitationList,
        handleOrderPending,
        setRecallSolicitationList,
        setRecallOrderList,
        recallOrderList,
        setNotificationsCheckin,
        setNotificationsResume,
        setNotificationsReview,
        notificationsCheckin,
        notificationsResume,
        modalCheckinOpen,
        modalResumeOpen,
        modalReviewOpen,
        notificationsReview,
        setModalCheckinOpen,
        setModalResumeOpen,
        setModalReviewOpen,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocketIo = (): SocketProps => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useFoodMenu must be used within an TableManagement');
  }

  return context;
};

export { SocketProvider, useSocketIo };
