import { Modal, notification } from 'antd';
import { useAuth } from '../../../../hooks/Auth';
import React, { useState, useEffect, useMemo } from 'react';
import socketio, { io } from 'socket.io-client';

import { AiFillStar } from 'react-icons/ai';

import photo from '../../../../shared/img/customer_photo.png';

import { ReactComponent as TableIcon } from '../../../../shared/icons/table-icon-modal-checkin.svg';

import {
  ModalContent,
  ReviewImg,
  ReviewSubtitle,
  ReviewText,
  ReviewTitle,
  ReviewStars,
} from './_ModalReview';

import { toast } from 'react-toastify';
import api from 'services/api';
import { Button } from 'shared/components/Button/Button';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { useSocketIo } from '../../../../hooks/Socket';

const ModalReview = ({
  modalReviewOpen,
  setModalReviewOpen,
  notifications,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState(0);
  const [hasTables, setHasTables] = useState<boolean | undefined>();
  const { data } = useAuth();

  const { setRecallSolicitationList, setRecallOrderList } = useSocketIo();

  const getEstablishmentTablesCheck = async () => {
    const today = new Date();

    const headers = { Authorization: `Bearer ${data?.accessToken}` };
    const response = await api.get(
      `/tables/available?date=${today}&establishmentId=${data?.user?.establishment?.id}`,
      {
        headers,
      },
    );

    if (response?.data?.length === 0) {
      setHasTables(false);
    } else {
      setHasTables(true);
    }
  };

  useEffect(() => {
    getEstablishmentTablesCheck();
  }, []);

  const handleReview = async () => {
    setLoading(true);

    try {
      const reservationId = notifications[0]?.id;
      const establishmentId = data?.user?.establishment?.id

      const headers = { Authorization: `Bearer ${data?.accessToken}` };
      await api.post(
        `/ratings/reservations`,
        {
          comment: '',
          evaluation: stars,
          establishmentId,
          reservationId,
        },
        { headers },
      );
      setRecallSolicitationList(true);
      setRecallOrderList(true);
      setModalReviewOpen(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.toString());
    } finally {
      setLoading(false);
    }
  };

  function renderReviewModal() {
    return notifications?.map((item: any, index: any) => (
      <ModalContent key={index}>
        <ReviewTitle>{item?.user?.first_name} finalizou sua comanda!</ReviewTitle>
        <ReviewSubtitle>
          Faça uma avaliação para {item?.user?.first_name}
        </ReviewSubtitle>
        <ReviewImg src={item?.user?.avatar_url} />
        <ReviewText>
          A mesa número {item?.table?.name} será liberada ao avaliar{' '}
          {item?.user?.first_name}
        </ReviewText>
        <ReviewStars>
          <AiFillStar
            onClick={() => setStars(1)}
            className={stars > 0 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(2)}
            className={stars > 1 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(3)}
            className={stars > 2 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(4)}
            className={stars > 3 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(5)}
            className={stars > 4 ? 'active' : ''}
          />
        </ReviewStars>
        <Button onClick={() => handleReview()}>
          {loading ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            'Enviar'
          )}
        </Button>
      </ModalContent>
    ));
  }

  function renderReviewModalWithoutTable() {
    return notifications?.map((item: any, index: any) => (
      <ModalContent key={index}>
        <ReviewTitle>{item?.user?.first_name} finalizou sua comanda!</ReviewTitle>
        <ReviewSubtitle>
          Faça uma avaliação para {item?.user?.first_name}
        </ReviewSubtitle>
        <ReviewImg src={item?.user?.avatar_url} />
        <ReviewStars>
          <AiFillStar
            onClick={() => setStars(1)}
            className={stars > 0 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(2)}
            className={stars > 1 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(3)}
            className={stars > 2 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(4)}
            className={stars > 3 ? 'active' : ''}
          />
          <AiFillStar
            onClick={() => setStars(5)}
            className={stars > 4 ? 'active' : ''}
          />
        </ReviewStars>
        <Button onClick={() => handleReview()}>
          {loading ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            'Enviar'
          )}
        </Button>
      </ModalContent>
    ));
  }

  return (
    <Modal
      visible={modalReviewOpen}
      footer={null}
      onCancel={() => setModalReviewOpen(false)}
    >
      {hasTables ? renderReviewModal() : renderReviewModalWithoutTable()}
    </Modal>
  );
};

export default ModalReview;
