import { Modal, notification } from 'antd';
import { useAuth } from '../../../../../hooks/Auth';
import React, { useState, useEffect, useMemo } from 'react';
import socketio, { io } from 'socket.io-client';
import { useTableManagement } from '../../../../../hooks/TableManagement';
import { ReactComponent as TableIcon } from '../../../../../shared/icons/table-icon-modal-checkin.svg';
import { useHistory } from 'react-router-dom';
import {
  AcceptModal,
  IconModal,
  RefuseModal,
  TitleModal,
  NameTitle,
  TableNumber,
  DateTitle,
  ImgAvatar,
  FooterModal,
  ModalContent,
} from './_ModalCheckinAtPage';
import { toast } from 'react-toastify';
import api from 'services/api';

export const ModalCheckinAtPage = ({
  modalCheckinAtPageOpen,
  setModalCheckinAtPageOpen,
  notificationsCheckin,
}: any) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { data } = useAuth();
  const { setTableIdAllocation, tableIdAllocation } = useTableManagement();

  const renderDate = (dateItem: string) => {
    const date = new Date(dateItem);

    const monthNumber = date.getMonth() + 1;
    const month =
      monthNumber.toString().length === 1 ? '0' + monthNumber : monthNumber;

    const day =
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate();

    const hours =
      date.getHours().toString().length === 1
        ? '0' + date.getHours()
        : date.getHours();

    const minutes =
      date.getMinutes().toString().length === 1
        ? '0' + date.getMinutes()
        : date.getMinutes();

    const dateFormated = day + '/' + month + '/' + date.getFullYear();

    return (
      <p>
        Agendado para: {dateFormated} às {hours}:{minutes}
      </p>
    );
  };

  async function acceptCheckIn(id: string) {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    try {
      await api.patch(
        `/reservations/${id}/checkin`,
        {
          establishmentId: data.user.establishment?.id,
        },
        { headers },
      );

      setModalCheckinAtPageOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  }

  const handleAcceptSolicitation = (id: string) => {
    setTableIdAllocation(id);
    if (id) {
      history.push('/solicitacoes/alocacao-de-mesa');
    }
  };

  function renderCheckInModal() {
    if (notificationsCheckin.length) {
      return (
        <ModalContent>
          <TitleModal>Solicitação de Check-in</TitleModal>
          <ImgAvatar
            src={notificationsCheckin[2].avatar_url}
            alt="user avatar"
          />
          <NameTitle>{`${notificationsCheckin[2].first_name} ${notificationsCheckin[2].last_name}`}</NameTitle>
          <DateTitle>{renderDate(notificationsCheckin[0])}</DateTitle>

          <TableNumber>
            Mesa número:{' '}
            {notificationsCheckin[1] && <>{notificationsCheckin[1]}</>}
          </TableNumber>
          <FooterModal>
            <AcceptModal onClick={() => acceptCheckIn(notificationsCheckin[3])}>
              Aceitar Check-in
            </AcceptModal>

            <RefuseModal onClick={() => setModalCheckinAtPageOpen(false)}>
              Recusar Check-in
            </RefuseModal>
          </FooterModal>
        </ModalContent>
      );
    }
  }

  return (
    <Modal
      visible={modalCheckinAtPageOpen}
      footer={null}
      onCancel={() => setModalCheckinAtPageOpen(false)}
    >
      {renderCheckInModal()}
    </Modal>
  );
};
