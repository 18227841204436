import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin-top: 40px;

  @media (max-width: 440px) {
   text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 450px){
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 40px;

  @media (max-width: 437px){
    padding: 20px;
    width: 100%;
  }
`;

export const RowInput = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-of-type(1){
    > div {
      width: 120px;
    }
  }

  > div:nth-of-type(2){
    > div {
      width: 180px;
    }
  }

  @media (max-width: 440px) {
    flex-direction: column;

    > div:nth-of-type(1){
      > div {
        width: 100%;
      }
    }

    > div:nth-of-type(2){
      > div {
        width: 100%
      }
    }

    > div:nth-of-type(2){
      margin-top: -20px;
    }
  }
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const Form = styled.form`
  > p {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.6rem;
    color: var(--greenText);
  }

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: var(--descriptionText);
    margin: 14px 0px;
    display: block;

    > span {
      font-family: 'FontsFreeNetSFProTextBold';
      color: #8E8E93;
    }
  }
`;

export const Next = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.4rem;
  color: #4CD964;
  text-align: right;
  margin-top: 40px;
  cursor: pointer;
`;

export const Step = styled.div`
  > img {
    display: block;
    margin: 0 auto;
  }

  > button {
    width: 260px;
  }

  @media (max-width: 1024px) {
    > button {
      margin: 20px auto 0 auto;
    }
  }

  @media (max-width: 1024px){
    > img {
      margin-top: 20px;
    }
  }
`;

export const SelectContainer = styled.div`
  > div > div {
    width: 100% !important;
  }
`;

export const ServicesDayContainer = styled.div`
  margin-left: 40px;

  > button {
    background-color: var(--greenText);
    color: #fff;
  }

  @media (max-width: 1024px) {
   margin: 20px 0px auto 0px;

   > button {
    @media (max-width: 1024px) {
      margin: 20px auto;
    }
   }

  }
`;

export const Informations = styled.div`
  max-width: 320px;
  border-radius: 30px;
  background-color: #EFEFF4;
  padding: 40px 20px;
  text-align: center;

  > div {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.6rem;
    color: #8E8E93;
  }

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8E8E93;
    display: block;
    margin: 20px 0px;
  }

  @media (max-width: 1024px) {
   margin: 0 auto;
  }
`;

export const RowInputHour = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8E8E93;
  }
`;

export const SelectHourContainer = styled.div`

`;
