import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from '../../../../shared/components/Button/Button';
import { Input } from '../../../../shared/components/Input/Input';
import {
  Content,
  Title,
  Subtitle,
  WrapperInput,
  SettledNumber,
  EditTableNumberIcon,
} from './_AddTables';
import { useTableManagement } from '../../../../hooks/TableManagement';
import ChangeTableNumberModal from '../ChangeTableNumberModal/ChangeTableNumberModal';
import { toast } from 'react-toastify';

interface AddTableProps {
  setTableNumber: Dispatch<SetStateAction<number>>;
  tableNumber: number;
  setSelectedToDelete: Dispatch<SetStateAction<string[]>>;
  selectedToDelete: string[];
}

const AddTables = ({ setTableNumber, tableNumber }: AddTableProps) => {
  const {
    handleNewNumberOfTables,
    getEstablishmentTables,
    establishmentTables,
  } = useTableManagement();

  const [countOfTable, setCountOfTable] = useState(0);
  const [showTables, setShowTables] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setTableNumber(establishmentTables.length);
    setIsModalVisible(true);
  };

  useEffect(() => {
    async function getAllTables() {
      await getEstablishmentTables();
    }

    getAllTables();
  }, []);

  function handleCountTableNumber(e: React.FormEvent<HTMLInputElement>) {
    setCountOfTable(Number(e.currentTarget.value));
  }

  async function handleTableNumber() {
    try {
      await handleNewNumberOfTables(countOfTable);
      await getEstablishmentTables();
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }

    setTableNumber(countOfTable);
    setShowTables(true);
    setIsModalVisible(false);
  }

  return (
    <Content>
      {establishmentTables.length > 0 ? (
        <>
          <Title>Suas mesas</Title>
          <Subtitle>Quantidade de mesas:</Subtitle>
          <SettledNumber>
            <span onClick={() => showModal()}>
              <EditTableNumberIcon />
            </span>
            <p> {establishmentTables.length}</p>
          </SettledNumber>
        </>
      ) : (
        <>
          <Title>Adicionar mesas</Title>
          <WrapperInput>
            <Subtitle>Informe a quantidade de mesas:</Subtitle>
            <Input
              placeholder="Ex: 10"
              type="text"
              onChange={(e) => handleCountTableNumber(e)}
            />
            <Button onClick={(e) => handleTableNumber()}>Ok</Button>
          </WrapperInput>
        </>
      )}
      <ChangeTableNumberModal
        tablesNumbers={tableNumber}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleCountTableNumber={handleCountTableNumber}
        handleTableNumber={handleTableNumber}
      />
    </Content>
  );
};

export default AddTables;
