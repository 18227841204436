import React, {
  useContext,
  createContext,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import api from '../services/api';

import { useAuth } from './Auth';

interface DataProps {
  email: string;
  password: string;
  passwordConfirmation: string;
  firstName: string;
  lastName: string;
  cpf: string;
  phone: string;
  contractAccepted: boolean;
}

interface CreateAccountContextProps {
  data: DataProps;
  setData: Dispatch<SetStateAction<DataProps>>;
  createAccount(): Promise<void>;
  verifyCodePhoneNumber(code: string): Promise<void>;
}

const CreateAccountContext = createContext<CreateAccountContextProps>(
  {} as CreateAccountContextProps,
);

const CreateAccountProvider: React.FC = ({ children }) => {
  const { signIn } = useAuth();
  const dataUseAuth = useAuth().data;

  const [data, setData] = useState<DataProps>({
    email: '',
    password: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',
    cpf: '',
    phone: '',
    contractAccepted: false,
  } as DataProps);

  const createAccount = async () => {
    await api.post('/users/establishments', { ...data });

    await signIn({
      email: data.email,
      password: data.password,
      saveLogin: true,
    });
  };

  const verifyCodePhoneNumber = async (code: string) => {
    const headers = { Authorization: `Bearer ${dataUseAuth.accessToken}` };

    await api.post('/codes/verify', { code }, { headers });
  };

  return (
    <CreateAccountContext.Provider
      value={{ data, setData, createAccount, verifyCodePhoneNumber }}
    >
      {children}
    </CreateAccountContext.Provider>
  );
};

const useCreateAccount = (): CreateAccountContextProps => {
  const context = useContext(CreateAccountContext);

  if (!context) {
    throw new Error('userAuth must be used within an CreateAccountProvider');
  }

  return context;
};

export { CreateAccountProvider, useCreateAccount };
