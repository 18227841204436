import { useState, useEffect } from 'react';
import { BsCheck } from 'react-icons/bs';
import { Switch } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

import { useFoodMenu } from '../../../../hooks/FoodMenu';
import { useAuth } from '../../../../hooks/Auth';
import api from '../../../../services/api';

import { WrapperPage } from '../../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../../shared/components/Input/Input';
import { Button } from '../../../../shared/components/Button/Button';
import { Select } from './components/Select/Select';
import { SuccessModal } from '../../../../shared/components/SuccessModal/SuccesModal';
import { Spinner } from '../../../../shared/components/Spinner/Spinner';

import OpenEstablishmentIcon from '../../../../shared/icons/open-establishment-icon.png';
import ClosedEstablishmentIcon from '../../../..qshared/icons/closed-establishment-icon.png';

import { NotificationsModal } from '../../../../shared/components/NotificationsModal/NotificationsModal';

import {
  Title,
  Row,
  FormContainer,
  Form,
  InputContainer,
  ListButtons,
  SelectContainer,
  Status,
  RowInput,
  RegisterNewCategorieButton,
  SwitchContainer,
} from './_RegisterNewMenu';

export const RegisterNewMenu = () => {
  const { data, setData } = useAuth();
  const { getCategories, categories, registerNewCategory } = useFoodMenu();
  const history = useHistory();

  const [modalCheckinOpen, setModalCheckinOpen] = useState(false);
  const [showSuccessItemEdited, setShowSuccessItemEdited] =
    useState<boolean>(false);
  const [statusEstablishment, setStatusEstablishment] =
    useState<boolean>(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openEstablishment, setOpenEstablishment] = useState(
    data.user.establishment?.enabled_seller,
  );
  const [
    loadingOpenAndClosedEstablishment,
    setLoadingOpenAndClosedEstablishment,
  ] = useState<boolean>(false);

  useEffect(() => {
    getCategories();
  }, []);

  const renderIconOpenAndClosedEstablishment = () => {
    if (loadingOpenAndClosedEstablishment) {
      return <Spinner />;
    }

    if (openEstablishment) {
      return 'Aberto';
    }

    return 'Fechado';
  };

  const handleRegisterNewCategory = async () => {
    if (!newCategoryName.trim()) {
      toast.error('Digite o nome da nova categoria corretamente');
      return;
    }

    try {
      setLoading(true);

      const idNewCategory = await registerNewCategory(newCategoryName);
      history.push(`/cardapio/${idNewCategory}/cadastrar-item`);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const handleOnChangeStatusEstablichment = async (value: boolean) => {
    setLoadingOpenAndClosedEstablishment(true);
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    try {
      await api.patch(
        `/establishments/enabled-seller?id=${data.user.establishment?.id}`,
        {
          enabledSeller: value,
        },
        {
          headers,
        },
      );

      if (value) {
        toast.success('Estabelecimento aberto');
      } else {
        toast.success('Estabelecimento fechado');
      }

      setOpenEstablishment(value);
      setData({
        ...data,
        user: {
          ...data.user,
          establishment: {
            ...data.user.establishment,
            enabled_seller: value,
          },
        },
      });
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoadingOpenAndClosedEstablishment(false);
    }
  };

  const handleOnChangeCategory = (value: string) => {
    history.push(`/cardapio/${value}/cadastrar-item`);
  };

  return (
    <WrapperPage title="Cadastro">
      <Title>Inserir Novo Cardápio</Title>
      <Row>
        <FormContainer>
          <Form>
            <p>Categoria do Cardápio</p>
            <label>Escolha qual é a categoria do seu Cardápio:</label>
            <SelectContainer>
              <Select
                placeholder="Selecionar categoria"
                data={categories}
                onChange={(value) => handleOnChangeCategory(value)}
              />
            </SelectContainer>

            <p>Inserir Categoria</p>
            <label>
              Caso não encontre a categoria, insira um novo nome abaixo:
            </label>
            <RowInput>
              <InputContainer>
                <Input
                  value={newCategoryName}
                  onChange={(event) => setNewCategoryName(event.target.value)}
                />
              </InputContainer>
              <RegisterNewCategorieButton
                type="button"
                onClick={() => handleRegisterNewCategory()}
              >
                {loading ? (
                  <LoadingOutlined style={{ fontSize: 24 }} spin />
                ) : (
                  <BsCheck color="#fff" size={30} />
                )}
              </RegisterNewCategorieButton>
            </RowInput>
          </Form>
        </FormContainer>
        <Status>
          <div>
            Status do
            <br /> Estabelecimento
          </div>
          <SwitchContainer statusEstablishment={statusEstablishment}>
            <Switch
              checked={openEstablishment}
              onChange={(value) => handleOnChangeStatusEstablichment(value)}
            />
            <span>{renderIconOpenAndClosedEstablishment()}</span>
          </SwitchContainer>
        </Status>
        <ListButtons>
          <Button>+ Novo Cardápio</Button>
          <Button>
            <Link to="/cardapios">Cardápios</Link>
          </Button>
        </ListButtons>
      </Row>
      <SuccessModal
        openModal={showSuccessItemEdited}
        setOpenModal={setShowSuccessItemEdited}
        text="Item editado com sucesso!"
      />
      <NotificationsModal />
    </WrapperPage>
  );
};
