import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.4rem;
  color: var(--greenText);
  text-align: center;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1rem;
  color: var(--descripionText);
  text-align: center;
`;

export const Description = styled.p`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1rem;
  color: var(--descripionText);
  text-align: center;
  margin-top: 10px;
`;

export const Icon = styled.img`
  display: block;
  margin: 20px auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;

  > img {
    cursor: pointer;
  }
`;

export const ContainerUpload = styled.div`
> button {
    width: 200px;
    min-height: 50px;
    background-color: var(--greenText);

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      cursor: pointer;
      > input {
        display: none;
      }
      > span {
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 1rem;
        color: #fff;
        overflow-wrap: anywhere;
      }
    }
  }
`;
