import styled from 'styled-components';

export const Container = styled.div`
  .ant-picker {
    border-radius: 30px;
    border: 0px;
    box-shadow: 0px 0px 6px 0px lightgrey;
    padding: 10px;
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 16px;
    color: var(--descripionText);
    outline: transparent;
    width: 90px;
  }
  .ant-picker-time-panel-column {
    font-family: 'FontsFreeNetSFProTextLight' !important;
  }
`;
