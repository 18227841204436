import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../hooks/Auth';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

import './modal-responsive.css';

import api from '../../../services/api';
import { moneyMask } from '../../../utils/Masks';

import { Button } from '../../../shared/components/Button/Button';
import { Spinner } from '../../../shared/components/Spinner/Spinner';

import exit from '../../../shared/icons/exit.png';
import TimerIcon from '../../../shared/icons/timer.svg';

import {
  Title,
  IconCloseModal,
  Divider,
  Wrapper,
  SelectedUser,
  SelectedUserInformations,
  Name,
  Scheduling,
  TableNumber,
  GuestsContainer,
  NumberGuests,
  Guest,
  Status,
  OrderSummaryContainer,
  TitleOrderSummary,
  WrapperOrderSumamary,
  InformationOrder,
  DescriptionOrder,
  TotalToPay,
  TotalOrderValue,
} from './_ModalInfoSolicitation';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  reservationId: string;
  customerId: string;
}

interface UserPropertyProps {
  first_name: string;
  last_name: string;
  avatar_url: string;
}

interface OrderItemProps {
  id: string;
  final_price: string;
  item_price: string;
  quantity: number;
  product: {
    name: string;
  };
}

interface OrderProps {
  created_at: string;
  final_price: string;
  order_number: number;
  order_items: OrderItemProps[];
}

interface ReservationInformationProps {
  results: {
    date: string;
    user: UserPropertyProps;
    guests: [
      {
        is_finished: boolean;
        customer_id: string;
        user: UserPropertyProps;
      },
    ];
    user_id: string;
    name?: string;
    orders?: OrderProps[];
  };
  total_amount: string;
  total_items: number;
}

export const ModalInfoSolicitation = ({
  openModal,
  setOpenModal,
  reservationId,
  customerId,
}: Props) => {
  const { data } = useAuth();

  const [loading, setLoading] = useState(false);
  const [loadingPayCash, setLoadingPayCash] = useState(false);
  const [reservationInformation, setReservationInformation] =
    useState<ReservationInformationProps>({} as ReservationInformationProps);
  const [currentCustomerId, setCurrentCustomerId] = useState(customerId);
  const [itemIsFinished, setItemIsFinished] = useState(false);

  const fetchReservationInformartion = async () => {
    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };

      const response = await api.get<ReservationInformationProps>(
        `reservations/establishments?reservationId=${reservationId}&customerId=${currentCustomerId}`,
        { headers },
      );

      const userFinishedPayment = response.data.results.guests.find((item) => {
        if (item.customer_id === currentCustomerId) {
          return item.is_finished;
        }
      }) || { is_finished: false };
      setItemIsFinished(userFinishedPayment.is_finished);

      setReservationInformation(response.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservationInformartion();
  }, [currentCustomerId]);

  const handlePayCash = async () => {
    setLoadingPayCash(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.post(
        `payments/cash`,
        {
          reservationId,
          customerId: currentCustomerId,
        },
        { headers },
      );
      setItemIsFinished(true);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoadingPayCash(false);
    }
  };

  const renderDate = (dateString: string) => {
    const date = new Date(dateString);

    const monthNumber = date.getMonth() + 1;
    const month =
      monthNumber.toString().length === 1 ? '0' + monthNumber : monthNumber;

    const day =
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate();

    const hours =
      date.getHours().toString().length === 1
        ? '0' + date.getHours()
        : date.getHours();

    const minutes =
      date.getMinutes().toString().length === 1
        ? '0' + date.getMinutes()
        : date.getMinutes();

    const dateFormated = day + '/' + month + '/' + date.getFullYear();

    return `${dateFormated} às ${hours}:${minutes}`;
  };

  const renderGuests = () => {
    const guests = reservationInformation?.results?.guests.map((item) => {
      if (item.customer_id !== currentCustomerId) {
        return (
          <Guest>
            <img
              onClick={() => setCurrentCustomerId(item.customer_id)}
              src={item.user.avatar_url}
              alt="Foto do usuário convidado"
            />
            <div onClick={() => setCurrentCustomerId(item.customer_id)}>
              {item.user.first_name} {item.user.last_name}
              {renderReservePrincipalIcon(item.customer_id)}
            </div>
            <Status isFinished={item.is_finished}>
              {item.is_finished ? '(Finalizado)' : '(Não Finalizado)'}
            </Status>
          </Guest>
        );
      }
    });

    return guests;
  };

  const renderReservePrincipalIcon = (id: string) => {
    if (id === reservationInformation?.results?.user_id) {
      return <span>*</span>;
    }
  };

  const renderPayCashButton = () => {
    if (!itemIsFinished) {
      return (
        <Button
          style={{
            color: '#fff',
            backgroundColor: 'var(--greenText)',
            margin: '40px 0px',
          }}
          onClick={() => handlePayCash()}
        >
          {loadingPayCash ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            'Confirmar recebimento'
          )}
        </Button>
      );
    }
  };

  const renderTableNumber = () => {
    if (reservationInformation?.results?.name) {
      return (
        <TableNumber>{`Mesa número: ${reservationInformation?.results?.name}`}</TableNumber>
      );
    }
  };

  const renderOrders = () => {
    const orders = reservationInformation?.results?.orders?.map((item) => (
      <WrapperOrderSumamary>
        <InformationOrder>
          <div>
            Nº do Pedido: <span>{item.order_number}</span>
          </div>
          <div>
            <img src={TimerIcon} alt="Ícone de relógio" />
            {renderDate(item.created_at)}
          </div>
        </InformationOrder>
        <DescriptionOrder>
          <div>Descrição</div>
          <ul>
            {item.order_items.map((order_item) => (
              <li>
                <div>
                  {order_item.quantity} - {order_item.product.name}
                </div>
                <div>
                  {order_item.product.name}:{' '}
                  <span>R$ {moneyMask(order_item.item_price)}</span>
                </div>
              </li>
            ))}
          </ul>
        </DescriptionOrder>
        <TotalToPay>
          Total: <span>R$ {moneyMask(item.final_price)}</span>
        </TotalToPay>
      </WrapperOrderSumamary>
    ));

    return orders;
  };

  const renderTotalOrderValue = () => {
    return (
      <TotalOrderValue>
        Valor Total dos Pedidos: <span>R$ {moneyMask(String(reservationInformation.total_amount))}</span>
      </TotalOrderValue>
    )
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="modal-info-solicitation-responsive"
    >
      <IconCloseModal
        src={exit}
        alt="Ícone de sair"
        onClick={() => setOpenModal(false)}
      />
      <Title>Resumo da Reserva</Title>
      <Divider />
      <Wrapper>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <SelectedUser>
              <img
                src={reservationInformation?.results?.user?.avatar_url}
                alt="Foto do usuário selecioando"
              />
              <SelectedUserInformations>
                <Name>
                  {`${reservationInformation?.results?.user?.first_name} ${reservationInformation?.results?.user?.last_name}`}{' '}
                  {renderReservePrincipalIcon(currentCustomerId)}
                </Name>
                <Scheduling>
                  Agendado para:{' '}
                  {renderDate(reservationInformation?.results?.date)}
                </Scheduling>
                {renderTableNumber()}
              </SelectedUserInformations>
            </SelectedUser>
            <GuestsContainer>
              <NumberGuests>
                Convidados:{' '}
                <span>
                  {reservationInformation?.results?.guests.length - 1}
                </span>
              </NumberGuests>
              {renderGuests()}
            </GuestsContainer>
            <OrderSummaryContainer>
              <TitleOrderSummary>
                Resumo dos Pedidos -{' '}
                <span>
                  {reservationInformation?.results?.user?.first_name}{' '}
                  {reservationInformation?.results?.user?.last_name}
                </span>
              </TitleOrderSummary>
              {renderOrders()}
              {renderTotalOrderValue()}
              {renderPayCashButton()}
            </OrderSummaryContainer>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};
