import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

import { WrapperPage } from '../../../../shared/components/WrapperPage/WrapperPage';
import { Button } from '../../../../shared/components/Button/Button';
import { Card } from './components/Card/Card';
import { HandleItemModal } from './components/HandleItemModal/HandleItemModal';
import { SuccessModal } from '../../../../shared/components/SuccessModal/SuccesModal';

import { Title, Row, ListItens } from './_MenuItems';
import { MenuItemsProps, useFoodMenu } from '../../../../hooks/FoodMenu';

import { NotificationsModal } from '../../../../shared/components/NotificationsModal/NotificationsModal';

export const MenuItems = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { menuItems, getMenuItems, categorieName } = useFoodMenu();
  const [loading, setLoading] = useState<boolean>(false);

  const [itemsFromMenu, setItemsFromMenu] = useState<MenuItemsProps[]>([]);
  const [modalCheckinOpen, setModalCheckinOpen] = useState(false);

  useEffect(() => {
    renderMenuItems(id);
  }, [id]);

  const renderMenuItems = async (id: string) => {
    try {
      setLoading(true);
      const loadMenus = await getMenuItems(id);
      setItemsFromMenu(menuItems);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const [actionItem, setActionItem] = useState('');
  const [itemID, setItemID] = useState('');
  const [activeStatus, setActiveStatus] = useState(false);
  const [isItemInPause, setisItemInPause] = useState(false);
  const [showHandleItemModal, setShowHandleItemModal] = useState(false);
  const [showDeletedItemSuccess, setShowDeletedItemSuccess] = useState(false);

  const handleRegisterNewItem = (id: string) => {
    history.push(`/cardapio/${id}/cadastrar-item`);
  };

  const renderListItens = () => {
    const itens = menuItems.map((item) => (
      <Card
        loading={loading}
        data={item}
        setItemID={setItemID}
        setActiveStatus={setActiveStatus}
        setShowHandleItemModal={setShowHandleItemModal}
        setActionItem={setActionItem}
        isItemInPause={isItemInPause}
      />
    ));
    return <ListItens>{itens}</ListItens>;
  };

  const renderTextSuccessModal = () => {
    if (actionItem === 'delete') {
      return 'Item excluído com sucesso!';
    }

    return 'Item pausado com sucesso!';
  };

  return (
    <WrapperPage title="Cadastro">
      <Row>
        <Title>
          <span onClick={() => history.goBack()}>{'<'}</span>
          {categorieName}
        </Title>
        <Button
          onClick={() => handleRegisterNewItem(id)}
          style={{ backgroundColor: 'var(--greenText)', color: '#fff' }}
        >
          Adicionar Item
        </Button>
      </Row>
      {renderListItens()}
      <HandleItemModal
        itemID={itemID}
        activeStatus={activeStatus}
        openModal={showHandleItemModal}
        setOpenModal={setShowHandleItemModal}
        setShowDeletedItemSuccess={setShowDeletedItemSuccess}
        actionItem={actionItem}
        setisItemInPause={setisItemInPause}
      />
      <SuccessModal
        openModal={showDeletedItemSuccess}
        setOpenModal={setShowDeletedItemSuccess}
        text={renderTextSuccessModal()}
      />
      <NotificationsModal />
    </WrapperPage>
  );
};
