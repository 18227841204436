import styled from 'styled-components';

interface StatusProps {
  isFinished: boolean;
}

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.section`
  max-height: 70vh;
  overflow: auto;
  width: 100%;
  margin-top: 1rem;
  padding: 9px;
`;

export const TitleModal = styled.h1`
  color: #21e270;
  font-size: 23px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #707070;
`;

export const TitleText = styled.h1`
  color: #21e270;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;

  > span {
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 1.2rem;
    color: #9f9fa3;
  }
`;

export const NameTitle = styled.p`
  color: #21e270;
  font-size: 22px;
  font-weight: bold;
`;

export const DateTitle = styled.p`
  color: #8e8e93;
  font-size: 14px;
  font-weight: bold;
`;

export const TableNumber = styled.p`
  color: #8e8e93;
  font-size: 14px;
  font-family: 'FontsFreeNetSFProTextLight';
`;

export const ImgAvatar = styled.img`
  width: 80px;
  border-radius: 20px;
`;

export const LeftContent = styled.div``;

export const RightContent = styled.div``;

export const Column = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-direction: row;
`;

export const GuestContainer = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  margin-top: 1.25rem;
  padding: 18px;
  margin-bottom: 1.25rem;
`;

export const OrderContainer = styled.div`
  width: 100%;
`;

export const Guest = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }

  > div {
    margin: 0px 10px;
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1rem;
    color: #9f9fa3;
    cursor: pointer;

    > span {
      color: #ffcc00;
      margin-left: 6px;
      font-size: 16px;
    }
  }

  @media (max-width: 520px) {
    flex-direction: column;
    margin: 10px 0px;
  }
`;

export const Status = styled.span<StatusProps>`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 0.8rem;
  color: ${(props) => (props.isFinished ? '#21e270' : '#A5A5A9')};
`;

export const InformationOrder = styled.div`
  > div:nth-of-type(1) {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: var(--descripionText);

    > span {
      color: var(--greenText);
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 0.7rem;
    color: var(--medium-gray);
    margin-top: 8px;

    > img {
      margin-right: 4px;
    }
  }

  @media (max-width: 520px) {
    text-align: center;
    > div:nth-of-type(2) {
      justify-content: center;
    }
  }
`;

export const DescriptionOrder = styled.div`
  > div {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: var(--greenText);
    margin: 20px 0px 10px 0px;
  }

  > ul {
    li {
      display: flex;
      justify-content: space-between;
      font-size: 0.7rem;
      color: var(--descripionText);
      margin: 4px 0px;

      > div:nth-of-type(1) {
        font-family: 'FontsFreeNetSFProTextMedium';
      }

      > div:nth-of-type(2) {
        font-family: 'FontsFreeNetSFProTextBold';
        span {
          font-family: 'FontsFreeNetSFProTextMedium';
        }
      }
    }
  }

  @media (max-width: 520px) {
    > div {
      text-align: center;
    }

    > ul {
      li {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
      }
    }
  }
`;

export const WrapperOrderSumamary = styled.div`
  padding: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 20px;
  /* margin: 1rem 0rem; */
  max-width: 100%;
`;

export const TotalToPay = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  color: var(--descripionText);
  margin-top: 20px;

  > span {
    color: var(--greenText);
  }

  @media (max-width: 520px) {
    text-align: center;
  }
`;

export const TotalOrderValue = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  color: var(--descripionText);
  margin-top: 20px;
  padding-top: 20px;

  > span {
    color: var(--greenText);
  }

  @media (max-width: 520px) {
    text-align: center;
  }
`;
