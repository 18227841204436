import styled from 'styled-components';

export const Title = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.2rem;
  color: var(--greenText);
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  > img {
    cursor: pointer;
  }
`;

export const InputContainer = styled.div`
  width: 100%;

  > div {
    width: 100%;
    margin: 10px 0px 20px 0px;
  }
`;

export const Form = styled.form`
  font-family: 'FontsFreeNetSFProTextLight';

  ${Title} {
    margin-bottom: 20px;
  }
  .password {
    margin-top: 10px;
  }
  > button {
    margin-top: 30px;
    width: 100%;
  }
`;

export const SaveButton = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    width: 100%;
    max-width: 256px;
    font: 1.2rem 'FontsFreeNetSFProTextBold', sans-serif;
    border-radius: 28px;
    height: 50px;
  }
`;
