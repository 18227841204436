import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from 'antd';
import { Input } from 'shared/components/Input/Input';
import { ForgotSubtitle, ForgotTitle } from './_ForgotPasswordModal';

interface ForgotModalProps {
  setOpenForgotModal: Dispatch<SetStateAction<boolean>>;
  openForgotModal: boolean;
}

export const ForgotPasswordModal = ({
  setOpenForgotModal,
  openForgotModal,
}: ForgotModalProps) => {
  return (
    <Modal
      footer={null}
      visible={openForgotModal}
      onCancel={() => setOpenForgotModal(false)}
    >
      <ForgotTitle>Verifique seu e-mail.</ForgotTitle>
      <ForgotSubtitle>
        Foi enviado para o seu e-mail um link para a recuperação de senha.
      </ForgotSubtitle>
    </Modal>
  );
};
