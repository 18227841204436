import React, {
  useContext,
  createContext,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';

import api from '../services/api';

import { useAuth } from './Auth';
import axios from 'axios';

interface EstablishmentProps {
  companyName: string;
  statementDescriptor: string;
  mcc: string;
  cnpj: string;
  address: AddressProps;
}

interface AddressProps {
  cep: string;
  street: string;
  number: string;
  neighborhood: string;
  complement?: string;
  uf: string;
  city: string;
}

interface BankData {
  account_number: string;
  bank_code: string;
  holder_name: string;
  routing_number: string;
  type: string;
}

export interface CreateNewPassword {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

export interface CreateFullName {
  firstName: string;
  lastName: string;
}

export interface NewAddressProps {
  cep: string;
  street: string;
  number: string;
  neighborhood: string;
  uf: string;
  city: string;
  name: string;
  complement?: string;
}

export interface NewInfosProps {
  companyName: string;
  statementDescriptor: string;
  mcc: string;
  categoryId?: string;
  totalCapacity: string;
}

interface CategoriesProps {
  id: string;
  name: string;
}

interface EstablishmentCategoriesProps {
  results: CategoriesProps[];
}

export interface BusinessHourProps {
  id: string;
  start_hour: string;
  end_hour: string;
  day_of_week: number;
  is_open: boolean;
}

export interface NewBusinessHourProps {
  endHour?: string;
  dayOfWeek: number;
  startHour?: string;
}

export interface UploadNewImage {
  image: File;
}

interface EstablishmentImg {
  image_url: string;
}

interface FinalizeRegistrationContextProps {
  establishmentData: EstablishmentProps;
  setEstablishmentData: Dispatch<SetStateAction<EstablishmentProps>>;
  createEstablishment(): Promise<void>;
  cnpjOrMeiDocument: File | undefined;
  setCnpjOrMeiDocument: Dispatch<SetStateAction<File | undefined>>;
  proofOfActivity: File | undefined;
  setProofOfActivity: Dispatch<SetStateAction<File | undefined>>;
  partnerDocument: File | undefined;
  setPartnerDocument: Dispatch<SetStateAction<File | undefined>>;
  proofOfResidenceDocument: File | undefined;
  setProofOfResidenceDocument: Dispatch<SetStateAction<File | undefined>>;
  registerDocuments(type: string, establishmentID: string): Promise<void>;
  getEstablichmentID(): Promise<string>;
  saveTokenZOOP(bankData: BankData): Promise<string>;
  updateStatusDocuments(type: string): void;
  changePassword(changePass: CreateNewPassword): Promise<void>;
  changeFullName(changeName: CreateFullName): Promise<void>;
  changeEstablishmentAddress(newAddress: NewAddressProps): Promise<void>;

  changeEstablishmentInfos(newAddress: NewInfosProps): Promise<void>;
  getCategoriesOfEstablishment(): Promise<void>;
  establishmentCategories: CategoriesProps[];
  getEstablishmentBusinessHours(): Promise<BusinessHourProps[]>;
  businessHours: BusinessHourProps[];
  setBusinessHours: Dispatch<SetStateAction<BusinessHourProps[]>>;
  updateIsOpenEstablishment(
    id: string,
    week_day: string,
    is_open: boolean,
  ): Promise<void>;
  updateEstablishmentBusinessHours(
    id: string,
    body: NewBusinessHourProps,
  ): Promise<void>;
  updateEstablishmentImg(image: UploadNewImage): Promise<void>;
  createTOTVSCode(erpEstablishmentCode: string): Promise<void>;
  handleDeleteAccount(accountID: string): Promise<any>;
  handleUpdateTip(tip: boolean): Promise<void>;
}

const FinalizeRegistrationContext =
  createContext<FinalizeRegistrationContextProps>(
    {} as FinalizeRegistrationContextProps,
  );

const FinalizaRegistrationProvider: React.FC = ({ children }) => {
  const [establishmentData, setEstablishmentData] =
    useState<EstablishmentProps>({
      companyName: '',
      statementDescriptor: '',
      mcc: '',
      cnpj: '',
      address: {
        cep: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
        uf: '',
        city: '',
      },
    } as EstablishmentProps);
  const [cnpjOrMeiDocument, setCnpjOrMeiDocument] = useState<
    File | undefined
  >();
  const [proofOfActivity, setProofOfActivity] = useState<File | undefined>();
  const [partnerDocument, setPartnerDocument] = useState<File | undefined>();
  const [proofOfResidenceDocument, setProofOfResidenceDocument] = useState<
    File | undefined
  >();
  const [establishmentCategories, setEstablishmentCategories] = useState<
    CategoriesProps[]
  >([] as CategoriesProps[]);

  const [businessHours, setBusinessHours] = useState<BusinessHourProps[]>(
    [] as BusinessHourProps[],
  );

  const { data, setData } = useAuth();

  const createEstablishment = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    await api
      .post('/establishments', { ...establishmentData }, { headers })
      .then((response) => {
        setData({
          ...data,
          user: {
            ...data.user,
            establishment: {
              ...data.user.establishment,
              id: response.data.id,
              cnpj: response.data.cnpj,
              document: {
                ...data.user.establishment?.document,
                registered_bank_account: false,
              },
            },
          },
        });
      });
  };

  const getEstablichmentID = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.get('/users/establishments/own', { headers });
    return response.data.id;
  };

  const changeEstablishmentAddress = async (newAddress: NewAddressProps) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    await api.put(
      `/addresses/establishments`,
      {
        cep: newAddress.cep,
        street: newAddress.street,
        number: newAddress.number,
        neighborhood: newAddress.neighborhood,
        uf: newAddress.uf,
        city: newAddress.city,
        name: newAddress.name,

        establishmentId: `${data.user.establishment?.id}`,
      },
      { headers },
    );
  };

  const getEstablishmentBusinessHours = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.get<BusinessHourProps[]>(
      `/establishments/${data.user.establishment?.id}/business-hours`,
      { headers },
    );

    return response.data;
  };

  const updateIsOpenEstablishment = async (
    id: string,
    week_day: string,
    is_open: boolean,
  ) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    await api.put(`/business-hours/${id}`, { isOpen: is_open }, { headers });
  };

  const updateEstablishmentBusinessHours = async (
    id: string,
    body: NewBusinessHourProps,
  ) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    await api.put(
      `/business-hours/${id}`,
      {
        ...body,
      },
      { headers },
    );
  };

  const updateEstablishmentImg = async (image: UploadNewImage) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const formData = new FormData();
    formData.append('image', image.image);
    await api.patch<UploadNewImage>(
      `/establishments/${data.user.establishment?.id}/image`,
      formData,
      { headers },
    );
  };

  const handleUpdateTip = async (tip: boolean) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    await api.put(
      `/establishments/${data.user.establishment?.id}`,
      {
        enableTip: tip,
      },
      { headers },
    );

    setData({
      ...data,
      user: {
        ...data.user,
        establishment: {
          ...data.user.establishment,
          tip_enabled: tip,
        },
      },
    });

  }

  const changeEstablishmentInfos = async (newInfos: NewInfosProps) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    await api.put(
      `/establishments/${data.user.establishment?.id}`,
      {
        mcc: newInfos.mcc,
        companyName: newInfos.companyName,
        statementDescriptor: newInfos.statementDescriptor,
        totalCapacity: newInfos.totalCapacity,
        categoryId: newInfos.categoryId,
      },
      { headers },
    );

    setData({
      ...data,
      user: {
        ...data.user,
        establishment: {
          ...data.user.establishment,
          mcc: newInfos.mcc,
          company_name: newInfos.companyName,
          statement_descriptor: newInfos.statementDescriptor,
          total_capacity: Number(newInfos.totalCapacity),
        },
      },
    });
  };

  const registerDocuments = async (type: string, establishmentID: string) => {
    const formData = new FormData();
    formData.append('type', type);

    if (type === 'cnpj') {
      formData.append('file', cnpjOrMeiDocument as File);
    } else if (type === 'identity') {
      formData.append('file', partnerDocument as File);
    } else if (type === 'residence') {
      formData.append('file', proofOfResidenceDocument as File);
    } else if (type === 'activity-performed') {
      formData.append('file', proofOfActivity as File);
    }

    const headers = {
      Authorization: `Bearer ${data.accessToken}`,
      'Content-Type': `multipart/form-data;`,
    };
    await api
      .post(`/establishments/${establishmentID}/documents`, formData, {
        headers,
      })
      .then((response) => {
        updateStatusDocuments(type);
      });
  };

  const handleDeleteAccount = async (cardID: string) => {

    const headers = { Authorization: `Bearer ${data.accessToken}`};

    const response = await api.delete(
        `/bank-accounts/${cardID}?establishmentId=${data.user.establishment?.id}`,
        { headers },
      );
  };

  const saveTokenZOOP = async (bankData: BankData) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.post(
      `/bank-accounts`,
      {
        ...bankData,
        bank_code: parseFloat(bankData.bank_code),
        routing_number: parseFloat(bankData.routing_number),
        account_number: parseFloat(bankData.account_number),
      },
      { headers },
    );

    setData({
      ...data,
      user: {
        ...data.user,
        establishment: {
          ...data.user.establishment,
          document: {
            ...data.user.establishment?.document,
            registered_bank_account: true,
          },
        },
      },
    });

    return response.data.id;
  };

  const changePassword = async (changePass: CreateNewPassword) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.patch(
      `/users/password`,
      {
        ...changePass,
      },
      { headers },
    );
  };

  const getCategoriesOfEstablishment = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.get<EstablishmentCategoriesProps>(
      `/categories/establishments`,
      { headers },
    );
    setEstablishmentCategories(response.data.results);
  };

  const changeFullName = async (changeName: CreateFullName) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.put(
      `/users`,
      {
        ...changeName,
      },
      { headers },
    );
    setData({
      ...data,
      user: {
        ...data.user,
        first_name: changeName.firstName,
        last_name: changeName.lastName,
        establishment: {
          ...data.user.establishment,
        },
      },
    });
  };

  const updateStatusDocuments = (type: string) => {
    let dataUser;
    if (data.saveLogin) {
      dataUser = JSON.parse(localStorage.getItem('@Payment:user') || '{}');
    } else {
      dataUser = JSON.parse(sessionStorage.getItem('@Payment:user') || '{}');
    }

    const { establishment } = dataUser;

    if (type === 'cnpj') {
      setData({
        ...data,
        user: {
          ...dataUser,
          establishment: {
            ...establishment,
            document: {
              ...establishment?.document,
              sent_cnpj: true,
            },
          },
        },
      });
    } else if (type === 'identity') {
      setData({
        ...data,
        user: {
          ...dataUser,
          establishment: {
            ...establishment,
            document: {
              ...establishment?.document,
              sent_id_card: true,
            },
          },
        },
      });
    } else if (type === 'residence') {
      setData({
        ...data,
        user: {
          ...dataUser,
          establishment: {
            ...establishment,
            document: {
              ...establishment?.document,
              sent_proof_of_address: true,
            },
          },
        },
      });
    } else if (type === 'activity-performed') {
      setData({
        ...data,
        user: {
          ...dataUser,
          establishment: {
            ...establishment,
            document: {
              ...establishment?.document,
              sent_proof_of_activity: true,
            },
          },
        },
      });
    }
  };

  const createTOTVSCode = async (erpEstablishmentCode: string) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.post(
      `/establishments/erp/integration`,
      {
        erpEstablishmentCode,
      },
      { headers },
    );

    return response.data.code;
  }

  return (
    <FinalizeRegistrationContext.Provider
      value={{
        establishmentData,
        setEstablishmentData,
        changeEstablishmentAddress,
        changeEstablishmentInfos,
        createEstablishment,
        cnpjOrMeiDocument,
        setCnpjOrMeiDocument,
        proofOfActivity,
        setProofOfActivity,
        partnerDocument,
        setPartnerDocument,
        proofOfResidenceDocument,
        setProofOfResidenceDocument,
        registerDocuments,
        getEstablichmentID,
        saveTokenZOOP,
        updateStatusDocuments,
        changePassword,
        changeFullName,

        getCategoriesOfEstablishment,
        establishmentCategories,
        getEstablishmentBusinessHours,
        businessHours,
        setBusinessHours,
        updateIsOpenEstablishment,
        updateEstablishmentBusinessHours,
        updateEstablishmentImg,
        createTOTVSCode,
        handleDeleteAccount,
        handleUpdateTip,
      }}
    >
      {children}
    </FinalizeRegistrationContext.Provider>
  );
};

const useFinalizeRegistration = (): FinalizeRegistrationContextProps => {
  const context = useContext(FinalizeRegistrationContext);

  if (!context) {
    throw new Error('userAuth must be used within an CreateAccountProvider');
  }

  return context;
};

export { FinalizaRegistrationProvider, useFinalizeRegistration };
