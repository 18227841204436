import styled, { css } from 'styled-components';

interface ContainerProps {
  showSideBar: boolean;
  showMenu: boolean;
}

interface Flags {
  pedingCount?: number;
  orderCount?: number;
}

export const Container = styled.nav<ContainerProps>`
  width: 160px;
  min-height: 100vh;
  background-color: var(--greenText);
  transition: all 1s;
  margin-left: 0px;
  padding: 0px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
  }

  ${(props) =>
    props.showSideBar === false &&
    css`
      margin-left: -160px;
    `}

  ${(props) =>
    props.showMenu === false &&
    css`
      > div {
        justify-content: space-between;
        padding: 40px 0px;
      }
    `}
  ${(props) =>
    props.showMenu === false &&
    css`
      > div {
        justify-content: space-between;
        padding: 40px 0px;
      }
    `}
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: auto;
    height: 80px;
    margin-left: 0px;

    > div {
      flex-direction: row;
      height: 80px;
    }

    z-index: 10;
  }
`;

export const MenuIcon = styled.img`
  width: 27px;
  height: 20px;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Items = styled.div<Flags>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 380px;
  justify-content: space-around;

  .solicitation-icon {
    &:after {
      content: '${(props) => props.pedingCount}';
      height: 14px;
      width: 14px;
      background: #ffffff;
      display: inline-flex;
      position: absolute;
      color: #8e8e93;
      font-size: 8px;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 1020px) {
      display: none;
    }
  }

  .orders-icon {
    &:after {
      content: '${(props) => props.orderCount}';
      height: 14px;
      width: 14px;
      background: #ffffff;
      display: inline-flex;
      position: absolute;
      left: 86px;
      color: #8e8e93;
      font-size: 8px;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 1020px) {
      display: none;
    }
  }

  .active {
    filter: brightness(0.3);
    opacity: 0.3;
  }

  > img {
    margin: 10px 0px;
    cursor: pointer;
  }
  > img:nth-of-type(1) {
    width: 45px;
    height: 36px;
  }
  > img:nth-of-type(2) {
    width: 35px;
    height: 35px;
  }
  > img:nth-of-type(3) {
    width: 40px;
    height: 40px;
  }
  > img:nth-of-type(4) {
    width: 36px;
    height: 36px;
  }
  @media (max-width: 1024px) {
    min-height: auto;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: 70px;
  height: 53px;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: none;
  }
`;
