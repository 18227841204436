import styled from 'styled-components';
import { MdChat } from 'react-icons/md';

interface TableProps {
  index: number;
}

export const SelectDelete = styled.label`
  display: block;
  position: relative;
  padding-left: 0px;
  margin-right: 12px;
  opacity: 0;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  span {
    &:after {
      top: 0px;
      left: 0px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #ffffff;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ span:after {
      display: block;
    }
  }

  &:hover {
    input ~ span {
      background-color: #ffffff;
    }
  }

  .checkmark {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 14px;
    width: 14px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #ff0000;

    &:after {
      content: 'X';
      color: #ff0000;
      position: absolute;
      left: 1px;
      display: none;
    }
  }
`;

export const TableItem = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 17px;
  background: #21e270;

  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  &.is-inactive {
    background: #d1d1d6;
  }

  > div {
    display: flex;

    margin-right: 20px;
  }

  svg {
    fill: #fff;
  }

  span {
    font-size: 12px;
    color: #fff;
    margin-left: 20%;
  }

  &:hover {
    border: 1px solid #21e270;
    cursor: pointer;
    background: #dfffec;

    span {
      color: #21e270;
    }

    ${SelectDelete} {
      opacity: 1;
    }

    svg {
      fill: #21e270;
    }
  }
`;

export const TablePreviewContent = styled.div<TableProps>`
  background: #f3f3f3;
  height: 580px;
  min-width: 590px;
  border-radius: 27px;
  align-items: ${({ index }) => (index > 3 ? 'center' : 'baseline')};
  justify-content: flex-start;
  padding: 2.1875rem 3rem;
  overflow: auto;

  @media screen and (max-width: 720px) {
    display: flex;
    flex-wrap: wrap;
    min-width: unset;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  > div {
    width: 100%;

    gap: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media screen and (max-width: 720px) {
      display: flex;
      flex-wrap: wrap;
      min-width: unset;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ItemStatusIcon = styled(MdChat)``;
