import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.4rem;
  color: var(--greenText);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1.2rem;
  color: var(--descripionText);
  text-align: center;
  margin-top: 40px;
`;

export const Alert = styled.p`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1rem;
  color: #C7C7CC;
  text-align: center;
  margin-top: 20px;
`;

