import { FormEvent, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingOutlined } from '@ant-design/icons';

import loginLogo from '../../shared/icons/login-logo.png';
import { Input } from '../../shared/components/Input/Input';
import { Button } from '../../shared/components/Button/Button';

import api from "services/api";

import {
  AccessYourAccount, Container,
  Form, FormContainer, InputContainer
 } from "./_PasswordForgot"

export const PasswordForgot = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [loading, setLoading] = useState(false);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if(!password || !passwordConfirmation) {
      toast.error("Preencha todos os campos!");

      return
    }

    if(password !== passwordConfirmation) {
      toast.error("Confirmação de senha incorreta!");

      return
    }

    setLoading(true);

    try {
      await api.post(`/password/reset`, {
        password,
        passwordConfirmation,
        token
      });

      toast.success("Senha alterada com sucesso!", {
        onClose: () => history.push("/")
      });
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  }

  return(
    <Container>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <img src={loginLogo} alt="Login do Payment" />
          <h1>Recupere sua senha!</h1>
          <AccessYourAccount>Digite sua nova senha:</AccessYourAccount>
          <InputContainer>
            <Input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Senha"
            />
          </InputContainer>
          <InputContainer>
            <Input
              type="password"
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              placeholder="Confirmar senha"
            />
          </InputContainer>
          <Button type="submit">
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Enviar'
            )}
          </Button>
        </Form>
      </FormContainer>
    </Container>
  )
}
