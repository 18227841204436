import React, { Dispatch, SetStateAction, useState } from 'react';
import { MdChat } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';
import { DeleteItem, Wrapper, Content, DeleteTitle } from './_DeleteTables';
import { useTableManagement } from '../../../../hooks/TableManagement';
import { toast } from 'react-toastify';
import { Modal } from 'antd';
import { Button } from 'shared/components/Button/Button';

interface DeleteTableProps {
  setSelectedToDelete: Dispatch<SetStateAction<string[]>>;
  selectedToDelete: string[];
}

const DeleteTables = ({
  selectedToDelete,
  setSelectedToDelete,
}: DeleteTableProps) => {
  const { deleteEstablishmentTables, getEstablishmentTables } =
    useTableManagement();

  function openModal() {
    setModalVisible(true);
  }

  function closeModal() {
    setModalVisible(false);
  }

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleDeleteTables = async () => {
    setLoading(true);
    try {
      await deleteEstablishmentTables(selectedToDelete);
      await getEstablishmentTables();
      setSelectedToDelete([]);
      setModalVisible(false);
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      setLoading(false);
    }
  };

  const renderTablesToExclude = () => {
    const items = selectedToDelete.map((item, index) => (
      <DeleteItem>
        <span>{index + 1}</span>
        <MdChat />
      </DeleteItem>
    ));

    return <Wrapper>{items}</Wrapper>;
  };

  return (
    <Content>
      {selectedToDelete.length > 0 && (
        <>
          <DeleteTitle>Excluir mesas selecionadas:</DeleteTitle>
          {renderTablesToExclude()}

          <button onClick={() => openModal()}>Excluir</button>
        </>
      )}
      <Modal
        visible={modalVisible}
        footer={null}
        title={''}
        onCancel={closeModal}
      >
        <p
          style={{
            fontSize: 22,
            color: '#21E270',
            fontWeight: 'bold',
            textAlign: 'center',
            width: '60%',
          }}
        >
          Tem certeza que deseja excluir essas mesas?
        </p>
        <Button
          style={{
            backgroundColor: '#ff3b30',
            fontSize: 18,
            color: '#fff',
            height: 44,
            marginBottom: 0,
          }}
          onClick={() => handleDeleteTables()}
        >
          {loading ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            'Excluir'
          )}
        </Button>
        <Button
          style={{
            backgroundColor: '#31DD71',
            fontSize: 18,
            color: '#fff',
            height: 44,
          }}
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>
      </Modal>
    </Content>
  );
};

export default DeleteTables;
