import { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

import { useCreateAccount } from '../../hooks/CreateAccount';
import { emailValidation } from '../../utils/Validations';
import api from '../../services/api';

import { TermsAndConditionsModal } from './components/TermsAndConditionsModal/TermsAndConditionsModal';

import loginLogo from '../../shared/icons/login-logo.png';
import loginAnimation from '../../shared/icons/login-animation.png';

import { Input } from '../../shared/components/Input/Input';
import { Button } from '../../shared/components/Button/Button';

import {
  Container,
  FormContainer,
  Form,
  ImageContainer,
  Description,
  InputContainer,
  CheckBoxTermsAndConditions,
} from './_ConfirmPassword';

export const ConfirmPassword = () => {
  const { data, setData, createAccount } = useCreateAccount();
  const history = useHistory();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  const getPrivacePolicy = async () => {
    try {
      const res = await api.get('/privacy-policy');

      setPrivacyPolicy(res.data.content);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPrivacePolicy();
  }, []);

  const validateFormFiels = () => {
    if (!emailValidation(data.email)) {
      toast.error('Preencha seu e-mail corretamente');
      return false;
    }

    if (!data.password.trim()) {
      toast.error('Preencha sua senha corretamente');
      return false;
    }

    if (!data.passwordConfirmation.trim()) {
      toast.error('Preencha sua confirmação de senha corretamente');
      return false;
    }

    if (data.password !== data.passwordConfirmation) {
      toast.error('As senhas não combinam');
      return false;
    }

    if (!data.contractAccepted) {
      toast.error(
        'Você não confirmou que leu e concordou com os termos obrigatórios de uso e política de privacidade',
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateFormFiels()) {
      try {
        setLoading(true);
        await createAccount();
        setLoading(false);
        history.push('/finalizar-cadastro/dados-do-estabelecimento');
      } catch (error) {
        setLoading(false);
        toast.error(error.response?.data?.message || error.toString());
      }
    }
  };

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <img src={loginLogo} alt="Login do Payment" />
          <h1>Ufa, falta pouco!</h1>
          <Description>Falta apenas criar os dados de acesso </Description>
          <InputContainer>
            <Input
              type="email"
              placeholder="E-mail"
              value={data.email}
              onChange={(event) =>
                setData({ ...data, email: event.target.value })
              }
            />
          </InputContainer>
          <InputContainer>
            <Input
              placeholder="Senha"
              type="password"
              onChange={(event) =>
                setData({ ...data, password: event.target.value })
              }
            />
          </InputContainer>
          <InputContainer>
            <Input
              placeholder="Confirmar Senha"
              type="password"
              onChange={(event) =>
                setData({ ...data, passwordConfirmation: event.target.value })
              }
            />
          </InputContainer>
          <CheckBoxTermsAndConditions>
            <input
              type="checkbox"
              checked={data.contractAccepted}
              onChange={() =>
                setData({ ...data, contractAccepted: !data.contractAccepted })
              }
            />
            <span onClick={() => setShowTermsAndConditionsModal(true)}>
              Li e concordo com o termos obrigatórios de uso e política de
              privacidade
            </span>
          </CheckBoxTermsAndConditions>
          <Button>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Cadastrar'
            )}
          </Button>
        </Form>
      </FormContainer>
      <ImageContainer>
        <img src={loginAnimation} alt="Animação da tela de login" />
      </ImageContainer>
      <TermsAndConditionsModal
        openModal={showTermsAndConditionsModal}
        setOpenModal={setShowTermsAndConditionsModal}
        privacyPolicy={privacyPolicy}
      />
    </Container>
  );
};
