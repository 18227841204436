import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin: 40px 0px;

  @media (max-width: 440px) {
   text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    height: 70px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ListMenus = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;

  @media (max-width: 1024px) {
   justify-content: center;
   margin-left: 0px;
  }
`;

export const Options = styled.div`
  @media (max-width: 1024px) {
   width: 100%;

   button {
    margin: 20px auto;
   }
  }
`;
