import styled from 'styled-components';

export const DeleteTitle = styled.h1`
  font-size: 28px;
  color: #8e8e93;
  margin-bottom: 10px;
  font-family: 'FontsFreeNetSFProTextMedium';
`;

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Content = styled.div`
  button {
    font-family: 'FontsFreeNetSFProTextMedium';
    color: #ff001c;
    font-size: 25px;
    background: rgba(255, 153, 153, 0.4);
    width: 174px;
    cursor: pointer;
    height: 58px;
    border: none;
    border-radius: 29px;
    margin-top: 28px;

    &:hover {
      background: rgba(255, 153, 153, 0.6);
    }
  }
`;

export const DeleteItem = styled.div`
  width: 63px;
  height: 63px;
  border-radius: 17px;
  border: 1px solid #ff001c;
  background: #fff0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: #ff001c;
  }

  svg {
    color: #ff001c;
  }
`;
