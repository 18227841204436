import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > h2 {
      font-family: 'FontsFreeNetSFProTextBold';
      color: lightgrey;
      font-size: 20px;
    }

    select {
      cursor: pointer;
      background: #fff;
      border: 0;
      border-radius: 40px;
      width: 204px;
      height: 42px;
      padding-left: 10px;
      box-shadow: lightgrey 0px 2px 8px -3px;
      font-family: 'FontsFreeNetSFProTextBold';
      font-size: 16px;
      color: var(--greenText);
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 460px) {
    > div {
      flex-direction: column;

      > h2 {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ContainerOpenAndClosedEstablishment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 80px;
  background-color: white;
  padding: 8px 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;

  > img {
    width: 30px;
  }

  > div {
    width: 30px;
    height: 30px;
  }

  .ant-switch-checked {
    background-color: var(--greenText);
  }
`;

export const ContainerMenu = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 360px) {
    flex-direction: column;
  }
`;
