import { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'react-modal';
import { Button } from '../Button/Button';
import Tour from 'reactour';

import exit from '../../icons/exit.png';
import iconTourModal from '../../icons/iconTourModal.png';

import {
  Title,
  Text,
  Icon,
  Container,
  Row,
  TourMenuContainer,
  TourConfig,
} from './_TourModal';

import './TourModal.css';

import { toast } from 'react-toastify';

interface Props {
  openModalTour: boolean;
  setOpenModalTour: Dispatch<SetStateAction<boolean>>;
  handleTutorialDone(isTutorialDone: boolean): Promise<void>;
}

export const TourModal: React.FC<Props> = ({
  openModalTour,
  handleTutorialDone,
  setOpenModalTour,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: '460px',
      width: '345px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };

  const [isTourOpen, setIsTourOpen] = useState(false);

  const setTutorialDone = async () => {
    try {
      const setHandleTutorial = await handleTutorialDone(true);
      setOpenModalTour(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setIsTourOpen(false);
    }
  };

  const steps = [
    {
      selector: '[data-tut="menu-register"]',
      content: () => (
        <TourMenuContainer>
          <h1>Cadastro de Cardápios</h1>
          <h6>Etapa 1</h6>
          <p>
            Clicando neste ícone você poderá cadastrar os cardápios do seu
            estabelecimento, no botão "+Novo Cardápio" separando-os por
            categorias. Assim que cadastrados você poderá edita-los a hora que
            quiser, basta clicar no botão "Cardápios" e selecionar o item que
            pretende editar ou excluir
          </p>
        </TourMenuContainer>
      ),
    },
    {
      selector: '[data-tut="solicitations"]',
      content: () => (
        <TourMenuContainer>
          <h1>Solicitações de Mesas</h1>
          <h6>Etapa 2</h6>
          <p>
            Nesta área você poderá visualizar todas as solicitações de
            agendamento, vendo o nome do cliente, dia e hora do agendamento,
            quantidade de pessoas por mesa e a avaliação do solicitante. Aqui
            você pode aceitar ou recusar as solicitações.
          </p>
        </TourMenuContainer>
      ),
    },
    {
      selector: '[data-tut="requests"]',
      content: () => (
        <TourMenuContainer>
          <h1>Gestão de Pedidos</h1>
          <h6>Etapa 3</h6>
          <p>
            Assim que o cliente der entrada no seu estabelecimento e realizar um
            pedido, você poderá ter acesso aos produtos selecionados, valores e
            acompanhar as etapas de realização do pedido. Ao final do processo,
            você emite um comprovante que será enviado automaticamente para o
            e-mail do cliente.
          </p>
        </TourMenuContainer>
      ),
    },
    {
      selector: '[data-tut="config"]',
      content: () => (
        <TourConfig>
          <h1>Configurações</h1>
          <h6>Etapa 4</h6>
          <p>
            Aqui você pode editar seus dados cadastrais, bancários, endereço,
            categorias e foto de perfil do estabelecimento. Além disso, na área
            de "Dados Bancários" você poderá
            <strong>
              {' '}
              solicitar uma maquininha Payment para as transações{' '}
            </strong>
            do seu estabelecimento.
          </p>
          <Button
            style={{
              backgroundColor: '#21E270',
              color: '#fff',
              width: '253px',
            }}
            onClick={() => setTutorialDone()}
          >
            Começar a usar!
          </Button>
        </TourConfig>
      ),
    },
  ];

  const handleTour = async () => {
    setOpenModalTour(false);
    setIsTourOpen(true);
  };

  return (
    <>
      <Modal
        isOpen={openModalTour}
        onRequestClose={() => setTutorialDone()}
        style={customStyles}
        className="react-tour-modal"
      >
        <Row>
          <img
            src={exit}
            alt="Ícone de sair"
            onClick={() => setTutorialDone()}
          />
        </Row>
        <Container>
          <Title>Seja bem-vindo(a) ao Payment!</Title>
          <Text>Vamos aprender a utilizar a plataforma?</Text>
          <Icon src={iconTourModal} />
          <Button
            style={{
              backgroundColor: '#21E270',
              color: '#fff',
              width: '183px',
              height: '40px',
            }}
            onClick={() => handleTour()}
          >
            Ok, vamos lá!
          </Button>
          <Button
            style={{
              backgroundColor: 'red',
              color: '#fff',
              width: '183px',
              height: '40px',
            }}
            onClick={() => setTutorialDone()}
          >
            Mais tarde
          </Button>
        </Container>
      </Modal>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </>
  );
};
