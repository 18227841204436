import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { WrapperPage } from '../../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../../shared/components/Input/Input';
import { Button } from '../../../../shared/components/Button/Button';
import { Select } from './components/Select/Select';
import { LoadingOutlined } from '@ant-design/icons';
import { SuccessModal } from '../../../../shared/components/SuccessModal/SuccesModal';

import { NotificationsModal } from '../../../../shared/components/NotificationsModal/NotificationsModal';

import {
  Title,
  Row,
  FormContainer,
  Form,
  InputContainer,
  ListButtons,
  SelectContainer,
  Stock,
  Divider,
} from './_RegisterNewItem';
import { CreateNewItemOnMenu, useFoodMenu } from '../../../../hooks/FoodMenu';
import { moneyMask, onlyNumberMask } from '../../../../utils/Masks';

export const RegisterNewItem = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { handleRegisterNewItem, getCategories, categories } = useFoodMenu();

  const [newItemData, setNewItemData] = useState({
    name: '',
    price: '',
    is_active: true,
    time_in_minutes: 0,
    available_quantity: 0,
    image: undefined,
    category_id: '',
    description: '',
  } as CreateNewItemOnMenu);
  const [isThereImg, setIsThereImg] = useState(false);
  const [oneItemHasAdd, setOneItemHasAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  const handleINewItemValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewItemData({ ...newItemData, [name]: value });
  };

  const [modalCheckinOpen, setModalCheckinOpen] = useState(false);
  const [imgName, setImgName] = useState('');
  const [showSuccessItemAdded, setShowSuccessItemAdded] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  const handleMoney = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedData = moneyMask(e.target.value);
    setNewItemData({ ...newItemData, price: formattedData });
  };

  const handleFinishMenu = () => {
    history.push('/cardapios');
    setOneItemHasAdd(false);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsThereImg(true);
    const fileList = e.target.files;

    if (fileList) {
      let formData = new FormData();
      setImgName(fileList[0].name);
      setNewItemData({ ...newItemData, image: fileList[0] });
    }
  };

  const renderImgName = () => {
    if (imgName) {
      return imgName;
    }

    return 'Anexar imagem';
  };

  const handleOnChangeCategory = (value: string) => {
    setNewItemData({ ...newItemData, category_id: value });
    setNewCategory(value);
  };

  const sendNewItem = async (itens: CreateNewItemOnMenu) => {
    setOneItemHasAdd(true);

    if (!itens.category_id) {
      toast.error('Selecione a categoria do cardápio corretamente');
      return;
    }

    if (!itens.name) {
      toast.error('Digite corretamente o nome do item');
      return;
    }

    if (!itens.price) {
      toast.error('Digite corretamente o preço do item');
      return;
    }

    if (itens.time_in_minutes.toString().length === 0) {
      toast.error('Digite corretamente o tempo em minutos de preparo');
      return;
    }

    if (itens.available_quantity.toString().length === 0) {
      toast.error(
        'O campo estoque não pode ser vazio, caso você não possua estoque do produto, preencha 0',
      );
      return;
    }

    if (itens.time_in_minutes.toString().match(/\D/g)) {
      toast.error('O tempo de preparo deve ser somente em números.');
      return;
    }

    setLoading(true);

    try {
      const addNewItem = await handleRegisterNewItem(itens);
      setNewItemData({
        name: '',
        price: '',
        is_active: true,
        time_in_minutes: 0,
        available_quantity: 0,
        image: undefined,
        category_id: newCategory,
        description: '',
      } as CreateNewItemOnMenu);
      setImgName('');
      setShowSuccessItemAdded(true);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <WrapperPage title="Cadastro">
      <Title>Inserir Novo Item ao Cardápio</Title>
      <Row>
        <FormContainer>
          <Form>
            <p>Categoria do Cardápio</p>
            <label>Escolha qual é a categoria do seu Cardápio:</label>
            <SelectContainer>
              <Select
                placeholder="Selecionar categoria"
                data={categories}
                onChange={(value) => handleOnChangeCategory(value)}
              />
            </SelectContainer>

            <p>Nome do item</p>
            <label>Nome:</label>
            <InputContainer>
              <Input
                name="name"
                value={newItemData.name}
                onChange={(e) => handleINewItemValue(e)}
                placeholder="Jacket Potato"
              />
            </InputContainer>

            <p>Descrição</p>
            <label>Inserir a descrição:</label>
            <InputContainer>
              <Input
                name="description"
                value={newItemData.description}
                onChange={(e) => handleINewItemValue(e)}
                placeholder="Inserir descrição"
              />
            </InputContainer>

            <p>Valor</p>
            <label>Insira o valor:</label>
            <InputContainer>
              <Input
                name="price"
                value={newItemData.price}
                onChange={(e) => handleMoney(e)}
                placeholder="R$ 24,90"
              />
            </InputContainer>

            <p>Tempo de Preparo</p>
            <label>Insira o tempo de preparo em minutos</label>
            <InputContainer>
              <Input
                name="time_in_minutes"
                value={newItemData.time_in_minutes}
                onChange={(e) => handleINewItemValue(e)}
                placeholder="Ex: 1 hora = 60 ou 10 minutos = 10"
              />
            </InputContainer>

            <p>Anexar imagem</p>
            <label>Adicione a imagem:</label>
            <Button type="button">
              <label htmlFor="avatar">
                <input
                  name="image"
                  id="avatar"
                  accept="image/*"
                  type="file"
                  onChange={(e) => handleImageChange(e)}
                />
                <span>{renderImgName()}</span>
              </label>
            </Button>
          </Form>
        </FormContainer>
        <Stock>
          <div>Estoque</div>
          <div>Insira a quantidade de unidades disponíveis</div>
          <Input
            name="available_quantity"
            value={onlyNumberMask(newItemData.available_quantity.toString())}
            onChange={(e) => handleINewItemValue(e)}
            placeholder="27 unidades"
          />
          <div>Código PDV</div>
          <div>Insira o código PDV</div>
          <Input
            name="pdv"
            value={newItemData?.pdv}
            onChange={(e) => handleINewItemValue(e)}
            placeholder="PDV..."
          />
        </Stock>
        <ListButtons>
          <Button onClick={() => history.push('/cardapio/cadastro')}>
            + Novo Cardápio
          </Button>
          <Button onClick={() => history.push('/cardapios')}>Cardápios</Button>
          <Divider />

          <Button onClick={() => sendNewItem(newItemData)}>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Adicionar item'
            )}
          </Button>
          <Button
            onClick={handleFinishMenu}
            style={{
              backgroundColor: oneItemHasAdd ? 'var(--greenText)' : '#C7C7CC',
            }}
          >
            Finalizar Cardápio
          </Button>
        </ListButtons>
      </Row>
      <SuccessModal
        openModal={showSuccessItemAdded}
        setOpenModal={setShowSuccessItemAdded}
        text="Item adicionado com sucesso!"
      />
      <NotificationsModal

      />
    </WrapperPage>
  );
};
