import styled from 'styled-components';

export const Container = styled.div`
.ant-select{
  cursor: pointer;
  background: #fff;
  border: 0;
  width: 100%;
  max-width: 350px;
  min-height: 50px;
  border-radius: 40px;
  padding: 10px 0px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  font-size: 16px;
  outline: transparent;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  font-family: 'FontsFreeNetSFProTextBold';
  color: #21E270 !important;
}
.ant-select-selector {
  width: 100% !important;
  border: 0 !important;
  border-radius: 40px !important;
  box-shadow: 0 !important;
}
.ant-select-arrow {
  color: var(--greenText);
  font-size: 1rem;
  width: 30px;
  top: 20%;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 30px;
}

.ant-select-selection-placeholder {
  font-family: 'FontsFreeNetSFProTextLight';
  color: var(--descripionText);
}
`;

export const Text = styled.span`
  font-family: 'FontsFreeNetSFProTextLight';
  color: var(--descripionText);
`;
