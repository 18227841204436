import styled from 'styled-components';
import { Button } from '../../../../../../shared/components/Button/Button';

export const Operation = styled.div`
  margin-top: 20px;

  p {
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 1.4rem;
    color: #4cd964;
    margin-bottom: 8px;
  }

  label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    margin-top: 20px;
    display: block;
  }
`;

export const RowInputHour = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 17px;

    margin-bottom: 1.7rem;
  }

  > div {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }

  > div:nth-of-type(2) {
    margin: 0px 8px;

    div + div {
      margin: 0px 10px;
    }
  }
`;

export const Container = styled.div`
  .ant-picker {
    border-radius: 30px;
    border: 0px;
    box-shadow: 0px 0px 6px 0px lightgrey;
    padding: 10px;
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 16px;
    color: var(--descripionText);
    outline: transparent;
    width: 90px;
  }
  .ant-picker-time-panel-column {
    font-family: 'FontsFreeNetSFProTextLight' !important;
  }
`;
