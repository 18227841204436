import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import './modal-responsive.css';

import { Button } from '../../../../../shared/components/Button/Button';

import { Title, Description, Alert } from './_FinishRegistrationModal';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const FinishRegistrationModal = ({ openModal, setOpenModal }: Props) => {
  const history = useHistory();

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => {
        setOpenModal(false);
        history.push('/cardapios');
      }}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="finish-registration-modal-responsive"
    >
      <Title>Cadastro Finalizado!</Title>
      <Description>
        Seus dados foram enviados para aprovação da plataforma Zoop de
        Pagamentos.
      </Description>
      <Alert>
        Fique atento(a) ao seu e-mail para a confirmação de aprovação
      </Alert>

      <Button
        style={{
          backgroundColor: 'var(--greenText)',
          color: '#fff',
          width: '80%',
          margin: '20px auto 0 auto',
        }}
        onClick={() => history.push('/cardapios')}
      >
        Ok, entendi!
      </Button>
    </Modal>
  );
};
