import { Dispatch, SetStateAction } from 'react';

import { solicitationDataModalProps } from '../Solicitations';

import { Card, SolicitationProps } from './components/Card/Card';
import { Spinner } from '../../../shared/components/Spinner/Spinner';

import { Container, ShowMore } from './_ListSolicitations';

interface ClientCardProps {
  acceptModal: () => void;
  denyModal: () => void;
  checkInModal: () => void;
  setSolicitationDataModal: Dispatch<
    SetStateAction<solicitationDataModalProps>
  >;
  solicitations: SolicitationProps[];
  total: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  loading: boolean;
  fetchSolicitations(reset: boolean, status: string, addPage: boolean): void;
  filterSolicitations: string;
  hasTables: boolean | undefined;
  isPresencialSolicitations: boolean;
  isAcceptSolicitations: boolean;
  setModalCheckinAtPageOpen: Dispatch<SetStateAction<boolean>>;
  modalCheckinAtPageOpen: boolean;
  notificationsCheckin: any[];
  setNotificationsCheckin: Dispatch<SetStateAction<any[]>>;
}

export function ListSolicitations({
  acceptModal,
  denyModal,
  checkInModal,
  setSolicitationDataModal,
  solicitations,
  total,
  page,
  setPage,
  loading,
  fetchSolicitations,
  filterSolicitations,
  hasTables,
  isPresencialSolicitations,
  isAcceptSolicitations,
  setModalCheckinAtPageOpen,
  modalCheckinAtPageOpen,
  notificationsCheckin,
  setNotificationsCheckin,
}: ClientCardProps) {
  const handleLoadMore = () => {
    fetchSolicitations(false, filterSolicitations, true);
  };

  const renderCards = () => {
    if (solicitations.length > 0) {
      const cards = solicitations.map((item) => (
        <Card
          data={item}
          acceptModal={acceptModal}
          denyModal={denyModal}
          checkInModal={checkInModal}
          setSolicitationDataModal={setSolicitationDataModal}
          hasTables={hasTables}
          isPresencialSolicitations={isPresencialSolicitations}
          isAcceptSolicitations={isAcceptSolicitations}
          setModalCheckinAtPageOpen={setModalCheckinAtPageOpen}
          modalCheckinAtPageOpen={modalCheckinAtPageOpen}
          notificationsCheckin={notificationsCheckin}
          setNotificationsCheckin={setNotificationsCheckin}
        />
      ));
      return cards;
    }
  };

  const renderShowMoreSolicitations = () => {
    if (loading) {
      return <Spinner />;
    }

    if (solicitations.length < total) {
      return (
        <ShowMore
          onClick={() => {
            handleLoadMore();
          }}
        >
          Carregar mais solicitações
        </ShowMore>
      );
    }
  };

  return (
    <>
      <Container>{renderCards()}</Container>
      {renderShowMoreSolicitations()}
    </>
  );
}
