import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

import { useAuth } from '../../../hooks/Auth';
import { useFinalizeRegistration } from '../../../hooks/FinalizeRegistration';
import { removeCepMask } from '../../../utils/Masks';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { WrapperPage } from '../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../shared/components/Input/Input';
import { Button } from '../../../shared/components/Button/Button';
import { SendFileModal } from '../../../shared/components/SendFileModal/SendFileModal';
import fileIcon from '../../../shared/icons/file-icon.png';

import step2 from '../../../shared/icons/step2.png';

import {
  Title,
  Row,
  FormContainer,
  Form,
  RowInput,
  InputContainer,
  Next,
  Step,
} from './_AddressData';

interface ViaCepFields {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface EstablichmentProps {
  id: string;
  document: EstablichmentDocumentProps;
}

interface EstablichmentDocumentProps {
  registered_bank_account: boolean;
}

export const AddressData = () => {
  const history = useHistory();
  const {
    establishmentData,
    setEstablishmentData,
    proofOfResidenceDocument,
    setProofOfResidenceDocument,
    createEstablishment,
    registerDocuments,
    getEstablichmentID,
    updateStatusDocuments,
  } = useFinalizeRegistration();
  const { checkIfUserHasEstablishment } = useAuth();
  const [showSendFileModal, setShowSendFileModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchAddressData = () => {
    axios
      .get<ViaCepFields>(
        `https://viacep.com.br/ws/${establishmentData.address.cep}/json/`,
      )
      .then((response) => {
        if (response.data.cep) {
          setEstablishmentData({
            ...establishmentData,
            address: {
              ...establishmentData.address,
              street: response.data.logradouro,
              neighborhood: response.data.bairro,
              city: response.data.localidade,
              uf: response.data.uf,
            },
          });
        } else {
          toast.error('Preencha o CEP corretamente');
        }
      })
      .catch((error) =>
        toast.error(error.response?.message?.data || error.toString()),
      );
  };

  const handleOnBlurCepField = () => {
    if (establishmentData.address.cep.length === 8) {
      fetchAddressData();
    }
  };

  const validateFormFields = () => {
    if (!establishmentData.address.cep.trim()) {
      toast.error('Preencha o CEP corretamente');
      return false;
    }

    if (!establishmentData.address.street.trim()) {
      toast.error('Preencha a rua corretamente');
      return false;
    }

    if (!establishmentData.address.neighborhood.trim()) {
      toast.error('Preencha o bairro corretamente');
      return false;
    }

    if (!establishmentData.address.city.trim()) {
      toast.error('Preencha a cidade corretamente');
      return false;
    }

    if (!establishmentData.address.cep.trim()) {
      toast.error('Preencha o CEP corretamente');
      return false;
    }

    if (!establishmentData.address.number.trim()) {
      toast.error('Preencha o número do estabelecimento corretamente');
      return false;
    }

    if (!proofOfResidenceDocument?.name) {
      toast.error('Anexe o comprovante de endereço corretamente');
      return false;
    }

    return true;
  };

  const handleCreateEstablishment = async () => {
    try {
      await createEstablishment();
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const handleRegisterDocuments = async () => {
    let establishmentID = '';

    try {
      establishmentID = await getEstablichmentID();
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      return false;
    }

    try {
      await registerDocuments('identity', establishmentID);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      return false;
    }

    try {
      await registerDocuments('cnpj', establishmentID);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      return false;
    }

    try {
      await registerDocuments('activity-performed', establishmentID);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      return false;
    }

    try {
      await registerDocuments('residence', establishmentID);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
      return false;
    }

    return true;
  };

  const handleNextStep = async () => {
    if (!validateFormFields()) {
      return;
    }

    setLoading(true);
    const userHasEstablishment = await checkIfUserHasEstablishment();

    if (!userHasEstablishment) {
      await handleCreateEstablishment();
    }

    const registerDocuments = await handleRegisterDocuments();
    setLoading(false);

    if (registerDocuments) {
      history.push('/finalizar-cadastro/dados-bancarios');
    }
  };

  const renderProofOfResidenceDocumentName = () => {
    if (proofOfResidenceDocument?.name) {
      return proofOfResidenceDocument.name;
    }

    return 'Anexar Documento';
  };

  return (
    <WrapperPage title="Cadastro" showMenu={false}>
      <Title>Finalizar Cadastro de Dados</Title>
      <Row>
        <FormContainer>
          <Form>
            <p>Endereço</p>
            <label>Informe o endereço do Estabelecimento: </label>
            <RowInput>
              <InputContainer>
                <Input
                  placeholder="CEP:"
                  value={establishmentData.address.cep}
                  onChange={(event) =>
                    setEstablishmentData({
                      ...establishmentData,
                      address: {
                        ...establishmentData.address,
                        cep: removeCepMask(event.target.value),
                      },
                    })
                  }
                  onBlur={() => handleOnBlurCepField()}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  placeholder="Rua:"
                  value={establishmentData.address.street}
                  onChange={(event) =>
                    setEstablishmentData({
                      ...establishmentData,
                      address: {
                        ...establishmentData.address,
                        street: event.target.value,
                      },
                    })
                  }
                />
              </InputContainer>
            </RowInput>
            <InputContainer style={{ marginTop: '-20px' }}>
              <Input
                placeholder="Bairro:"
                value={establishmentData.address.neighborhood}
                onChange={(event) =>
                  setEstablishmentData({
                    ...establishmentData,
                    address: {
                      ...establishmentData.address,
                      neighborhood: event.target.value,
                    },
                  })
                }
              />
            </InputContainer>
            <InputContainer>
              <Input
                placeholder="Cidade:"
                value={establishmentData.address.city}
                onChange={(event) =>
                  setEstablishmentData({
                    ...establishmentData,
                    address: {
                      ...establishmentData.address,
                      city: event.target.value,
                    },
                  })
                }
              />
            </InputContainer>
            <RowInput style={{ marginTop: '-20px' }}>
              <InputContainer>
                <Input
                  placeholder="Estado:"
                  value={establishmentData.address.uf}
                  onChange={(event) =>
                    setEstablishmentData({
                      ...establishmentData,
                      address: {
                        ...establishmentData.address,
                        uf: event.target.value,
                      },
                    })
                  }
                />
              </InputContainer>
              <InputContainer>
                <Input
                  placeholder="Número:"
                  type="number"
                  value={establishmentData.address.number}
                  onChange={(event) =>
                    setEstablishmentData({
                      ...establishmentData,
                      address: {
                        ...establishmentData.address,
                        number: event.target.value,
                      },
                    })
                  }
                />
              </InputContainer>
            </RowInput>
            <label>Comprovante de Endereço:</label>
            <Button
              iconLeft={fileIcon}
              type="button"
              onClick={() => setShowSendFileModal(true)}
            >
              {renderProofOfResidenceDocumentName()}
            </Button>
            <Next>
              {loading ? (
                <LoadingOutlined
                  style={{ fontSize: 24, margin: '0px auto' }}
                  spin
                />
              ) : (
                <>
                  <div onClick={() => history.goBack()}>
                    <FaChevronLeft />
                    Voltar
                  </div>
                  <div onClick={() => handleNextStep()}>
                    Próximo <FaChevronRight />
                  </div>
                </>
              )}
            </Next>
          </Form>
        </FormContainer>
        <Step>
          <img src={step2} alt="Etapa de cadastrar dados do estabelecimento" />
          <Button
            style={{ backgroundColor: '#94FFC1', color: '#fff' }}
            disabled
          >
            Finalizar Cadastro
          </Button>
        </Step>
      </Row>
      <SendFileModal
        openModal={showSendFileModal}
        setOpenModal={setShowSendFileModal}
        setFile={setProofOfResidenceDocument}
      />
    </WrapperPage>
  );
};
