import { Dispatch, HTMLAttributes, SetStateAction } from 'react';
import { Content } from '../../components/Content/Content';
import { useAuth } from '../../../hooks/Auth';

import menu from '../../icons/menu-green.png';

import { Background, Container, Title, MenuIcon, ExitButton } from './_Header';

interface Props extends HTMLAttributes<HTMLHeadElement> {
  title: string;
  showSideBar: boolean;
  setShowSideBar: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({ title, showSideBar, setShowSideBar }: Props) => {
  const { signOut } = useAuth();

  const renderIconMenu = () => {
    if (!showSideBar) {
      return (
        <MenuIcon
          onClick={() => setShowSideBar(true)}
          src={menu}
          alt="Ícone do menu"
        />
      );
    }
  };

  return (
    <Background>
      <Container>
        <Content>
          <Title>
            {renderIconMenu()}
            {title}
          </Title>
          <ExitButton>
            <span onClick={() => signOut()}>Sair</span>
          </ExitButton>
        </Content>
      </Container>
    </Background>
  );
};
