import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from 'react-modal';

import './modal-responsive.css';

import api from '../../../../../../services/api';

import { moneyMask } from '../../../../../../utils/Masks';

import { Button } from '../../../../../../shared/components/Button/Button';
import { Spinner } from '../../../../../../shared/components/Spinner/Spinner';
import { useAuth } from '../../../../../../hooks/Auth';
import { toast } from 'react-toastify';

import { Title, Description, TextArea } from './_RequestMachineModal';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setShowSuccessModal: Dispatch<SetStateAction<boolean>>;
}

export const RequestMachineModal = ({
  openModal,
  setOpenModal,
  setShowSuccessModal,
}: Props) => {
  const { data } = useAuth();

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleRequestMachine = async () => {
    setLoading(true);
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    try {
      await api.post(
        '/establishments/payment-machine',
        {},
        {
          headers,
        },
      );
      setOpenModal(false);
      setShowSuccessModal(true);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const getPaymentMachineValue = async () => {
    setLoading(true);
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    try {
      const response = await api.get('/variables/payment-machine-rental', {
        headers,
      });
      setValue(response.data.value);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentMachineValue();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="authentication-modal-responsive"
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Title>Solicitação de Maquininha</Title>
          <Description>
            Ao solicitar, estou ciente da cobrança mensal de{' '}
            <span>aluguel no valor R${value.toString()}</span> referente ao uso
            da maquininha
          </Description>

          <br />
          <br />

          <Button
            style={{
              fontSize: '1rem',
              backgroundColor: 'var(--greenText)',
              color: '#fff',
              width: '80%',
              margin: '0 auto',
              height: '40px',
            }}
            onClick={() => handleRequestMachine()}
          >
            Confirmar
          </Button>
          <Button
            style={{
              fontSize: '1rem',
              backgroundColor: '#FF3B30',
              color: '#fff',
              width: '80%',
              margin: '10px auto',
              height: '40px',
            }}
            onClick={() => setOpenModal(false)}
          >
            Cancelar
          </Button>
        </>
      )}
    </Modal>
  );
};
