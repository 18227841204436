import { Select as SelecteAntd, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { BiChevronDown } from 'react-icons/bi';

import { Container } from './_Select';

interface Props extends SelectProps<SelectValue> {
  data: string[];
}

export const Select = ({ data, ...rest }: Props) => {
  const { Option } = SelecteAntd;

  const renderOptions = () => {
    return data.map((element: string) => (
      <Option
        style={{
          color: 'var(--descripionText)',
          fontFamily: 'FontsFreeNetSFProTextBold',
        }}
        value={element}
        key={element}
      >
        {element}
      </Option>
    ));
  };

  return (
    <Container>
      <SelecteAntd
        suffixIcon={<BiChevronDown size={40} />}
        bordered={false}
        {...rest}
      >
        {renderOptions()}
      </SelecteAntd>
    </Container>
  );
};
