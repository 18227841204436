import { useState, useEffect, useMemo } from 'react';
import { Switch } from 'antd';
import { useAuth } from '../../hooks/Auth';
import { toast } from 'react-toastify';

import socketio from 'socket.io-client';

import api from '../../services/api';
import { SolicitationProps } from './ListSolicitations/components/Card/Card';

import { WrapperPage } from '../../shared/components/WrapperPage/WrapperPage';
import { SolicitationModal } from '../../shared/components/SolicitationModal/SolicitationModal';
import { ListSolicitations } from './ListSolicitations/ListSolicitations';
import { SpinnerModal } from '../../shared/components/SpinnerModal/SpinnerModal';
import { Spinner } from '../../shared/components/Spinner/Spinner';

import OpenEstablishmentIcon from '../../shared/icons/open-establishment-icon.png';
import ClosedEstablishmentIcon from '../../shared/icons/closed-establishment-icon.png';

import { NotificationsModal } from '../../shared/components/NotificationsModal/NotificationsModal';

import {
  Container,
  ContainerOpenAndClosedEstablishment,
  ContainerMenu,
} from './_Solicitations';
import { ModalCheckinAtPage } from './ListSolicitations/components/ModalCheckin/ModalCheckinAtPage';
import { useSocketIo } from '../../hooks/Socket';

export interface solicitationDataModalProps {
  idSolicitation: string;
  idEstablishment: string;
  idTable?: string;
}

interface EstablishmentTables {
  id: string;
  name: string;
  amount_chairs: number;
  is_available: boolean;
}

interface FetchSolicitationsProps {
  results: SolicitationProps[];
  page: number;
  limit: number;
  total: number;
  hasTables: boolean;
}

export function Solicitations() {
  const { data, setData } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [solicitationDataModal, setSolicitationDataModal] =
    useState<solicitationDataModalProps>({} as solicitationDataModalProps);
  const [loadingAccepOrDenySolicitation, setLoadingAccepOrDenySolicitation] =
    useState(false);
  const [solicitations, setSolicitations] = useState<SolicitationProps[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSolicitations, setFilterSolicitations] = useState<string>('');
  const [hasTables, setHasTables] = useState<boolean | undefined>();
  const [
    loadingOpenAndClosedEstablishment,
    setLoadingOpenAndClosedEstablishment,
  ] = useState<boolean>(false);
  const [
    establishmentReceivingReservation,
    setEstablishmentReceivingReservation,
  ] = useState(data.user.establishment?.accept_reservations);

  const [isPresencialSolicitations, setIsPresencialSolicitations] =
    useState(false);
  const [isAcceptSolicitations, setIsAcceptSolicitations] = useState(false);
  const [modalCheckinAtPageOpen, setModalCheckinAtPageOpen] = useState(false);
  const [notificationsCheckin, setNotificationsCheckin] = useState<any[]>([]);
  const [statusSelected, setStatusSelected] = useState(() =>
    hasTables ? 'true' : 'pending',
  );

  const {
    handleRefuseReservation,
    recallSolicitationList,
    setRecallSolicitationList,
  } = useSocketIo();

  useEffect(() => {
    if (recallSolicitationList) {
      setRecallSolicitationList(false);
      fetchSolicitations(true, statusSelected, false);
    }
  }, [recallSolicitationList]);

  const fetchSolicitations = async (
    reset = false,
    status = 'true',
    addPage = false,
  ) => {
    try {
      setLoading(true);
      const headers = { Authorization: `Bearer ${data.accessToken}` };

      let index = page;

      if (status === 'false') {
        setIsPresencialSolicitations(true);
      } else {
        setIsPresencialSolicitations(false);
      }

      if (status === 'accept') {
        setIsAcceptSolicitations(true);
      } else {
        setIsAcceptSolicitations(false);
      }

      if (addPage) {
        index = index + 1;
        setPage(index);
      }

      if (reset) index = 1;

      let route = `/establishments/${data.user.establishment?.id}/reservations?page=${index}&limit=20`;

      if (status) {
      }

      switch (status) {
        case 'accept':
          route += '&status[]=accept';
          break;
        case 'true':
          route += '&isAppointment=true&status[]=pending&status[]=canceled';
          break;
        case 'false':
          route += '&isAppointment=false';
          break;
        case 'pending':
          route += '&status[]=pending';
          break;
        case 'refuse':
          route += '&status[]=refuse';
          break;
      }

      const response = await api.get<FetchSolicitationsProps>(route, {
        headers,
      });

      if (reset) {
        setSolicitations(response.data.results);
      } else {
        setSolicitations([...solicitations, ...response.data.results]);
      }

      setHasTables(response.data.hasTables);
      setTotal(response.data.total);
      setLoading(false);
      setStatusSelected(status);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  useEffect(() => {
    getEstablishmentTablesCheck();
  }, []);

  const getEstablishmentTablesCheck = async () => {
    const today = new Date();

    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.get<EstablishmentTables[]>(
      `/tables/available?date=${today}&establishmentId=${data.user.establishment?.id}`,
      {
        headers,
      },
    );

    if (response.data.length === 0) {
      setHasTables(false);
      fetchSolicitations(false, 'pending', false);
    } else {
      setHasTables(true);
      fetchSolicitations(false, 'true', false);
    }
  };

  const handleSolicitation = async (status: string) => {
    try {
      setLoadingAccepOrDenySolicitation(true);

      const headers = { Authorization: `Bearer ${data.accessToken}` };
      if (status === 'checkin') {
        await api.patch(
          `/reservations/${solicitationDataModal.idSolicitation}/${status}`,
          {
            establishmentId: solicitationDataModal.idEstablishment,
          },
          { headers },
        );

        const newSolicitations = solicitations.map((solicitation) => {
          if (solicitation.id === solicitationDataModal.idSolicitation) {
            return { ...solicitation, checkin: true };
          }
          return solicitation;
        });

        setSolicitations(newSolicitations);
      } else {
        if (solicitationDataModal.idTable != null) {
          await api.post(
            `/reservations/${solicitationDataModal.idSolicitation}/${status}`,
            {
              establishmentId: solicitationDataModal.idEstablishment,
              tableId: solicitationDataModal.idTable,
            },
            { headers },
          );
        } else {
          await api.post(
            `/reservations/${solicitationDataModal.idSolicitation}/${status}`,
            {
              establishmentId: solicitationDataModal.idEstablishment,
            },
            { headers },
          );
        }
        const newSolicitations = solicitations.map((solicitation) => {
          if (solicitation.id === solicitationDataModal.idSolicitation) {
            return { ...solicitation, status };
          }
          return solicitation;
        });

        setSolicitations(newSolicitations);
      }
      setLoadingAccepOrDenySolicitation(false);

      handleRefuseReservation();
    } catch (error) {
      setLoadingAccepOrDenySolicitation(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  function acceptModal() {
    setModalText('aceitar');
    setOpenModal(true);
  }

  function checkInModal() {
    setModalText('fazer check-in');
    setOpenModal(true);
  }

  function denyModal() {
    setModalText('recusar');
    setOpenModal(true);
  }

  const handleAutomaticallyOrders = async (value: boolean) => {
    setLoadingOpenAndClosedEstablishment(true);
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    try {
      await api.patch(
        `/establishments/accept-reservations?id=${data.user.establishment?.id}`,
        {
          acceptReservations: value,
        },
        {
          headers,
        },
      );

      if (value) {
        toast.success('Estabelecimento está recebendo reservas');
      } else {
        toast.success('Estabelecimento não está mais recebendo reservas');
      }

      setEstablishmentReceivingReservation(value);
      setData({
        ...data,
        user: {
          ...data.user,
          establishment: {
            ...data.user.establishment,
            accept_reservations: value,
          },
        },
      });
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoadingOpenAndClosedEstablishment(false);
    }
  };

  const renderIconOpenAndClosedEstablishment = () => {
    if (loadingOpenAndClosedEstablishment) {
      return <Spinner />;
    }

    if (establishmentReceivingReservation) {
      return (
        <img
          src={OpenEstablishmentIcon}
          alt="Ícone de estabelecimento aberto"
        />
      );
    }

    return (
      <img
        src={ClosedEstablishmentIcon}
        alt="Ícone de estabelecimento fechado"
      />
    );
  };

  return (
    <WrapperPage title="Solicitações">
      <Container>
        <div>
          <h2>
            {' '}
            {hasTables ? 'Solicitação de Mesas' : 'Solicitações de atendimento'}
          </h2>
          <ContainerMenu>
            <ContainerOpenAndClosedEstablishment>
              {renderIconOpenAndClosedEstablishment()}
              <Switch
                checked={establishmentReceivingReservation}
                onChange={(value) => handleAutomaticallyOrders(value)}
              />
            </ContainerOpenAndClosedEstablishment>
            <select
              onChange={(event) => {
                setStatusSelected(event.target.value);
                setPage(1);
                setFilterSolicitations(event.target.value);
                fetchSolicitations(true, event.target.value);
              }}
            >
              {hasTables ? (
                <>
                  <option value="true">Agendamentos</option>
                  <option value="accept">Solicitações Aceitas</option>
                  <option value="false">Solicitações Presenciais</option>
                </>
              ) : (
                <>
                  <option value="pending">Solicitações</option>
                  <option value="accept">Solicitações Aceitas</option>
                  <option value="refuse">Solicitações Recusadas</option>
                </>
              )}
            </select>
          </ContainerMenu>
        </div>
      </Container>
      <ListSolicitations
        solicitations={solicitations}
        acceptModal={acceptModal}
        denyModal={denyModal}
        checkInModal={checkInModal}
        setSolicitationDataModal={setSolicitationDataModal}
        total={total}
        page={page}
        setPage={setPage}
        loading={loading}
        fetchSolicitations={fetchSolicitations}
        filterSolicitations={filterSolicitations}
        hasTables={hasTables}
        isPresencialSolicitations={isPresencialSolicitations}
        isAcceptSolicitations={isAcceptSolicitations}
        setModalCheckinAtPageOpen={setModalCheckinAtPageOpen}
        modalCheckinAtPageOpen={modalCheckinAtPageOpen}
        notificationsCheckin={notificationsCheckin}
        setNotificationsCheckin={setNotificationsCheckin}
      />
      <SolicitationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalText={modalText}
        solicitationDataModal={solicitationDataModal}
        handleSolicitation={handleSolicitation}
        text={`Tem certeza que deseja
        ${modalText} ${
          modalText !== 'fazer check-in' ? 'o atendimento?' : 'do cliente?'
        }`}
      />
      <SpinnerModal openModal={loadingAccepOrDenySolicitation} />
      <ModalCheckinAtPage
        setModalCheckinAtPageOpen={setModalCheckinAtPageOpen}
        modalCheckinAtPageOpen={modalCheckinAtPageOpen}
        notificationsCheckin={notificationsCheckin}
      />
      <NotificationsModal />
    </WrapperPage>
  );
}
