import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin-top: 40px;

  @media (max-width: 440px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 460px;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 20px 40px;

  > textarea {
    width: 100%;
    height: 400px;
    border: none;
    text-align: left;
    color: #8e8e93;
    font: 1.4rem 'FontsFreeNetSFProTextLight', sans-serif;
    resize: none;
    outline: transparent;
    white-space: break-spaces;
    background-color: white;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--greenText);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--greenText);
    }
  }

  @media (max-width: 1140px) {
    margin: 20px auto;
  }

  @media (max-width: 430px) {
    padding: 20px;
    width: 100%;
  }
`;

export const ButtonGreen = styled(Button)`
  background-color: var(--greenText);
  color: #fff;
`;

export const Divider = styled.div`
  margin: 120px;

  @media (max-width: 1140px) {
    margin: 0px;
  }
`;

export const ListButtons = styled.div`
  > button {
    width: 260px;
  }

  @media (max-width: 1140px) {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > button {
      margin: 20px;
    }
  }

  @media (max-width: 330px) {
    width: 100%;
  }
`;
