import { Modal, notification } from 'antd';
import { useAuth } from '../../../../hooks/Auth';
import React, { useState, useEffect, useMemo } from 'react';
import socketio, { io } from 'socket.io-client';

import { ReactComponent as TableIcon } from '../../../../shared/icons/table-icon-modal-checkin.svg';

import TimerIcon from '../../../../shared/icons/timer.svg';
import { moneyMask } from '../../../../utils/Masks';

import {
  TitleModal,
  NameTitle,
  TableNumber,
  DateTitle,
  ImgAvatar,
  LeftContent,
  Column,
  RightContent,
  GuestContainer,
  TitleText,
  OrderContainer,
  Wrapper,
  WrapperOrderSumamary,
  InformationOrder,
  DescriptionOrder,
  TotalToPay,
  Guest,
  Status,
  ModalContent,
  TotalOrderValue,
} from './_ModalResume';

import { LoadingOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import api from 'services/api';
import { Button } from '../../../../shared/components/Button/Button';
import { useSocketIo } from '../../../../hooks/Socket';

const ModalResume = ({
  modalResumeOpen,
  setModalResumeOpen,
  notifications,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [hasTables, setHasTables] = useState<boolean | undefined>();
  const [currentCustomerId, setCurrentCustomerId] = useState(() => {
    if (notifications.length) {
      return notifications[0].results.orders[0].user_id;
    }
  });

  const { setRecallSolicitationList, setRecallOrderList } = useSocketIo();

  const getEstablishmentTablesCheck = async () => {
    const today = new Date();

    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.get(
      `/tables/available?date=${today}&establishmentId=${data.user.establishment?.id}`,
      {
        headers,
      },
    );

    if (response.data.length === 0) {
      setHasTables(false);
    } else {
      setHasTables(true);
    }
  };

  useEffect(() => {
    getEstablishmentTablesCheck();
  }, []);

  const { data } = useAuth();

  const renderDate = (dateItem: string) => {
    const date = new Date(dateItem);

    const monthNumber = date.getMonth() + 1;
    const month =
      monthNumber.toString().length === 1 ? '0' + monthNumber : monthNumber;

    const day =
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate();

    const hours =
      date.getHours().toString().length === 1
        ? '0' + date.getHours()
        : date.getHours();

    const minutes =
      date.getMinutes().toString().length === 1
        ? '0' + date.getMinutes()
        : date.getMinutes();

    const dateFormated = day + '/' + month + '/' + date.getFullYear();

    return (
      <p>
        Agendado para: {dateFormated} às {hours}:{minutes}
      </p>
    );
  };

  const renderGuests = () => {
    if (notifications.length > 0) {
      return notifications.map((item: any) => {
        return item.results.guests.map((item: any) => {
          if (item.customer_id !== currentCustomerId) {
            <Guest>
              <img src={item.user.avatar_url} alt="Foto do usuário convidado" />
              <div>
                {item.user.first_name} {item.user.last_name}
              </div>
              <Status isFinished={item.is_finished}>
                {item.is_finished ? '(Finalizado)' : '(Não Finalizado)'}
              </Status>
            </Guest>;
          }
        });
      });
    }
  };

  const handlePayCash = async () => {
    setLoading(true);
    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      await api.post(
        `payments/cash`,
        {
          reservationId: notifications[0].results.orders[0].reservation_id,
          customerId: notifications[0].results.orders[0].user_id,
        },
        { headers },
      );

      setRecallSolicitationList(true);
      setRecallOrderList(true);
      setModalResumeOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const renderPayCashButton = () => {
    return (
      <Button
        style={{
          color: '#fff',
          backgroundColor: 'var(--greenText)',
          margin: '40px 0px',
        }}
        onClick={() => handlePayCash()}
      >
        {loading ? (
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        ) : (
          'Confirmar recebimento'
        )}
      </Button>
    );
  };

  const renderOrders = () => {
    if (notifications.length) {
      return notifications.map((item: any) => {
        return item.results.orders.map((item: any, index: any) => (
          <WrapperOrderSumamary key={index}>
            <InformationOrder>
              <div>
                Nº do Pedido: <span>{item.order_number}</span>
              </div>
              <div>
                <img src={TimerIcon} alt="Ícone de relógio" />
                {renderDate(item.created_at)}
              </div>
            </InformationOrder>
            <DescriptionOrder>
              <div>Descrição</div>
              <ul>
                {item.order_items.map((order_item: any) => (
                  <li>
                    <div>
                      {order_item.quantity} - {order_item.product.name}
                    </div>
                    <div>
                      {order_item.product.name}:
                      <span>R$ {moneyMask(order_item.item_price)}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </DescriptionOrder>
            <TotalToPay>
              Total:
              <span>R$ {moneyMask(item.final_price)}</span>
            </TotalToPay>
          </WrapperOrderSumamary>
        ));
      });
    }
  };

  const renderTotalOrderValue = () => {
    return (
      <TotalOrderValue>
        Valor Total dos Pedidos: <span>R$ {moneyMask(String(notifications[0].total_amount))}</span>
      </TotalOrderValue>
    )
  };

  function renderResumeModal() {
    if (notifications.length) {
      return notifications.map((item: any, index: any) => (
        <ModalContent key={index}>
          <TitleModal>Resumo da Reserva</TitleModal>
          <Wrapper>
            <Column>
              <LeftContent>
                <ImgAvatar src={item.results.user.avatar_url} />
              </LeftContent>
              <RightContent>
                <NameTitle>
                  {item.results.user.first_name} {item.results.user.last_name}
                </NameTitle>
                <DateTitle>{renderDate(item.results.created_at)}</DateTitle>
                <TableNumber>Mesa número: {item.results.name}</TableNumber>
              </RightContent>
            </Column>
            <GuestContainer>
              <TitleText>
                Convidados:
                <span> {item.results.guests.length - 1}</span>
              </TitleText>
              {renderGuests()}
            </GuestContainer>
            <TitleText>
              Resumo dos Pedidos -{' '}
              <span>
                {item.results.user.first_name} {item.results.user.last_name}
              </span>
            </TitleText>
            <OrderContainer>{renderOrders()}</OrderContainer>
            {renderTotalOrderValue()}
            {renderPayCashButton()}
          </Wrapper>
        </ModalContent>
      ));
    }
  }

  if (!hasTables) return null;

  return (
    <Modal
      visible={modalResumeOpen}
      footer={null}
      onCancel={() => setModalResumeOpen(false)}
    >
      {notifications && <> {renderResumeModal()}</>}
    </Modal>
  );
};

export default ModalResume;
