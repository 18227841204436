import menuIcon from '../../../../shared/icons/menu-icon.png';
import edit from '../../../../shared/icons/edit.png';

import {
  Container,
  Card,
  ContainerImg,
  Description,
  EditContainer,
} from './_MenuItem';
import { useEffect, useState } from 'react';
import { useFoodMenu } from '../../../../hooks/FoodMenu';
import { DeleteCategoryModal } from '../DeleteCategoryModal/DeleteCategoryModal';

interface Props {
  handleEditMenuItem: (menuItemId: string) => void;
  handleDeleteCategory: (categoryId: string) => void;
  name: string;
  id: string;
}

export const MenuItem = ({
  handleEditMenuItem,
  name,
  id,
  handleDeleteCategory,
}: Props) => {
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

  const renderDeleteCategoryModal = () => {
    if (showDeleteCategoryModal) {
      return (
        <DeleteCategoryModal
          openModal={showDeleteCategoryModal}
          setOpenModal={setShowDeleteCategoryModal}
          categoryId={id}
          handleDeleteCategoryEstablishment={handleDeleteCategory}
        />
      );
    }
  };

  return (
    <Container>
      <Card>
        <ContainerImg>
          <img src={menuIcon} alt="Ícone do cardapio" />
        </ContainerImg>
        <Description>{name}</Description>
      </Card>
      <EditContainer>
        <img
          onClick={() => handleEditMenuItem(id)}
          src={edit}
          alt="Ícone de editar cardápio"
        />
        <span onClick={() => handleEditMenuItem(id)}>Editar</span>
        <span onClick={() => setShowDeleteCategoryModal(true)}>Excluir</span>
      </EditContainer>
      {renderDeleteCategoryModal()}
    </Container>
  );
};
