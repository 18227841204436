import styled from 'styled-components';

export const Title = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.2rem;
  color: var(--greenText);
  text-align: center;
  margin-top: 20px;
`;

export const Text = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  color: #aeaeb2;
  text-align: center;
  margin: 20px 0 20px 0;
`;

export const Container = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  height: 90px;
  display: block;
  margin-top: 20px;
  margin: 20px auto 0px auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;

  > img {
    cursor: pointer;
    margin: 0px 20px;
  }
`;

export const TourMenuContainer = styled.div`
  h1 {
    font-family: 'FontsFreeNetSFProTextBold';
    color: var(--greenText);
    text-align: center;
    font-size: 22px;
  }

  h6 {
    font-family: 'FontsFreeNetSFProTextBold';
    color: #8e8e93;
    text-align: center;
    margin: 10px 0 10px 0;
  }

  p {
    font-family: 'FontsFreeNetSFProTextBold';
    color: #bfbfc1;
    text-align: center;
  }
`;

export const TourConfig = styled(TourMenuContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;

  strong {
    color: #8e8e93;
  }
`;
