import { useState, Dispatch, SetStateAction } from 'react';

import { Container, Header, Footer, RateBox } from './_Card';

import { solicitationDataModalProps } from '../../../Solicitations';
import { ModalInfoSolicitation } from '../../../ModalInfoSolicitation/ModalInfoSolicitation';

import { ReactComponent as TableIcon } from '../../../../../shared/icons/table-icon-modal-checkin.svg';
import { ReactComponent as CheckinIcon } from '../../../../../shared/icons/checkin-icon.svg';
import { ReactComponent as TableInactive } from '../../../../../shared/icons/table-icon-inactive.svg';

import { useHistory } from 'react-router-dom';

import clientImg from '../../../../../assets/clientImg.png';
import acceptIcon from '../../../../../shared/icons/acceptIcon.png';
import denyIcon from '../../../../../shared/icons/denyIcon.png';
import userCheckin from '../../../../../shared/icons/user-checkin.png';
import rate from '../../../../../assets/rate.png';

import { AiFillStar } from 'react-icons/ai';
import { useTableManagement } from '../../../../../hooks/TableManagement';

import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface UserProps {
  avatar_url: string;
  first_name: string;
  last_name: string;
  rating: string;
}

export interface SolicitationProps {
  id: string;
  date: string;
  status: string;
  checkin: boolean;
  amount_of_people: number;
  user_id: string;
  establishment_id: string;
  user: UserProps;
  table?: SolicitationTableProps;
  table_id?: string;
  is_appointment?: boolean;
  checkin_requested?: boolean;
}

interface SolicitationTableProps {
  name: string;
}

interface CardProps {
  data: SolicitationProps;
  acceptModal(): void;
  denyModal(): void;
  checkInModal(): void;
  setSolicitationDataModal: Dispatch<
    SetStateAction<solicitationDataModalProps>
  >;
  hasTables: boolean | undefined;
  isPresencialSolicitations: boolean;
  isAcceptSolicitations: boolean;
  setModalCheckinAtPageOpen: Dispatch<SetStateAction<boolean>>;
  modalCheckinAtPageOpen: boolean;
  notificationsCheckin: any[];
  setNotificationsCheckin: Dispatch<SetStateAction<any[]>>;
}

export const Card = ({
  data,
  acceptModal,
  denyModal,
  checkInModal,
  setSolicitationDataModal,
  hasTables,
  isPresencialSolicitations,
  isAcceptSolicitations,
  setModalCheckinAtPageOpen,
  modalCheckinAtPageOpen,
  notificationsCheckin,
  setNotificationsCheckin,
}: CardProps) => {
  const [showModalInfoSolicitation, setShowModalInfoSolicitation] =
    useState(false);
  const { setTableIdAllocation, tableIdAllocation, setTableReallocation } =
    useTableManagement();
  const history = useHistory();

  const handleChangeTable = (id: string) => {
    if (hasTables && !isPresencialSolicitations) {
      setTableReallocation(true);
      if (id) {
        setTableIdAllocation(id);
        history.push('/solicitacoes/alocacao-de-mesa');
      }
    }
  };

  const handleAcceptSolicitation = (id: string) => {
    if (hasTables && !isPresencialSolicitations) {
      setTableIdAllocation(id);
      if (id) {
        history.push('/solicitacoes/alocacao-de-mesa');
      }
    } else {
      acceptModal();
      setSolicitationDataModal({
        idSolicitation: data.id,
        idEstablishment: data.establishment_id,
        idTable: data.table_id,
      });
    }
  };

  const handleRefuseSolicitation = () => {
    denyModal();
    setSolicitationDataModal({
      idSolicitation: data.id,
      idEstablishment: data.establishment_id,
    });
  };

  const handleCheckInSolicitation = () => {
    checkInModal();
    setSolicitationDataModal({
      idSolicitation: data.id,
      idEstablishment: data.establishment_id,
    });
  };

  const handleToCheckinModal = (
    date: string,
    tableName: any,
    user: any,
    id: string,
  ) => {
    setNotificationsCheckin([date, tableName, user, id]);
    setModalCheckinAtPageOpen(true);
  };

  const renderDate = () => {
    const date = new Date(data.date);

    if (hasTables) {
      const monthNumber = date.getMonth() + 1;
      const month =
        monthNumber.toString().length === 1 ? '0' + monthNumber : monthNumber;

      const day =
        date.getDate().toString().length === 1
          ? '0' + date.getDate()
          : date.getDate();

      const hours =
        date.getHours().toString().length === 1
          ? '0' + date.getHours()
          : date.getHours();

      const minutes =
        date.getMinutes().toString().length === 1
          ? '0' + date.getMinutes()
          : date.getMinutes();

      const dateFormated = day + '/' + month + '/' + date.getFullYear();

      return (
        <p>
          {!data.is_appointment
            ? `Solicitação para a mesa número: ${data.table?.name || 0}`
            : `Agendado para: ${dateFormated} às ${hours}:${minutes}`}
        </p>
      );
    }

    return (
      <p>
        Solicitou atendimento há:{' '}
        {formatDistance(new Date(date), new Date(), { locale: ptBR })}
      </p>
    );
  };

  const renderStatus = () => {
    if (data.status === 'pending') {
      return (
        <>
          <div>
            <img src={acceptIcon} alt="Ícone de aceitar" />
            <button onClick={() => handleAcceptSolicitation(data.id)}>
              Aceitar
            </button>
          </div>
          <div>
            <img src={denyIcon} alt="Ícone de recusar" />
            <button onClick={() => handleRefuseSolicitation()}>Recusar</button>
          </div>
        </>
      );
    } else if (data.status === 'accept') {
      if (!data.is_appointment) {
        return (
          <>
            <div>
              <img src={acceptIcon} alt="Ícone de aceitado" />
              <button style={{ cursor: 'auto' }}>Aceito</button>
            </div>
          </>
        );
      }
      if (data.checkin) {
        return (
          <>
            <div>
              <img src={acceptIcon} alt="Ícone de aceitado" />
              <button style={{ cursor: 'auto' }}>Aceito</button>
            </div>
          </>
        );
      }

      return (
        <>
          <div>
            <img src={acceptIcon} alt="Ícone de aceitado" />
            <button style={{ cursor: 'auto' }}>Aceito</button>
          </div>
          {hasTables && (
            <>
              {data.checkin_requested ? (
                <div className="table-content">
                  <TableInactive />
                  <button>Mesa</button>
                </div>
              ) : (
                <div
                  className="table-content active"
                  onClick={() => handleChangeTable(data.id)}
                >
                  <TableIcon />
                  <button>Mesa</button>
                </div>
              )}

              {data.checkin_requested ? (
                <div
                  className={`checkin-content ${
                    data.checkin_requested ? 'active' : ''
                  }`}
                >
                  <CheckinIcon />
                  <button
                    onClick={() =>
                      handleToCheckinModal(
                        data.date,
                        data.table?.name,
                        data.user,
                        data.id,
                      )
                    }
                  >
                    Check-in
                  </button>
                </div>
              ) : (
                <div
                  className={`checkin-content ${
                    data.checkin_requested ? 'active' : ''
                  }`}
                >
                  <CheckinIcon />
                  <button>Check-in</button>
                </div>
              )}
            </>
          )}
        </>
      );
    } else if (
      data.status === 'refuse' ||
      data.status === 'canceled' ||
      data.status === 'finished'
    ) {
      if (isPresencialSolicitations) {
        return (
          <>
            <div>
              <img src={denyIcon} alt="Ícone de recusado" />
              <button style={{ cursor: 'auto' }}>Recusado</button>
            </div>
          </>
        );
      }

      return (
        <div>
          <img src={denyIcon} alt="Ícone de recusado" />
          <button style={{ cursor: 'auto' }}>Recusado</button>
        </div>
      );
    } else if (data.status === 'finished') {
      if (isPresencialSolicitations) {
        return (
          <>
            <div>
              <img src={denyIcon} alt="Ícone de recusado" />
              <button style={{ cursor: 'auto' }}>Finalizado</button>
            </div>
          </>
        );
      }
    }
  };

  const renderModalInfoSolicitation = () => {
    if (showModalInfoSolicitation) {
      return (
        <ModalInfoSolicitation
          openModal={showModalInfoSolicitation}
          setOpenModal={setShowModalInfoSolicitation}
          reservationId={data.id}
          customerId={data.user_id}
        />
      );
    }
  };

  return (
    <Container key={data.id}>
      <Header onClick={() => setShowModalInfoSolicitation(true)}>
        <img src={data.user.avatar_url} alt="Foto do cliente" />
        <h4>{data.user.first_name + ' ' + data.user.last_name}</h4>
        {renderDate()}
        {hasTables && (
          <p>
            {data.is_appointment &&
              `Mesa para ${data.amount_of_people}  ${
                data.amount_of_people > 1 ? 'pessoas' : 'pessoa'
              }`}

            <p>
              {data.is_appointment
                ? `Mesa número: ${data.table?.name || 0} `
                : ` `}
            </p>
          </p>
        )}
      </Header>
      <Footer>
        <RateBox>
          <div>
            <p>Avaliação:</p>
            <div>
              <AiFillStar
                className={parseInt(data.user.rating) > 0 ? 'active' : ''}
              />
              <AiFillStar
                className={parseInt(data.user.rating) > 1 ? 'active' : ''}
              />
              <AiFillStar
                className={parseInt(data.user.rating) > 2 ? 'active' : ''}
              />
              <AiFillStar
                className={parseInt(data.user.rating) > 3 ? 'active' : ''}
              />
              <AiFillStar
                className={parseInt(data.user.rating) > 4 ? 'active' : ''}
              />
            </div>
          </div>
        </RateBox>
        <div>{renderStatus()}</div>
      </Footer>
      {renderModalInfoSolicitation()}
    </Container>
  );
};
