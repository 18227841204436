import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import {
  FetchOnlyItemProps,
  useFoodMenu,
  UploadNewImage,
} from '../../../../hooks/FoodMenu';

import { WrapperPage } from '../../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../../shared/components/Input/Input';
import { Button } from '../../../../shared/components/Button/Button';
import { Select } from './components/Select/Select';
import { LoadingOutlined } from '@ant-design/icons';
import { SuccessModal } from '../../../../shared/components/SuccessModal/SuccesModal';

import { NotificationsModal } from '../../../../shared/components/NotificationsModal/NotificationsModal';

import {
  Title,
  Row,
  FormContainer,
  Form,
  InputContainer,
  ListButtons,
  SelectContainer,
  Stock,
  Divider,
} from './_EditMenuItem';
import { Price } from '../MenuItems/components/Card/_Card';
import { Spinner } from 'shared/components/Spinner/Spinner';
import { moneyMask } from '../../../../utils/Masks';

export const EditMenuItem = () => {
  const [imgName, setImgName] = useState('');
  const [showSuccessItemEdited, setShowSuccessItemEdited] = useState(false);

  const { id, itemId } = useParams<{ id: string; itemId: string }>();

  const history = useHistory();

  const {
    getMenuOnlyItem,
    onlyItem,
    handleEditItem,
    handleChangeImage,
    getCategories,
    categories,
  } = useFoodMenu();

  const [formData, setFormData] = useState({} as FetchOnlyItemProps);
  const [loading, setLoading] = useState(false);
  const [modalCheckinOpen, setModalCheckinOpen] = useState(false);

  const [newImage, setNewImage] = useState({} as UploadNewImage);

  const handleItemNewValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMoney = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedData = moneyMask(e.target.value);

    setFormData({ ...formData, price: formattedData });
  };

  useEffect(() => {
    setFormData({
      ...onlyItem,
      price: onlyItem?.price?.replace(/\./g, ',')
    });
  }, [onlyItem]);

  const handleSentItemsInfo = async (
    idItem: string,
    formData: FetchOnlyItemProps,
    image: UploadNewImage,
  ) => {
    if (formData.time_in_minutes.toString().match(/\D/g)) {
      toast.error('O tempo de preparo deve ser somente em números.');
      return;
    }

    try {
      setLoading(true);

      const tryEditItems = await handleEditItem(idItem, formData);

      if (Object.keys(image).length !== 0) {
        const tryNewImg = await handleChangeImage(idItem, image);
      }

      setShowSuccessItemEdited(true);
      setTimeout(function () {
        history.push(`/cardapio/${onlyItem.category_id}/itens`);
      }, 1000);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMenuOnlyItem(itemId);
    getCategories();
  }, [itemId]);

  const handleOnChangeCategory = (value: string) => {
    setFormData({ ...formData, category_id: value });
  };

  const goToMenus = () => {
    history.push(`/cardapios`);
  };

  const goToNewMenus = () => {
    history.push(`/cardapio/cadastro`);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileList = e.target.files;
    if (fileList) {
      let formData = new FormData();
      setNewImage({ image: fileList[0] });
      setImgName(fileList[0].name);
    }
  };

  const renderImgName = () => {
    if (imgName) {
      return imgName;
    }

    return 'Clique aqui';
  };

  return (
    <WrapperPage title="Editar">
      <Title>Editar Cardápio</Title>
      <Row>
        <FormContainer>
          <Form>
            <p>Categoria do Cardápio</p>
            <label>Escolha qual é a categoria do seu Cardápio:</label>
            <SelectContainer>
              <Select
                placeholder="Selecionar categoria"
                data={categories}
                onChange={(value) => handleOnChangeCategory(value)}
              />
            </SelectContainer>

            <p>Nome do item</p>
            <label>Nome:</label>
            <InputContainer>
              <Input
                name="name"
                value={formData.name}
                onChange={(e) => handleItemNewValue(e)}
                placeholder="Jacket Potato"
              />
            </InputContainer>

            <p>Descrição</p>
            <label>Inserir a descrição:</label>
            <InputContainer>
              <Input
                name="description"
                value={formData.description}
                onChange={(e) => handleItemNewValue(e)}
                placeholder="Inserir descrição..."
              />
            </InputContainer>

            <p>Valor</p>
            <label>Insira o valor:</label>
            <InputContainer>
              <Input
                name="price"
                value={formData.price && moneyMask(formData.price)}
                onChange={(e) => handleMoney(e)}
                placeholder="R$ 24,90"
              />
            </InputContainer>

            <p>Tempo de Preparo</p>
            <label>Insira o tempo de preparo em minutos</label>
            <InputContainer>
              <Input
                name="time_in_minutes"
                value={formData.time_in_minutes}
                onChange={(e) => handleItemNewValue(e)}
                placeholder="Ex: 1 hora = 60 ou 10 minutos = 10"
              />
            </InputContainer>

            <p>Editar imagem</p>
            <label>Adicione a imagem:</label>
            <Button type="button">
              <label htmlFor="avatar">
                <input
                  id="avatar"
                  accept="image/*"
                  type="file"
                  onChange={(e) => handleImageChange(e)}
                />
                <span>{renderImgName()}</span>
              </label>
            </Button>
          </Form>
        </FormContainer>
        <Stock>
          <div>Estoque</div>
          <div>Insira a quantidade de unidades disponíveis</div>
          <Input
            name="available_quantity"
            value={formData.available_quantity}
            onChange={(e) => handleItemNewValue(e)}
            placeholder="27 unidades"
          />
          <div>Código PDV</div>
          <div>Insira o código PDV</div>
          <Input
            name="pdv"
            value={formData.pdv}
            onChange={(e) => handleItemNewValue(e)}
            placeholder="PDV..."
          />
        </Stock>
        <ListButtons>
          <Button onClick={() => goToNewMenus()}>+ Novo Cardápio</Button>
          <Button onClick={() => goToMenus()}>Cardápios</Button>
          <Divider />
          <Button
            onClick={() => handleSentItemsInfo(itemId, formData, newImage)}
          >
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Editar item'
            )}
          </Button>
        </ListButtons>
      </Row>
      <SuccessModal
        openModal={showSuccessItemEdited}
        setOpenModal={setShowSuccessItemEdited}
        text="Item editado com sucesso!"
      />
      <NotificationsModal />
    </WrapperPage>
  );
};
