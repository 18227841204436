import { useTableManagement } from '../../../../../hooks/TableManagement';
import { useAuth } from '../../../../../hooks/Auth';
import { useSocketIo } from '../../../../../hooks/Socket';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { SuccessModal } from '../../../../../shared/components/SuccessModal/SuccesModal';
import { Button } from '../../../../../shared/components/Button/Button';
import { useHistory } from 'react-router-dom';
import success from '../../../../../shared/icons/success.png';
import { WrapperPage } from '../../../../../shared/components/WrapperPage/WrapperPage';
import {
  Content,
  ContentInfos,
  ContentPreview,
  Subtitle,
  Title,
  LocationItem,
  ItemStatusIcon,
  Icon,
  TableItem,
  TablePreviewContent,
} from './_TableAllocation';

import './modal-to-responsive.css';

import api from '../../../../../services/api';
import { toast } from 'react-toastify';
import { Modal } from 'antd';

interface AllocationProps {
  name: string;
  id: string;
  amountChairs: string;
  isAvailable: boolean;
}

interface NewInfosProps {
  name: string;
  amountChairs: string;
  isAvailable: boolean;
}

const TableAllocation = () => {
  const {
    getEstablishmentTables,
    establishmentTables,
    setTableIdAllocation,
    tableIdAllocation,
    setTableReallocation,
    tableReallocation,
    updateTableInfos,
  } = useTableManagement();

  const { setRecallSolicitationList, handleRefuseReservation } = useSocketIo();

  const history = useHistory();

  const [seatAllocation, setSeatAllocation] = useState<AllocationProps>(
    {} as AllocationProps,
  );
  const [newTableInfos, setNewTableInfos] = useState<NewInfosProps>(
    {} as NewInfosProps,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = useAuth();

  function handleSelectedTable(
    tableId: string,
    tableChairs: number,
    tableName: string,
    tableAvailability: boolean,
  ) {
    setSeatAllocation({
      name: tableName,
      amountChairs: String(tableChairs),
      id: tableId,
      isAvailable: tableAvailability,
    });
    setNewTableInfos({
      name: tableName,
      amountChairs: String(tableChairs),
      isAvailable: !tableAvailability,
    });
  }

  const handleSolicitation = async () => {
    setLoading(true);

    if (tableReallocation) {
      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };

        await api.put(
          `/reservations/${tableIdAllocation}/tables/${seatAllocation.id}`,
          {},
          { headers },
        );

        getEstablishmentTables();
        setLoading(false);
        setModalOpen(true);
        setTableReallocation(false);
        setRecallSolicitationList(true);
        handleRefuseReservation();
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const headers = { Authorization: `Bearer ${data.accessToken}` };

        await api.post(
          `/reservations/${tableIdAllocation}/accept`,
          {
            establishmentId: data.user.establishment?.id,
            tableId: seatAllocation.id,
          },
          { headers },
        );

        getEstablishmentTables();
        setLoading(false);
        setModalOpen(true);
        setRecallSolicitationList(true);
        handleRefuseReservation();
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
      } finally {
        setLoading(false);
      }
    }
  };

  function handleCloseModal() {
    setModalOpen(false);
    history.push('/solicitacoes');
  }

  useEffect(() => {
    getEstablishmentTables();
  }, []);

  const renderItems = () => {
    const itens = establishmentTables
      .sort(function (a, b) {
        var textA = Number(a.name);
        var textB = Number(b.name);
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((item, index) => (
        <TableItem
          className={
            seatAllocation.id === item.id
              ? 'is-selected'
              : '' && !item.is_available
              ? 'not-avaliable'
              : ''
          }
          id={!item.is_available ? 'not-avaliable' : ''}
          onClick={() =>
            handleSelectedTable(
              item.id,
              item.amount_chairs,
              item.name,
              item.is_available,
            )
          }
        >
          <span>{item.name}</span>
          <ItemStatusIcon />
        </TableItem>
      ));

    return (
      <TablePreviewContent index={itens.length}>
        <div>{itens}</div>
      </TablePreviewContent>
    );
  };

  return (
    <WrapperPage title="Selecione a mesa desejada">
      <Content>
        <ContentInfos>
          {seatAllocation.name ? (
            <Title>Você escolheu a mesa: {seatAllocation.name}</Title>
          ) : (
            <Title>Você ainda não escolheu a mesa</Title>
          )}
          <Subtitle>Quantidade de lugares:</Subtitle>
          <LocationItem>
            {seatAllocation.name ? (
              <h1>{seatAllocation.amountChairs}</h1>
            ) : (
              <h1>00</h1>
            )}

            <p>Lugares</p>
          </LocationItem>
          <Button
            disabled={Object.keys(seatAllocation).length ? false : true}
            onClick={() => handleSolicitation()}
          >
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              ' Selecionar mesa'
            )}
          </Button>
        </ContentInfos>
        <ContentPreview>{renderItems()}</ContentPreview>
        <Modal
          visible={modalOpen}
          onCancel={() => handleCloseModal()}
          footer={null}
          style={{ fontSize: 26 }}
          bodyStyle={{ fontSize: 20, color: '#21E270' }}
          wrapClassName="mestres-modal"
        >
          <p>Mesa selecionada com sucesso!</p>

          <Icon src={success} />
        </Modal>
      </Content>
    </WrapperPage>
  );
};

export default TableAllocation;
