import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

interface StatusEstablishmentProps {
  statusEstablishment: boolean;
}

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin-top: 40px;

  @media (max-width: 440px) {
   text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 450px){
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 40px;

  @media (max-width: 1024px){
    margin: 20px auto;
  }

  @media (max-width: 430px){
    padding: 20px;
    width: 100%;
  }
`;

export const RowInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: var(--descriptionText);
    margin-top: 10px;
    display: block;
  }

  > div:nth-of-type(1) {
    width: 120px;
  }

  > div:nth-of-type(2) {
    width: 150px;
  }

  @media (max-width: 1024px) {
   flex-direction: column;
   width: 100%;

   > div:nth-of-type(1) {
      width: 100%;
    }

    > div:nth-of-type(2) {
      width: 100%;
    }
  }
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const SelectContainer = styled.div`
  margin-top: 20px;

  > div > div {
    width: 224px !important;
  }

  @media (max-width: 1024px){
    > div > div {
      width: 100% !important;
    }
  }
`;

export const Form = styled.form`
  > p {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.6rem;
    color: var(--greenText);
  }

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: var(--descriptionText);
    margin-top: 10px;
    display: block;
  }

  > button {
    background-color: var(--greenText);

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      cursor: pointer;
      > input {
        display: none;
      }
      > span {
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 1rem;
        color: #fff;
        overflow-wrap: anywhere;
      }
    }

  }

  @media (max-width: 440px) {
   > select {
     margin: 0;
   }

   > button {
     width: 100%;
   }
  }
`;

export const ButtonGreen = styled(Button)`
  background-color: var(--greenText);
  color: #fff;
`;

export const Divider = styled.div`
  margin: 120px;

  @media (max-width: 1140px) {
   margin: 0px;
  }
`;

export const ListButtons = styled.div`
  > button {
    width: 260px;
  }

  @media (max-width: 1140px){
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > button {
      margin: 20px;
    }
  }

  @media (max-width: 330px){
    width: 100%;
  }
`;

export const RequestMachine = styled.div`
  width: 260px;
  padding: 20px;
  background-color: #EFEFF4;
  height: fit-content;
  border-radius: 30px;
  text-align: center;

  > div:nth-of-type(1) {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.2rem;
    color: #8E8E93;
  }

  > p {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8E8E93;
    margin: 10px 0px;
  }

  > button {
    background-color: var(--greenText);
    color: #fff;
    width: 100%;
  }

  @media (max-width: 1024px) {
    margin: 20px auto;
  }

  @media (max-width: 330px){
    width: 100%;
  }
`;


