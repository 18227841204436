import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import AppProvider from './hooks/index';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <Routes />
      <GlobalStyle />
      <ToastContainer autoClose={3000} />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
