import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/Auth';
import { useCreateAccount } from '../../../hooks/CreateAccount';
import { useFinalizeRegistration } from '../../../hooks/FinalizeRegistration';
import { cnpjMask, removeCnpjMask } from '../../../utils/Masks';
import { cnpjValidation } from '../../../utils/Validations';

import { WrapperPage } from '../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../shared/components/Input/Input';
import { Button } from '../../../shared/components/Button/Button';
import { Select } from './components/Select/Select';
import { SendFileModal } from '../../../shared/components/SendFileModal/SendFileModal';

import { AuthenticationModal } from './components/AuthenticationModal/AuthenticationModal';
import { SuccessModal } from '../../../shared/components/SuccessModal/SuccesModal';

import step1 from '../../../shared/icons/step1.png';
import fileIcon from '../../../shared/icons/file-icon.png';

import {
  Title,
  Row,
  FormContainer,
  Form,
  InputContainer,
  Next,
  Step,
  DocumentsContainer,
} from './_EstablishmentData';

export const EstablishmentData = () => {
  const { data, setData } = useAuth();
  const { verifyCodePhoneNumber } = useCreateAccount();
  const {
    establishmentData,
    setEstablishmentData,
    cnpjOrMeiDocument,
    setCnpjOrMeiDocument,
    partnerDocument,
    setPartnerDocument,
    proofOfActivity,
    setProofOfActivity,
  } = useFinalizeRegistration();
  const history = useHistory();

  const [typeDocument, setTypeDocument] = useState('');
  const [showSendFileModal, setShowSendFileModal] = useState(false);
  const [showAuthenticationModal, setShowAuthenticationModal] = useState(
    !data.user.verified_phone,
  );
  const [showSuccesModal, setShowSuccessModal] = useState(false);
  const [code, setCode] = useState('');
  const [loadingPhoneCode, setLoadingPhoneCode] = useState(false);
  const [allowResendCode, setAllowResendCode] = useState(false);
  const [codesMCC, setCodesMCC] = useState([]);

  useEffect(() => {
    fetchMCC();
  }, []);

  const fetchMCC = () => {
    api
      .get('/establishments/mcc')
      .then((response) => setCodesMCC(response.data))
      .catch((error) =>
        toast.error(error.response?.data?.message || error.toString()),
      );
  };

  const identificationOptions = [
    { id: '1', code: '1', description: 'RG e CPF' },
    { id: '2', code: '2', description: 'CNH' },
  ];

  const handleDocument = async (type: string) => {
    setTypeDocument(type);
    setShowSendFileModal(true);
  };

  const choosenFile = () => {
    if (typeDocument === 'partnerDocument') {
      return setPartnerDocument;
    } else if (typeDocument === 'cnpjOrMeiDocument') {
      return setCnpjOrMeiDocument;
    } else {
      return setProofOfActivity;
    }
  };

  const actionAfterConfirmationNumber = async () => {
    if (code.trim().length < 6) {
      toast.error('Digite o código de verificação corretamente');
      return;
    }

    try {
      setLoadingPhoneCode(true);
      setAllowResendCode(true);
      await verifyCodePhoneNumber(code);
      setLoadingPhoneCode(false);
      setShowAuthenticationModal(false);
      setShowSuccessModal(true);
      setData({ ...data, user: { ...data.user, verified_phone: true } });
    } catch (error) {
      setLoadingPhoneCode(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const validateFormFields = () => {
    if (!partnerDocument?.name) {
      toast.error(
        'Anexe o documento de identificação de um dos sócios corretamente',
      );
      return false;
    }

    if (!establishmentData.companyName.trim()) {
      toast.error('Preencha a razão social corretamente');
      return false;
    }

    if (!establishmentData.statementDescriptor.trim()) {
      toast.error('Preencha o nome do estabelecimento corretamente');
      return false;
    }

    if (
      !establishmentData.cnpj.trim() ||
      !cnpjValidation(establishmentData.cnpj)
    ) {
      toast.error('Preencha o CNPJ corretamente');
      return false;
    }

    if (!establishmentData.mcc.trim()) {
      toast.error('Insira seu código MCC corretamente');
      return false;
    }

    if (!cnpjOrMeiDocument?.name) {
      toast.error('Anexe a cópia do cartão do CNPJ ou MEI corretamente');
      return false;
    }

    if (!proofOfActivity?.name) {
      toast.error('Anexe o comprovante de atividades corretamente');
      return false;
    }

    return true;
  };

  const handleNextStep = () => {
    if (validateFormFields()) {
      history.push('/finalizar-cadastro/dados-do-endereco');
    }
  };

  const renderPartnerDocumentName = () => {
    if (partnerDocument?.name) {
      return partnerDocument.name;
    }

    return 'Anexar Documento';
  };

  const renderCnpjOrMeiDocumentName = () => {
    if (cnpjOrMeiDocument?.name) {
      return cnpjOrMeiDocument.name;
    }

    return 'Anexar Documento';
  };

  const renderProofOfActivityName = () => {
    if (proofOfActivity?.name) {
      return proofOfActivity.name;
    }

    return 'Anexar Documento';
  };

  return (
    <WrapperPage title="Cadastro" showMenu={false}>
      <Title>Finalizar Cadastro de Dados</Title>
      <Row>
        <FormContainer>
          <Form>
            <p>Documentação</p>
            <label>Documento de Identificação de um dos Sócios:</label>
            <Select
              defaultValue={identificationOptions[0].code}
              data={identificationOptions}
            />
            <Button
              iconLeft={fileIcon}
              type="button"
              onClick={() => handleDocument('partnerDocument')}
            >
              {renderPartnerDocumentName()}
            </Button>

            <p>Estabelecimento</p>
            <label>Informe os dados do Estabelecimento: </label>
            <InputContainer>
              <Input
                placeholder="Razão Social"
                value={establishmentData.companyName}
                onChange={(event) =>
                  setEstablishmentData({
                    ...establishmentData,
                    companyName: event.target.value,
                  })
                }
              />
            </InputContainer>
            <InputContainer>
              <Input
                placeholder="Nome do estabelecimento"
                value={establishmentData.statementDescriptor}
                onChange={(event) =>
                  setEstablishmentData({
                    ...establishmentData,
                    statementDescriptor: event.target.value,
                  })
                }
              />
            </InputContainer>
            <InputContainer>
              <Input
                placeholder="CNPJ do Estabelecimento"
                value={cnpjMask(establishmentData.cnpj)}
                onChange={(event) =>
                  setEstablishmentData({
                    ...establishmentData,
                    cnpj: removeCnpjMask(event.target.value),
                  })
                }
              />
            </InputContainer>
            <InputContainer>
              <Select
                showCodeIntoOption
                placeholder={data.user.establishment?.id}
                data={codesMCC}
                onChange={(value) =>
                  setEstablishmentData({ ...establishmentData, mcc: value })
                }
              />
            </InputContainer>
            <DocumentsContainer>
              <label>Cópia do Cartão do CNPJ ou MEI:</label>
              <Button
                iconLeft={fileIcon}
                type="button"
                onClick={() => handleDocument('cnpjOrMeiDocument')}
              >
                {renderCnpjOrMeiDocumentName()}
              </Button>
              <label>Comprovante de Atividades:</label>
              <Button
                iconLeft={fileIcon}
                type="button"
                onClick={() => handleDocument('proofOfActivity')}
              >
                {renderProofOfActivityName()}
              </Button>
            </DocumentsContainer>
            <Next onClick={() => handleNextStep()}>
              Próximo <FaChevronRight />
            </Next>
          </Form>
        </FormContainer>
        <Step>
          <img src={step1} alt="Etapa de cadastrar dados do estabelecimento" />
          <Button
            style={{ backgroundColor: '#94FFC1', color: '#fff' }}
            disabled
          >
            Finalizar Cadastro
          </Button>
        </Step>
      </Row>
      <SendFileModal
        openModal={showSendFileModal}
        setOpenModal={setShowSendFileModal}
        setFile={choosenFile()}
      />
      <AuthenticationModal
        openModal={showAuthenticationModal}
        setOpenModal={setShowAuthenticationModal}
        actionAfterConfirmationNumber={actionAfterConfirmationNumber}
        setCode={setCode}
        loadingPhoneCode={loadingPhoneCode}
        allowResendCode={allowResendCode}
      />
      <SuccessModal
        openModal={showSuccesModal}
        setOpenModal={setShowSuccessModal}
        text="Telefone Celular autenticado com sucesso!"
      />
    </WrapperPage>
  );
};
