import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  background-color: var(--greenText);
  min-height: 100vh;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (max-width: 1120px){
    width: 100%;
    padding: 20px 0px;
  }
`;

export const Form = styled.form`
  width: fit-content;
  text-align: center;

  > img {
    display: block;
    margin: 0 auto;
  }

  > h1 {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 2rem;
    color: #fff;
    margin-top: 40px;
  }

  > button {
    width: 100%;
  }

  @media (max-width: 400px){
    width: 90%;

    > h1 {
      text-align: center;
    }
  }
`;

export const Description = styled.p`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.2rem;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: #fff;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;

  > img {
    display: block;
    margin: 0 auto;
    margin-top: 50vh;
    transform: translateY(-50%);
    width: 560px;
  }

  @media (max-width: 1120px){
    display: none;
  }
`;

export const Next = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.4rem;
  color: #94FFC1;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;

  > svg {
    margin-left: 20px;
  }
`;
