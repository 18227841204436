import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin: 40px 0px;

  > span {
    font-size: 2.2rem;
    color: var(--greenText);
    margin-right: 10px;
    cursor: pointer;
  }

  @media (max-width: 440px) {
   text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ListItens = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 1126px) {
    justify-content: center;
  }

  @media (max-width: 460px) {
    margin: 0px;
  }
`;
