import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

import api from '../../../services/api';

import { useFinalizeRegistration } from '../../../hooks/FinalizeRegistration';
import { useAuth } from '../../../hooks/Auth';
import { bankAgencyMask, onlyNumberMask } from '../../../utils/Masks';
import { FaChevronRight } from 'react-icons/fa';

import { WrapperPage } from '../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../shared/components/Input/Input';
import { Button } from '../../../shared/components/Button/Button';
import { Select } from './components/Select/Select';
import { FinishRegistrationModal } from './components/FinishRegistrationModal/FinishRegistrationModal';

import step3 from '../../../shared/icons/step3.png';
import stepFinish from '../../../shared/icons/step-finish.png';

import {
  Title,
  Row,
  FormContainer,
  Form,
  RowInput,
  InputContainer,
  Next,
  Step,
  SelectContainer,
} from './_BankDetails';

export const BankDetails = () => {
  const { data } = useAuth();
  const {
    saveTokenZOOP,
    establishmentData,
  } = useFinalizeRegistration();
  const [bankData, setBankData] = useState({
    holder_name: '',
    bank_code: '',
    routing_number: '',
    account_number: '',
    establishment_id: data.user.establishment?.id,
    type: '',
  });
  const [finishRegistration, setFinishRegistration] = useState(false);
  const [
    showFinishRegistrationModal,
    setShowFinishRegistrationModal,
  ] = useState(false);
  const [banksCode, setBanksCode] = useState([]);
  const [typeAccount, setTypeAccount] = useState([
    { id: '1', code: 'checking', name: 'Conta corrente' },
    { id: '2', code: 'savings', name: 'Conta poupança' },
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBanksCode();
  }, []);

  const fetchBanksCode = () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    api
      .get('/bank-codes', { headers })
      .then((response) => setBanksCode(response.data))
      .catch((error) =>
        toast.error(error.response?.data?.message || error.toString()),
      );
  };

  const handleNextStep = async () => {
    if (!validateFormFields()) {
      return;
    }

    setLoading(true);
    try {

      await saveTokenZOOP(bankData);

      setFinishRegistration(true);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
    setLoading(false);
  };

  const validateFormFields = () => {
    if (!bankData.holder_name.trim()) {
      toast.error('Preencha o nome do portador corretamente');
      return false;
    }

    if (!bankData.bank_code.trim()) {
      toast.error('Escolha o banco corretamente');
      return false;
    }

    if (!bankData.routing_number.trim()) {
      toast.error(
        'Preencha sua agência bancária sem o dígito verificador corretamente',
      );
      return false;
    }

    if (!bankData.type.trim()) {
      toast.error('Escolha o tipo da sua conta corretamente');
      return false;
    }

    if (!bankData.account_number.trim()) {
      toast.error(
        'Preencha sua conta bancária com o dígito verificador corretamente',
      );
      return false;
    }

    return true;
  };

  const handleFinishRegistration = () => {
    if (finishRegistration) {
      setShowFinishRegistrationModal(true);
    }
  };

  const renderImgStep = () => {
    if (finishRegistration) {
      return <img src={stepFinish} alt="Finalizar cadastro" />;
    }

    return (
      <img src={step3} alt="Etapa de cadastrar dados do estabelecimento" />
    );
  };

  return (
    <WrapperPage title="Cadastro" showMenu={false}>
      <Title>Finalizar Cadastro de Dados</Title>
      <Row>
        <FormContainer>
          <Form>
            <p>Dados Bancários</p>
            <label>Informe os dados bancários do Estabelecimento:</label>
            <InputContainer>
              <Input
                placeholder="Nome do portador.."
                value={bankData.holder_name}
                onChange={(event) =>
                  setBankData({
                    ...bankData,
                    holder_name: event.target.value,
                  })
                }
              />
            </InputContainer>
            <SelectContainer>
              <Select
                showCodeIntoOption
                onChange={(value) =>
                  setBankData({ ...bankData, bank_code: value.split('&')[0] })
                }
                placeholder="Banco..."
                data={banksCode}
              />
            </SelectContainer>
            <InputContainer>
              <Input
                placeholder="Agência.."
                value={bankData.routing_number}
                onChange={(event) =>
                  setBankData({
                    ...bankData,
                    routing_number: bankAgencyMask(event.target.value),
                  })
                }
              />
            </InputContainer>
            <SelectContainer>
              <Select
                placeholder="Tipo de conta"
                onChange={(value) => setBankData({ ...bankData, type: value })}
                data={typeAccount}
              />
            </SelectContainer>
            <InputContainer>
              <Input
                placeholder="Conta"
                value={bankData.account_number}
                onChange={(event) =>
                  setBankData({
                    ...bankData,
                    account_number: onlyNumberMask(event.target.value),
                  })
                }
              />
            </InputContainer>
            <Next onClick={() => handleNextStep()}>
              {loading ? (
                <LoadingOutlined
                  style={{ fontSize: 24, margin: '0px auto' }}
                  spin
                />
              ) : (
                <>
                  Próximo <FaChevronRight />
                </>
              )}
            </Next>
          </Form>
        </FormContainer>
        <Step>
          {renderImgStep()}
          <Button
            onClick={() => handleFinishRegistration()}
            style={{
              backgroundColor: finishRegistration
                ? 'var(--greenText)'
                : '#94FFC1',
              color: '#fff',
            }}
          >
            Finalizar Cadastro
          </Button>
        </Step>
      </Row>
      <FinishRegistrationModal
        openModal={showFinishRegistrationModal}
        setOpenModal={setShowFinishRegistrationModal}
      />
    </WrapperPage>
  );
};
