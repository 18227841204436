import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.6rem;
  color: var(--greenText);
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.img`
  height: 40px;
  cursor: pointer;
`;

export const Description = styled.div`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1.2rem;
  color: var(--descriptionText);
  max-height: 340px;
  overflow: auto;
  padding-right: 20px;
  white-space: break-spaces;

  @media (max-height: 520px) {
    max-height: 300px;
  }
`;
