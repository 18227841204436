import styled from 'styled-components';

export const ContentModal = styled.div`
  button {
    color: #fff;
    background: #21e270;

    &:disabled {
      background: #8e8e93;
    }
  }
`;

export const ModalPassTitle = styled.h1`
  color: #21e270;
  font-size: 1.25rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const ModalPassSubtitle = styled.p`
  color: #8e8e93;
  font-size: 0.9375rem;
  font-family: 'FontsFreeNetSFProTextLight';
`;
