import styled from 'styled-components';
import { Button } from '../../shared/components/Button/Button';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin-top: 40px;

  @media (max-width: 440px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 20px 40px;

  @media (max-width: 1140px) {
    margin: 20px auto;
  }

  @media (max-width: 430px) {
    padding: 20px;
    width: 100%;
  }
`;

export const RowInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:nth-of-type(1) {
    > div > div {
      width: 120px;
    }
  }

  > div:nth-of-type(2) {
    > div {
      width: 180px;
    }
  }

  @media (max-width: 440px) {
    flex-direction: column;

    > div:nth-of-type(1) {
      width: 100%;
      > div > div {
        width: 100%;
      }
    }

    > div:nth-of-type(2) {
      width: 100%;
      > div {
        width: 100%;
      }
    }

    > div:nth-of-type(2) {
      margin-top: -20px;
    }
  }
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const SelectContainer = styled.div`
  margin-top: 20px;

  > div > div {
    width: 60% !important;
  }
`;

export const Form = styled.form`
  > p {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.6rem;
    color: var(--greenText);
  }

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    margin-top: 10px;
    display: block;
  }

  > button {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: #fff;
    overflow-wrap: anywhere;
    overflow: hidden;
    background-color: var(--greenText);
    color: #fff;
    width: 70%;
    height: 40px;

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      cursor: pointer;
      > input {
        display: none;
      }
      > span {
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 1rem;
        color: #fff;
        overflow-wrap: anywhere;
      }
    }
  }

  @media (max-width: 440px) {
    > select {
      margin: 0;
    }

    > button {
      width: 100%;
    }
  }
`;

export const Next = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.4rem;
  color: #4cd964;
  text-align: right;
  margin-top: 40px;
  cursor: pointer;
`;

export const ListButtons = styled.div`
  > button {
    width: 260px;
  }

  /* > button:nth-of-type(1) {
    background-color: var(--greenText);
    color: #fff;
  }

  > button:nth-of-type(3) {
    background-color: var(--greenText);
    color: #fff;
  }

  > button:nth-of-type(4) {
    background-color: #C7C7CC;
    color: #fff;
  } */

  @media (max-width: 1140px) {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > button {
      margin: 20px;
    }
  }

  @media (max-width: 330px) {
    width: 100%;
  }
`;

export const ButtonGreen = styled(Button)`
  background-color: var(--greenText);
  color: #fff;
`;

export const Divider = styled.div`
  margin: 120px;

  @media (max-width: 1140px) {
    margin: 0px;
  }
`;

export const RowIcons = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;

  > img {
    width: auto;
    height: 16px;
    cursor: pointer;
  }

  > label {
    display: block;
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const ContainerRowIcons = styled.div`
  > div:nth-of-type(1) > label {
    margin-left: 16px;
  }
`;
