import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  TableItem,
  TablePreviewContent,
  SelectDelete,
  ItemStatusIcon,
} from './_TablesPreview';
import { ReactComponent as TableEditIcons } from '../../../../shared/icons/edit-table-details.svg';
import ChangeTableInfosModal from '../ChangeTableInfosModal/ChangeTableInfosModal';
import { useTableManagement } from '../../../../hooks/TableManagement';

interface TablePreviewProps {
  tableNumber: number;
  setSelectedToDelete: Dispatch<SetStateAction<string[]>>;
  selectedToDelete: string[];
}

const TablesPreview = ({
  tableNumber,
  selectedToDelete,
  setSelectedToDelete,
}: TablePreviewProps) => {
  const { getEstablishmentTables, establishmentTables } = useTableManagement();

  const [tableModalInfos, setTableModalInfos] = useState(false);
  const [nameOfTable, setNameOfTable] = useState('');
  const [isTableAvaliable, setIsTableAvaliable] = useState(true);
  const [amountOfChair, setAmountOfChair] = useState(0);
  const [tableId, setTableId] = useState('');

  function handleChangeTableInfos(
    e: React.MouseEvent,
    tableName: string,
    isactive: boolean,
    amoutChair: number,
    tableID: string,
  ) {
    if (e.target == e.currentTarget) {
      setNameOfTable(tableName);
      setIsTableAvaliable(isactive);
      setAmountOfChair(amoutChair);
      setTableId(tableID);
      setTableModalInfos(true);
    }
  }

  useEffect(() => {
    getEstablishmentTables();
  }, []);

  const removeTableArray = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: string,
  ) => {
    const { checked } = e.currentTarget;

    if (checked) {
      setSelectedToDelete([...selectedToDelete, itemId]);
    } else {
      const removeTable = selectedToDelete.filter((table) => table !== itemId);
      setSelectedToDelete([...removeTable]);
    }
  };

  const renderItems = () => {
    const itens = establishmentTables
      .sort(function (a, b) {
        var textA = Number(a.name);
        var textB = Number(b.name);
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((item, index) => (
        <TableItem
          className={item.is_available ? '' : 'is-inactive'}
          onClick={(e) =>
            handleChangeTableInfos(
              e,
              item.name,
              item.is_available,
              item.amount_chairs,
              item.id,
            )
          }
        >
          <div>
            <SelectDelete>
              <input
                onChange={(e) => removeTableArray(e, item.id)}
                type="checkbox"
              />
              <span className="checkmark"></span>
            </SelectDelete>
            <span>{item.name}</span>
            <span>{item.is_available}</span>
          </div>
          <ItemStatusIcon
            onClick={(e) =>
              handleChangeTableInfos(
                e,
                item.name,
                item.is_available,
                item.amount_chairs,
                item.id,
              )
            }
          />
        </TableItem>
      ));

    return (
      <TablePreviewContent index={itens.length}>
        <div>{itens}</div>
      </TablePreviewContent>
    );
  };

  return (
    <div>
      {renderItems()}
      {tableModalInfos && (
        <ChangeTableInfosModal
          setTableModalInfos={setTableModalInfos}
          tableModalInfos={tableModalInfos}
          nameOfTable={nameOfTable}
          isTableAvaliable={isTableAvaliable}
          setIsTableAvaliable={setIsTableAvaliable}
          setAmountOfChair={setAmountOfChair}
          amountOfChair={amountOfChair}
          tableId={tableId}
        />
      )}
    </div>
  );
};

export default TablesPreview;
