import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  background-color: var(--greenText);
  min-height: 100vh;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (max-width: 1120px){
    width: 100%;
    padding: 20px 0px;
  }
`;

export const Form = styled.form`
  width: fit-content;

  > img {
    display: block;
    margin: 0 auto;
  }

  > h1 {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 2rem;
    color: #fff;
    margin-top: 40px;
  }

  > button {
    width: 100%;
  }

  @media (max-width: 400px){
    width: 90%;

    > h1 {
      text-align: center;
    }
  }
`;

export const AccessYourAccount = styled.div`
  color: #fff;
  margin-top: 40px;
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1.2rem;
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const SaveLogin = styled.span`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.2rem;
  color: #fff;

  > input {
    margin-right: 10px;
  }
`;

export const ForgotPassword = styled.span`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;

  @media (max-width: 342px) {
   margin-top: 10px;
  }
`;

export const SignUp = styled.div`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1.2rem;
  color: #fff;

  > span {
    font-family: 'FontsFreeNetSFProTextMedium';
    color: #94FFC1;
    cursor: pointer;
  }

  @media (max-width: 400px){
    text-align: center;
  }

  @media (max-width: 342px){
    > span {
      display: block;
      margin-top: 20px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: #fff;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;

  > img {
    display: block;
    margin: 0 auto;
    margin-top: 50vh;
    transform: translateY(-50%);
    width: 560px;
  }

  @media (max-width: 1120px){
    display: none;
  }
`;


export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 342px) {
    flex-direction: column;
    align-items: center;
  }
`;
