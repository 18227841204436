import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 330px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 40px;
  padding: 0px 20px;
  margin: 20px 0px;

  @media (max-width: 380px) {
   width: 100%;
  }
`;

export const InputField = styled.input`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1rem;
  width: 100%;
  border: 0;
  outline: transparent;

  &::placeholder{
    color: #8E8E93;
  }
`;

export const IconLeft = styled.img`
  margin-right: 20px;
`;

export const IconRight = styled.img`
  margin-left: 20px;
`;
