import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';

import api from '../services/api';

interface AuthState {
  accessToken: string;
  refreshToken?: string;
  user: UserProps;
  saveLogin: boolean;
}

interface UserProps {
  verified_phone: boolean;
  first_name: string;
  last_name: string;
  establishment?: EstablichmentProps;
  email?: string;
}

interface EstablichmentProps {
  id?: string;
  document?: EstablichmentDocumentProps;
  cnpj?: string;
  tutorial_done?: boolean;
  company_name?: string;
  mcc?: string;
  total_capacity?: number;
  statement_descriptor?: string;
  automate_orders?: boolean;
  accept_reservations?: boolean;
  enabled_seller?: boolean;
  category_id?: string;
  tip_enabled?: boolean;
}

interface EstablichmentDocumentProps {
  sent_cnpj?: boolean;
  sent_id_card?: boolean;
  sent_proof_of_address?: boolean;
  sent_proof_of_activity?: boolean;
  registered_bank_account?: boolean;
}

interface SignInCredentials {
  email: string;
  password: string;
  saveLogin: boolean;
}

interface AuthContextData {
  data: AuthState;
  setData: Dispatch<SetStateAction<AuthState>>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  refreshToken(): void;
  checkIfUserHasEstablishment(): Promise<boolean>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const saveLogin = !!localStorage.getItem('@Payment:accessToken') || false;

    let accessToken;
    let refreshToken;
    let user;
    if (saveLogin) {
      accessToken = localStorage.getItem('@Payment:accessToken') || '';
      refreshToken = localStorage.getItem('@Payment:refreshToken') || '';
      user = localStorage.getItem('@Payment:user') || '{}';
    } else {
      accessToken = sessionStorage.getItem('@Payment:accessToken') || '';
      refreshToken = sessionStorage.getItem('@Payment:refreshToken') || '';
      user = sessionStorage.getItem('@Payment:user') || '{}';
    }

    if (accessToken) {
      return {
        accessToken,
        refreshToken,
        user: JSON.parse(user),
        saveLogin,
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password, saveLogin }: SignInCredentials) => {
      const response = await api.post('/sessions/establishments', {
        email,
        password,
      });

      const { user } = response.data;
      const accessToken = response.data.accessToken;
      const refreshToken = response.data.refreshToken;

      if (saveLogin) {
        setData({ accessToken, refreshToken, user, saveLogin });
      } else {
        setData({ accessToken, user, saveLogin });
      }
    },
    [],
  );

  const updateLocalStorage = () => {
    if (JSON.stringify(data) === '{}') {
      localStorage.removeItem('@Payment:accessToken');
      localStorage.removeItem('@Payment:refreshToken');
      localStorage.removeItem('@Payment:user');
      localStorage.removeItem('@Payment:saveLogin');
      sessionStorage.removeItem('@Payment:accessToken');
      sessionStorage.removeItem('@Payment:refreshToken');
      sessionStorage.removeItem('@Payment:user');
      return;
    }

    if (data.saveLogin) {
      localStorage.setItem('@Payment:accessToken', data.accessToken || '');
      localStorage.setItem('@Payment:refreshToken', data.refreshToken || '');
      localStorage.setItem('@Payment:user', JSON.stringify(data.user) || '');
      localStorage.setItem('@Payment:saveLogin', data.saveLogin.toString());
    } else {
      sessionStorage.setItem('@Payment:accessToken', data.accessToken || '');
      sessionStorage.setItem('@Payment:user', JSON.stringify(data.user) || '');
      localStorage.setItem('@Payment:saveLogin', 'false');
    }
  };

  const signOut = useCallback(() => {
    setData({} as AuthState);
  }, []);

  const refreshToken = async () => {
    return new Promise((resolve, reject) => {
      try {
        const refreshToken = data.refreshToken;
        api
          .put('/sessions/refresh-tokens', {
            refreshToken,
          })
          .then((res: any) => {
            setData({
              ...data,
              refreshToken: res.data.refreshToken,
              accessToken: res.data.accessToken,
            });
            window.location.reload();
            return res;
          })
          .catch((err: any) => {
            signOut();
            window.location.reload();
            return err;
          });
      } catch (err) {
        return err;
      }
    });
  };

  const checkIfUserHasEstablishment = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    try {
      await api.get('/users/establishments/own', { headers });
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    updateLocalStorage();
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        data,
        setData,
        signIn,
        signOut,
        refreshToken,
        checkIfUserHasEstablishment,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('userAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
