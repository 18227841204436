import React, { useEffect, useState } from 'react';
import { SelectHour } from '../../../../../../shared/components/SelectHour/SelectHour';
import { TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';

import './customize.css';
import { Operation, RowInputHour, Container } from './_BusinessHours';

import {
  useFinalizeRegistration,
  BusinessHourProps,
} from '../../../../../../hooks/FinalizeRegistration';
import api from 'services/api';
import { useAuth } from '../../../../../../hooks/Auth';

const BusinessHours = () => {
  const defaultHour = '10:00';
  const format = 'HH:mm';

  const {
    getEstablishmentBusinessHours,
    updateIsOpenEstablishment,
    updateEstablishmentBusinessHours,
  } = useFinalizeRegistration();

  const [businessHours, setBusinessHours] = useState<BusinessHourProps[]>(
    [] as BusinessHourProps[],
  );

  async function getHours() {
    const response = await getEstablishmentBusinessHours();
    setBusinessHours(response);
  }

  useEffect(() => {
    getHours();
  }, []);

  const handleCheckbox = async (event: any, id: string, week_day: string) => {
    const status = event.target.value;

    try {
      const opening = !businessHours[Number(week_day)].is_open;

      await updateIsOpenEstablishment(id, week_day, opening);

      const isSelectedDay = businessHours.find(
        (time) => time.day_of_week === Number(week_day),
      ) as BusinessHourProps;

      isSelectedDay.is_open = opening;

      const newBusinessHour = businessHours;
      newBusinessHour[Number(week_day)] = isSelectedDay;

      setBusinessHours([...newBusinessHour]);

      toast.success('Atualizado com sucesso');
    } catch (error) {
      toast.error(error?.response?.data?.message || error.toString());
    }
  };

  const getDefaultTime = (week_day: number, opening: boolean) => {
    const isTheDefaulTime = businessHours.find(
      (time) => time.day_of_week === week_day,
    ) as BusinessHourProps;

    if (isTheDefaulTime) {
      return moment(
        opening ? isTheDefaulTime.start_hour : isTheDefaulTime.end_hour,
        'HH:mm',
      );
    }
  };

  async function handleTime(
    time: Moment,
    day: number,
    id: string,
    isOpen: boolean,
  ) {
    let body;

    const newMoment = moment(time.toDate()).format('HH:mm:ss');
    const isSelectedDay = businessHours.find(
      (time) => time.day_of_week === day,
    ) as BusinessHourProps;

    if (isOpen) {
      isSelectedDay.start_hour = newMoment;
      body = {
        startHour: newMoment,
        dayOfWeek: day,
      };
    } else {
      isSelectedDay.end_hour = newMoment;
      body = {
        endHour: newMoment,
        dayOfWeek: day,
      };
    }

    try {
      await updateEstablishmentBusinessHours(id, body);
      toast.success('Atualizado com sucesso');
    } catch (error) {
      toast.error(error?.response?.data?.message || error.toString());
    }

    const newBusinessHour = businessHours;
    newBusinessHour[day] = isSelectedDay;

    setBusinessHours([...newBusinessHour]);
  }

  return (
    <Operation>
      <p>Funcionamento</p>
      <label>Horário de Funcionamento:</label>
      <RowInputHour>
        <div>
          <input
            checked={
              businessHours[1]?.is_open ? businessHours[1]?.is_open : false
            }
            type="checkbox"
            onChange={(event) =>
              handleCheckbox(event, businessHours[1]?.id, '1')
            }
          />
          <span>Segunda-feira</span>
        </div>
        <div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 1, businessHours[1]?.id, true)
              }
              value={getDefaultTime(1, true)}
              showNow={false}
              format="HH:mm"
              allowClear={false}
              placeholder={'Selecione a hora'}
              disabled={
                businessHours[1]?.is_open ? !businessHours[1]?.is_open : true
              }
            />
          </Container>
          <div>ás</div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 1, businessHours[1]?.id, false)
              }
              value={getDefaultTime(1, false)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format="HH:mm"
              allowClear={false}
              disabled={
                businessHours[1]?.is_open ? !businessHours[1]?.is_open : true
              }
            />
          </Container>
        </div>
      </RowInputHour>
      <RowInputHour>
        <div>
          <input
            checked={
              businessHours[2]?.is_open ? businessHours[2]?.is_open : false
            }
            type="checkbox"
            onChange={(event) =>
              handleCheckbox(event, businessHours[2]?.id, '2')
            }
          />
          <span>Terça-feira</span>
        </div>
        <div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 2, businessHours[2]?.id, true)
              }
              value={getDefaultTime(2, true)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[2]?.is_open ? !businessHours[2]?.is_open : true
              }
            />
          </Container>
          <div>ás</div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 2, businessHours[2]?.id, false)
              }
              value={getDefaultTime(2, false)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[2]?.is_open ? !businessHours[2]?.is_open : true
              }
            />
          </Container>
        </div>
      </RowInputHour>
      <RowInputHour>
        <div>
          <input
            checked={
              businessHours[3]?.is_open ? businessHours[3]?.is_open : false
            }
            type="checkbox"
            onChange={(event) =>
              handleCheckbox(event, businessHours[3]?.id, '3')
            }
          />
          <span>Quarta-feira</span>
        </div>
        <div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 3, businessHours[3]?.id, true)
              }
              value={getDefaultTime(3, true)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[3]?.is_open ? !businessHours[3]?.is_open : true
              }
            />
          </Container>
          <div>ás</div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 3, businessHours[3]?.id, false)
              }
              value={getDefaultTime(3, false)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[3]?.is_open ? !businessHours[3]?.is_open : true
              }
            />
          </Container>
        </div>
      </RowInputHour>
      <RowInputHour>
        <div>
          <input
            type="checkbox"
            checked={
              businessHours[4]?.is_open ? businessHours[4]?.is_open : false
            }
            onChange={(event) =>
              handleCheckbox(event, businessHours[4]?.id, '4')
            }
          />
          <span>Quinta-feira</span>
        </div>
        <div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 4, businessHours[4]?.id, true)
              }
              value={getDefaultTime(4, true)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[4]?.is_open ? !businessHours[4]?.is_open : true
              }
            />
          </Container>
          <div>ás</div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 4, businessHours[4]?.id, false)
              }
              value={getDefaultTime(4, false)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[4]?.is_open ? !businessHours[4]?.is_open : true
              }
            />
          </Container>
        </div>
      </RowInputHour>
      <RowInputHour>
        <div>
          <input
            checked={
              businessHours[5]?.is_open ? businessHours[5]?.is_open : false
            }
            type="checkbox"
            onChange={(event) =>
              handleCheckbox(event, businessHours[5]?.id, '5')
            }
          />
          <span>Sexta-feira</span>
        </div>
        <div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 5, businessHours[5]?.id, true)
              }
              value={getDefaultTime(5, true)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[5]?.is_open ? !businessHours[5]?.is_open : true
              }
            />
          </Container>
          <div>ás</div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 5, businessHours[5]?.id, false)
              }
              value={getDefaultTime(5, false)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[5]?.is_open ? !businessHours[5]?.is_open : true
              }
            />
          </Container>
        </div>
      </RowInputHour>
      <RowInputHour>
        <div>
          <input
            checked={
              businessHours[6]?.is_open ? businessHours[6]?.is_open : false
            }
            type="checkbox"
            onChange={(event) =>
              handleCheckbox(event, businessHours[6]?.id, '6')
            }
          />
          <span>Sábado</span>
        </div>
        <div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 6, businessHours[6]?.id, true)
              }
              value={getDefaultTime(6, true)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[6]?.is_open ? !businessHours[6]?.is_open : true
              }
            />
          </Container>
          <div>ás</div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 6, businessHours[6]?.id, false)
              }
              value={getDefaultTime(6, false)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[6]?.is_open ? !businessHours[6]?.is_open : true
              }
            />
          </Container>
        </div>
      </RowInputHour>
      <RowInputHour>
        <div>
          <input
            checked={
              businessHours[0]?.is_open ? businessHours[0]?.is_open : false
            }
            type="checkbox"
            onChange={(event) =>
              handleCheckbox(event, businessHours[0]?.id, '0')
            }
          />
          <span>Domingo</span>
        </div>
        <div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 0, businessHours[0]?.id, true)
              }
              value={getDefaultTime(0, true)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[0]?.is_open ? !businessHours[0]?.is_open : true
              }
            />
          </Container>
          <div>ás</div>
          <Container>
            <TimePicker
              onChange={(time: any) =>
                handleTime(time, 0, businessHours[0]?.id, false)
              }
              value={getDefaultTime(0, false)}
              showNow={false}
              placeholder={'Selecione a hora'}
              format={format}
              disabled={
                businessHours[0]?.is_open ? !businessHours[0]?.is_open : true
              }
            />
          </Container>
        </div>
      </RowInputHour>
    </Operation>
  );
};

export default BusinessHours;
