import Modal from 'react-modal';

import './modal-responsive.css';

import { Spinner } from '../Spinner/Spinner';

interface Props {
  openModal: boolean;
}

export const SpinnerModal = ({ openModal }: Props) => {
  return (
    <Modal
      isOpen={openModal}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="spinner-modal-responsive"
    >
      <Spinner />
    </Modal>
  );
};
