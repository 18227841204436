import React, { useState, useEffect } from 'react';
import { Route as ReactDOMRoute, Redirect, useHistory } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import { isBefore } from 'date-fns';
import { useAuth } from '../hooks/Auth';

import { EstablishmentData } from '../pages/FinalizeRegistration/EstablishmentData/EstablishmentData';
import { BankDetails } from '../pages/FinalizeRegistration/BankDetails/BankDetails';

interface RouteProps {
  isPrivateAndPublic?: boolean;
  isPrivate?: boolean;
  Component: React.FC;
  path: string;
  exact?: boolean;
}

export const Route = ({
  isPrivateAndPublic = false,
  isPrivate = false,
  Component,
  path,
  exact,
  ...rest
}: RouteProps) => {
  const history = useHistory();
  const { data, signOut, refreshToken } = useAuth();
  const [renderRoute, setRenderRoute] = useState(false);

  const verifyAccessToken = async () => {
    setRenderRoute(false);
    if (data.accessToken) {
      const decodedToken: any = decode(data.accessToken);

      const currentDate = new Date();
      let expirationDate = new Date(decodedToken.exp * 1000);
      if (isBefore(expirationDate, currentDate)) {
        if (data.saveLogin) {
          await refreshToken();
        } else {
          signOut();
          window.location.reload();
          return;
        }
      }

      setRenderRoute(true);
    } else {
      setRenderRoute(true);
    }
  };

  const verifyRoute = () => {
    if (data.accessToken !== undefined && data.accessToken !== '') {
      if (
        (!data.user.establishment?.id ||
          !data.user.establishment?.document?.sent_proof_of_address) &&
        path === '/finalizar-cadastro/dados-do-endereco'
      ) {
        return;
      }

      if (
        !data.user.establishment?.id ||
        !data.user.establishment?.document?.sent_cnpj ||
        !data.user.establishment?.document?.sent_id_card ||
        !data.user.establishment?.document?.sent_proof_of_activity
      ) {
        history.push('/finalizar-cadastro/dados-do-estabelecimento');
        return;
      }

      if (!data.user.establishment?.document?.registered_bank_account) {
        history.push('/finalizar-cadastro/dados-bancarios');
        return;
      }

      if (
        path === '/finalizar-cadastro/dados-do-estabelecimento' ||
        path === '/finalizar-cadastro/dados-do-endereco' ||
        path === '/finalizar-cadastro/dados-bancarios'
      ) {
        history.push('/cardapios');
        return;
      }
    }
  };

  useEffect(() => {
    verifyAccessToken();
    verifyRoute();
  }, [path]);

  return (
    <ReactDOMRoute
      {...rest}
      exact
      render={({ location }) => {
        if (isPrivateAndPublic) {
          return renderRoute && <Component />;
        }

        if (
          isPrivate ===
          (data.accessToken !== undefined && data.accessToken !== '')
        ) {
          return renderRoute && <Component />;
        }

        return (
          renderRoute && (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/cardapio/cadastro',
              }}
            />
          )
        );
      }}
    />
  );
};
