import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { BsCheck } from 'react-icons/bs';
import { LoadingOutlined } from '@ant-design/icons';

import { WrapperPage } from '../../shared/components/WrapperPage/WrapperPage';
import { MenuItem } from './components/MenuItem/MenuItem';
import { Button } from '../../shared/components/Button/Button';

import { useAuth } from '../../hooks/Auth';
import { TourModal } from '../../shared/components/TourModal/TourModal';

import { Title, Row, ListMenus, Options } from './_FoodMenu';
import { useFoodMenu } from '../../hooks/FoodMenu';
import { Spinner } from 'shared/components/Spinner/Spinner';

import { NotificationsModal } from '../../shared/components/NotificationsModal/NotificationsModal';

export const FoodMenu = () => {
  const history = useHistory();
  const { data } = useAuth();
  const { menus, getAllMenus, handleDeleteCategory, handleTutorialDone } =
    useFoodMenu();

  const [loading, setLoading] = useState<boolean>(false);
  const [modalCheckinOpen, setModalCheckinOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTour, setOpenModalTour] = useState(true);

  useEffect(() => {
    renderAllMenus();
  }, []);

  const renderAllMenus = async () => {
    try {
      setLoading(true);
      const loadMenus = await getAllMenus();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const handleDeleteCategoryEstablishment = async (categoryId: string) => {
    setLoading(true);
    try {
      await handleDeleteCategory(categoryId);
      renderAllMenus();
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const renderMenuItems = () => {
    const items = menus.map((item) => (
      <MenuItem
        key={item.id}
        name={item.name}
        id={item.id}
        handleEditMenuItem={handleEditMenuItem}
        handleDeleteCategory={handleDeleteCategoryEstablishment}
      />
    ));
    return items;
  };

  const handleEditMenuItem = (menuItemId: string) => {
    history.push(`/cardapio/${menuItemId}/itens`);
  };

  const handleRegisterNewMenu = () => {
    history.push('/cardapio/cadastro');
  };

  return (
    <WrapperPage title="Cadastro">
      <Title>Cardápios Cadastrados</Title>
      <Row>
        {loading ? <Spinner /> : <ListMenus>{renderMenuItems()}</ListMenus>}

        <Options>
          <Button onClick={() => handleRegisterNewMenu()}>
            + Novo Cardápio
          </Button>
          <Button
            style={{ backgroundColor: 'var(--greenText)', color: '#fff' }}
          >
            Cardápios
          </Button>
        </Options>
      </Row>
      {!data.user.establishment?.tutorial_done && (
        <TourModal
          handleTutorialDone={handleTutorialDone}
          openModalTour={openModalTour}
          setOpenModalTour={setOpenModalTour}
        />
      )}
      <NotificationsModal />
    </WrapperPage>
  );
};
