import React, { useContext, createContext, useState } from 'react';
import { idText } from 'typescript';
import api from '../services/api';

import { useAuth } from './Auth';

interface FoodMenuContextProps {
  getCategories(): Promise<void>;
  getAllMenus(): Promise<void>;
  handlePauseItem(itemId: string, stateItem: boolean): Promise<void>;
  getMenuOnlyItem(itemId: string): Promise<void>;
  getMenuItems(categoryId: string): Promise<void>;
  handleDeleteItemMenu(productItem: string): Promise<void>;
  handleDeleteCategory(categoryItem: string): Promise<void>;
  handleEditItem(
    productId: string,
    formData: FetchOnlyItemProps,
  ): Promise<void>;
  categories: CategoryProps[];
  menus: CategoryProps[];
  menuItems: MenuItemsProps[];
  onlyItem: FetchOnlyItemProps;
  registerNewCategory(categoryName: string): Promise<string>;
  handleRegisterNewItem(itensInfo: CreateNewItemOnMenu): Promise<void>;
  handleChangeImage(itemId: string, itensInfo: UploadNewImage): Promise<void>;
  handleTutorialDone(isTutorialDone: boolean): Promise<void>;
  handleCancelingOrder(idOrder: string): Promise<void>;
  categorieName: string;
}

export interface FetchItemsMenuProps {
  results: MenuItemsProps[];
  category_name: string;
}

export interface FetchOnlyItemProps {
  time_in_minutes: number;
  name: string;
  price: string;
  is_active: boolean;
  available_quantity: number;
  establishment_id: string;
  category_id: string;
  description: string;
  pdv: string;
}

export interface CreateNewItemOnMenu {
  name: string;
  price: string;
  is_active: boolean;
  time_in_minutes: number;
  available_quantity: number;
  image: File | undefined;
  category_id: string;
  description: string;
  pdv: string;
}

export interface UploadNewImage {
  image: File;
}

export interface MenuItemsProps {
  time_in_minutes: number;
  name: string;
  price: string;
  is_active: boolean;
  id: string;
  available_quantity: number;
  establishment_id: string;
  image_url: string;
  category?: CategoryItemnsProps;
}

interface CategoryItemnsProps {
  name: string;
}

export interface CategoryProps {
  id: string;
  name: string;
}

const FoodMenuContext = createContext<FoodMenuContextProps>(
  {} as FoodMenuContextProps,
);

const FoodMenuProvider: React.FC = ({ children }) => {
  const { data, setData } = useAuth();

  const [categories, setCategories] = useState<CategoryProps[]>(
    [] as CategoryProps[],
  );

  const [menus, setMenus] = useState<CategoryProps[]>([] as CategoryProps[]);

  const [categorieName, setCategorieName] = useState('');

  const [menuItems, setMenuItems] = useState<MenuItemsProps[]>(
    [] as MenuItemsProps[],
  );

  const [onlyItem, setOnlyItem] = useState<FetchOnlyItemProps>(
    {} as FetchOnlyItemProps,
  );

  const getCategories = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.get<CategoryProps[]>(
      `/categories/for-establishments?establishmentId=${data.user.establishment?.id}`,
      { headers },
    );
    setCategories(response.data);
  };

  const getAllMenus = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.get<CategoryProps[]>(
      `/establishments/${data.user.establishment?.id}/menu?relation=false`,
      { headers },
    );
    setMenus(response.data);
  };

  const getMenuItems = async (categoryId: string) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.get<FetchItemsMenuProps>(
      `products?establishment_id=${data.user.establishment?.id}&category_id=${categoryId}&page=1&limit=1000`,
      { headers },
    );
    setMenuItems(response.data.results);
    setCategorieName(response.data.category_name);
  };

  const getMenuOnlyItem = async (categoryId: string) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.get<FetchOnlyItemProps>(
      `products/${categoryId}`,
      { headers },
    );

    setOnlyItem(response.data);
  };

  const handlePauseItem = async (itemId: string, stateItem: boolean) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.patch(
      `products/${itemId}/active`,
      {
        isActive: !stateItem,
      },
      { headers },
    );

    const newItems = menuItems.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          is_active: !stateItem,
        };
      }
      return item;
    });

    setMenuItems(newItems);

    return response.data;
  };

  const handleEditItem = async (
    itemId: string,
    formData: FetchOnlyItemProps,
  ) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const newValue = formData.price.replace(/[.]+/g, '').replace(',', '.');

    const response = await api.put<FetchOnlyItemProps>(
      `products/${itemId}`,
      {
        available_quantity: Number(formData.available_quantity),
        category_id: formData.category_id,
        establishment_id: formData.establishment_id,
        is_active: formData.is_active,
        name: formData.name,
        price: newValue,
        time_in_minutes: Number(formData.time_in_minutes),
        pdv: formData.pdv || '',
      },
      { headers },
    );
  };

  const handleRegisterNewItem = async (itensInfo: CreateNewItemOnMenu) => {
    const formData = new FormData();
    const newValue = itensInfo.price.replace(/[.]+/g, '').replace(',', '.');

    if (itensInfo.image) {
      formData.append('image', itensInfo.image as File);
    }

    if (itensInfo.description) {
      formData.append('description', itensInfo.description);
    }

    formData.append('name', itensInfo.name);
    formData.append('price', newValue);
    formData.append('time_in_minutes', itensInfo.time_in_minutes.toString());
    formData.append(
      'available_quantity',
      itensInfo.available_quantity.toString(),
    );
    formData.append('is_active', 'true');
    formData.append('establishment_id', data.user.establishment?.id || '');
    formData.append('category_id', itensInfo.category_id);

    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response: any = await api.post<CreateNewItemOnMenu>(
      `products/`,
      formData,
      { headers },
    );

    formData.append('pvd', itensInfo.pdv || '');
    await api.put(`/products/${response?.data?.id}`,{
      pdv: itensInfo.pdv,
    },{headers});
  };

  const handleTutorialDone = async (isTutorialDone: boolean) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.patch(
      `establishments/tutorial`,
      {
        tutorialDone: isTutorialDone,
      },
      { headers },
    );

    setData({
      ...data,
      user: {
        ...data.user,
        establishment: {
          ...data.user.establishment,
          tutorial_done: isTutorialDone,
        },
      },
    });
  };

  const handleChangeImage = async (
    itemId: string,
    itensInfo: UploadNewImage,
  ) => {
    const formData = new FormData();
    formData.append('image', itensInfo.image);
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.patch<UploadNewImage>(
      `/products/${itemId}/image`,
      formData,
      { headers },
    );
  };

  const handleDeleteItemMenu = async (itemId: string) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.delete(`products/${itemId}`, { headers });

    return response.data;
  };

  const handleDeleteCategory = async (categoryId: string) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.delete(
      `categories/${categoryId}/for-establishments`,
      { headers },
    );

    return response.data;
  };

  const registerNewCategory = async (categoryName: string) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.post(
      `/categories/for-establishments?establishmentId=${data.user.establishment?.id}`,
      {
        name: categoryName,
      },
      { headers },
    );

    return response.data.id;
  };

  const handleCancelingOrder = async (idOrder: string) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.delete(
      `/orders/${idOrder}/establishments/cancel`,
      { headers },
    );

    return response.data.id;
  };

  return (
    <FoodMenuContext.Provider
      value={{
        getCategories,
        categories,
        handleDeleteItemMenu,
        handleDeleteCategory,
        registerNewCategory,
        handlePauseItem,
        getAllMenus,
        getMenuOnlyItem,
        handleEditItem,
        handleRegisterNewItem,
        handleChangeImage,
        onlyItem,
        menus,
        getMenuItems,
        menuItems,
        handleTutorialDone,
        categorieName,
        handleCancelingOrder,
      }}
    >
      {children}
    </FoodMenuContext.Provider>
  );
};

const useFoodMenu = (): FoodMenuContextProps => {
  const context = useContext(FoodMenuContext);

  if (!context) {
    throw new Error('useFoodMenu must be used within an FoodMenuProvider');
  }

  return context;
};

export { FoodMenuProvider, useFoodMenu };
