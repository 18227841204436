import styled from 'styled-components';

import { FaCheckCircle } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';

interface Props {
  inProgress: boolean;
  finished: boolean;
  status: string;
}

export const Order = styled.div`
  animation: entering 0.8s;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 968px;
`;

export const OrderContent = styled.div`
  margin: 0 auto 15px auto;
  width: 100%;
  max-width: 700px;
  position: relative;
`;

export const OrderNumber = styled.div`
  width: 100%;
  min-width: 240px;
  max-width: 175px;
  min-height: 51px;
  margin: 5px 0;
  padding: 5px 0;
  font-size: 14;
  color: var(--gray);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  small {
    font-size: 0.9rem;
    font-weight: 700;
    b {
      color: var(--greenText);
    }
  }
  span {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 300;
    color: var(--medium-gray);
    img {
      margin-right: 5px;
    }
  }
`;
export const OrderDescription = styled.div`
  width: 100%;
  min-width: 230px;
  max-width: 670px;
  min-height: 222px;
  border-radius: 26px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  position: relative;
  gap: 10px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  min-width: 180px;
  /* max-width: 165px; */
  max-width: 440px;
  min-height: 180px;
  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--greenText);
    width: 100%;
    padding: 0 5px;
    margin-bottom: 10px;
  }

  @media (max-width: 739px) {
    text-align: center;
  }
`;
export const MainOrderList = styled.ul`
  font-size: 0.9rem;
  color: #8e8e93;
  li {
    font-family: 'FontsFreeNetSFProTextBold';
    padding: 2px 0px;
    span {
      font-family: 'FontsFreeNetSFProTextMedium';
      display: inline-block;
      text-align: left;
    }
  }
`;

export const ContainerItens = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 739px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const Total = styled.span`
  font-size: 21px;
  color: #8e8e93;
  margin: 0 auto 0 0;
  b {
    color: var(--greenText);
  }

  @media (max-width: 739px) {
    margin: 0 auto;
  }
`;

export const SingleOrderList = styled.ul`
  font-size: 12px;
  font-weight: 600;
  color: #8e8e93;
  li {
    font-family: 'FontsFreeNetSFProTextBold';
    padding: 2px 0px;

    span {
      font-weight: 100;
      font-family: 'FontsFreeNetSFProTextMedium';
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 180px;
  margin: 0 auto;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

export const AcceptButton = styled.button`
  width: 168px;
  min-height: 57px;
  border-radius: 29px;
  border: unset;
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: var(--greenText);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  &:hover {
    opacity: 0.9;
  }
  img {
    padding-right: 5px;
    width: 28px;
    height: auto;
  }
  &:focus {
    outline: none;
    border-radius: 29px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.65);
  }
`;
export const RefuseButton = styled.button`
  width: 168px;
  min-height: 57px;
  border-radius: 29px;
  border: unset;
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 500;
  color: #f00;
  background: rgba(255, 123, 153, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: baseline;
  &:hover {
    opacity: 0.9;
  }
  img {
    padding-right: 5px;
    width: 28px;
    height: auto;
  }
  &:focus {
    outline: none;
    border-radius: 29px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 3.65);
  }
`;

export const FinishButton = styled.button`
  width: 168px;
  min-height: 57px;
  border-radius: 29px;
  border: unset;
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 500;
  color: #007aff;
  background: #5ac8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: flex-end;
  &:hover {
    opacity: 0.9;
  }
  img {
    padding-right: 5px;
    width: 28px;
    height: auto;
  }
  &:focus {
    outline: none;
    border-radius: 29px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.65);
  }
`;
export const OrderStatus = styled.div`
  margin-top: -120px;
  position: relative;
  z-index: -1;
  width: 100%;
  min-width: 230px;
  max-width: 670px;
  min-height: 222px;
  border-radius: 26px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 15px;
  padding-top: 125px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  background: #efeff4;
`;

export const TimeInfo = styled.div`
  width: 100%;
  max-width: 310px;
  height: 65px;
  margin: 5px auto;
  padding: 5px 5px;
  font-size: 17px;
  font-weight: 700;
  color: #8e8e93;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  span {
    color: var(--greenText);
    font-size: 14px;
    font-weight: 500;
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 3px;
    height: 65px;
    background: #d1d1d6;
  }
  @media (max-width: 1024px) {
    text-align: center;
    &:after {
      display: none;
    }
  }

  @media (max-width: 661px) {
    span,
    b {
      width: 100%;
      text-align: center;
    }
  }
`;

export const StatusFeedback = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  width: 225px;
  margin: 0 auto;
  min-height: 70px;
  color: var(--light-gray);
  text-align: center;
  position: relative;
  transition: all 0.5s;
  &:before {
    position: absolute;
    top: 50%;
    left: 54px;
    content: '';
    width: 29px;
    height: 3px;
    background: var(--greenText);
    transform: translateY(-8px);
  }
  &:after {
    position: absolute;
    top: 50%;
    right: 54px;
    content: '';
    width: 33px;
    height: 3px;
    background: ${({ status }) =>
      status === 'finished' || status === 'paid'
        ? 'var(--greenText)'
        : 'var(--light-gray)'};
    transform: translateY(-8px);
  }

  /* first circle */
  div:nth-child(1) {
    div {
      border-color: var(--greenText);
    }
    small {
      color: var(--greenText);
    }
  }

  /* second circle */
  div:nth-child(2) {
    div {
      border-color: ${({ status }) =>
        status === 'accept' || status === 'finished' || status === 'paid'
          ? 'var(--greenText)'
          : 'var(--light-gray)'};
    }
    small {
      color: ${({ status }) =>
        status === 'accept' || status === 'finished' || status === 'paid'
          ? 'var(--greenText)'
          : 'var(--light-gray)'};
    }
  }

  /* third circle */
  div:nth-child(3) {
    div {
      border-color: ${({ status }) =>
        status === 'finished' || status === 'paid'
          ? 'var(--greenText)'
          : 'var(--light-gray)'};
    }
    small {
      color: ${({ status }) =>
        status === 'finished' || status === 'paid'
          ? 'var(--greenText)'
          : 'var(--light-gray)'};
    }
  }
`;
export const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  small {
    font-size: 8px;
    padding-top: 2px;
  }
`;

export const CircleIcon = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid var(--light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 23px;
    height: auto;
  }
`;
export const CustomerSection = styled.div`
  width: 100%;
  min-width: 230px;
  max-width: 256px;
  min-height: 328px;
  border-radius: 21px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 11px;
  font-weight: 300;
  color: var(--gray);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 15px auto;
  b {
    display: flex;
    align-items: center;
  }
`;

export const DateCheckin = styled.b`
  margin-top: 40px;
`;

export const Customer = styled.div`
  width: 100%;
  min-height: 248px;
  border-radius: 21px;
  background: #e5e5ea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  img {
    max-width: 165px;
    max-height: 142px;
    border-radius: 20px;
    margin: 20px 0px;
  }

  h3 {
    color: var(--greenText);
    font-size: 18px;
  }
  span {
    color: var(--gray);
    font-size: 11px;

    b {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
`;

export const Evaluation = styled.div`
  width: 94px;
  min-height: 47px;
  border-radius: 9px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  font-size: 8px;
  color: #363e55;
  text-align: left;
  margin-bottom: -30px;
`;
export const Stars = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 75px;
  justify-content: space-between;
  margin-top: 5px;
  img {
    width: 11px;
    height: 10px;
  }
  svg {
    font-size: 0.8rem;
    color: #c7c7cc;

    &.active {
      color: #ffcc00;
    }
  }
`;

export const WarningTitle = styled.h1`
  color: #000000;
  text-align: center;
  font-size: 27px;
  margin-bottom: 33px;
  @media screen and (max-width: 720px) {
    font-size: 20px;
  }
`;

export const WarningSubtitle = styled.p`
  color: #5b5b5d;
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 22px;
  margin-bottom: 33px;
  text-align: center;

  @media screen and (max-width: 720px) {
    font-size: 1rem;
  }
`;

export const WarningContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  button {
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    color: #fff;
    gap: 11px;
    width: 160px;
  }
`;

export const WarningYes = styled.button`
  height: 64px;
  background: #21e270;
  font-size: 19px;
  font-weight: bold;
`;

export const WarningNo = styled.button`
  height: 64px;
  background: #ff0045;
  font-size: 19px;
  font-weight: bold;
`;

export const AcceptIcon = styled(FaCheckCircle)``;

export const RefuseIcon = styled(ImCancelCircle)``;

export const ObservationContainer = styled.div`
  width: 100%;
  padding: 20px;
  margin: 20px 0px;
  background-color: #fffdcd;
  border-radius: 10px;

  > div {
    font-family: 'FontsFreeNetSFProTextBold';
    color: var(--descripionText);
    font-size: 0.9rem;
    overflow-y: hidden;
  }

  > p {
    font-family: 'FontsFreeNetSFProTextMedium';
    color: var(--descripionText);
    font-size: 0.8rem;
    margin-top: 6px;
  }
`;

export const PaidForTheApp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  background: #e4ffef;
  position: absolute;
  z-index: 2;
  right: 80px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  > div {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 11px;
    margin-left: 4px;
    color: #21e270;
  }

  @media (max-width: 740px) {
    right: 14px;
    width: 80px;
  }
`;
