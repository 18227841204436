import { Select as SelecteAntd, SelectProps } from 'antd';
import { Container, Text } from './_Select';
import { BiChevronDown } from 'react-icons/bi';

import { CategoryProps } from '../../../../../../hooks/FoodMenu';

interface Props extends SelectProps<string> {
  data: CategoryProps[];
}

export const Select = ({ data, ...rest }: Props) => {
  const { Option } = SelecteAntd;

  const renderOptions = () => {
    return data.map((element) => (
      <Option
        style={{
          color: 'var(--descripionText)',
          fontFamily: 'FontsFreeNetSFProTextLight',
        }}
        value={element.id}
        key={element.id}
      >
        {element.name}
      </Option>
    ));
  };

  return (
    <Container>
      <SelecteAntd
        suffixIcon={<BiChevronDown size={40} />}
        bordered={false}
        {...rest}
      >
        {renderOptions()}
      </SelecteAntd>
    </Container>
  );
};
