import { BrowserRouter, Switch } from 'react-router-dom';

import { Route } from './Route';

import { Login } from '../pages/Login/Login';
import { SignUp } from '../pages/SignUp/SignUp';
import { ConfirmPassword } from '../pages/ConfirmPassword/ConfirmPassword';
import { PasswordForgot } from 'pages/PasswordForgot/PasswordForgot';
import { EstablishmentData } from '../pages/FinalizeRegistration/EstablishmentData/EstablishmentData';
import { AddressData } from '../pages/FinalizeRegistration/AddressData/AddressData';
import { BankDetails } from '../pages/FinalizeRegistration/BankDetails/BankDetails';
import { FoodMenu } from '../pages/FoodMenu/FoodMenu';
import { MenuItems } from '../pages/FoodMenu/pages/MenuItems/MenuItems';
import { EditMenuItem } from '../pages/FoodMenu/pages/EditMenuItem/EditMenuItem';
import { Solicitations } from '../pages/Solicitations/Solicitations';
import { RegisterNewMenu } from '../pages/FoodMenu/pages/RegisterNewMenu/RegisterNewMenu';
import { RegisterNewItem } from '../pages/FoodMenu/pages/RegisterNewItem/RegisterNewItem';
import { AdditionalInformation } from '../pages/AdditionalInformation/AdditionalInformation';
import { Settings } from '../pages/Settings/Settings';
import { EstablishmentProfile } from '../pages/Settings/pages/EstablishmentProfile/EstablishmentProfile';
import { BankData } from '../pages/Settings/pages/BankData/BankData';
import { TermsOfUseAndPrivacy } from '../pages/Settings/pages/TermsOfUseAndPrivacy/TermosOfUseAndPrivacy';
import { RequestManagement } from '../pages/RequestsManagement/RequestManagement';
import TablesManagement from 'pages/TablesManagement/TablesManagement';
import TableAllocation from 'pages/Solicitations/ListSolicitations/pages/TableAllocation/TableAllocation';
import { PrivacyPolicies } from '../pages/PrivacyPolicies/PrivacyPolicies';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route Component={Login} path="/" exact />
      <Route Component={SignUp} path="/criar-conta" exact />
      <Route Component={ConfirmPassword} path="/confirmar-senha" exact />
      <Route Component={PasswordForgot} path="/alterar-senha/:token" exact />
      <Route
        Component={EstablishmentData}
        path="/finalizar-cadastro/dados-do-estabelecimento"
        exact
        isPrivate
      />
      <Route
        Component={AddressData}
        path="/finalizar-cadastro/dados-do-endereco"
        exact
        isPrivate
      />
      <Route
        Component={BankDetails}
        path="/finalizar-cadastro/dados-bancarios"
        exact
        isPrivate
      />
      <Route
        Component={RegisterNewMenu}
        path="/cardapio/cadastro"
        exact
        isPrivate
      />
      <Route Component={FoodMenu} path="/cardapios" exact isPrivate />
      <Route
        Component={RegisterNewItem}
        path="/cardapio/:id/cadastrar-item"
        exact
        isPrivate
      />
      <Route path="/cardapio/:id/itens" Component={MenuItems} exact isPrivate />
      <Route
        Component={EditMenuItem}
        path="/cardapio/:id/item/:itemId"
        exact
        isPrivate
      />
      <Route Component={Solicitations} path="/solicitacoes" exact isPrivate />
      <Route
        Component={AdditionalInformation}
        path="/informacoes-adicionais"
        exact
        isPrivate
      />
      <Route Component={Settings} path="/configuracoes" exact isPrivate />
      <Route
        Component={EstablishmentProfile}
        path="/configuracoes/perfil-do-estabelecimento"
        exact
        isPrivate
      />
      <Route
        Component={BankData}
        path="/configuracoes/dados-bancarios"
        exact
        isPrivate
      />
      <Route
        Component={TermsOfUseAndPrivacy}
        path="/configuracoes/termos-de-uso-e-privacidade"
        exact
        isPrivate
      />
      <Route Component={RequestManagement} path="/pedidos" exact isPrivate />
      <Route
        Component={TablesManagement}
        path="/gestao-de-mesas"
        exact
        isPrivate
      />
      <Route
        Component={TableAllocation}
        path="/solicitacoes/alocacao-de-mesa"
        exact
        isPrivate
      />
      <Route
        Component={PrivacyPolicies}
        path="/politicas-de-privacidade-e-termos-de-uso"
        exact
        isPrivateAndPublic
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
