import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';

import './modal-responsive.css';

import exit from '../../../../shared/icons/exit.png';

import { Title, Row, Icon, Description } from './_TermsAndConditionsModal';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  privacyPolicy: string;
}

export const TermsAndConditionsModal = ({
  openModal,
  setOpenModal,
  privacyPolicy,
}: Props) => {
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      className="terms-and-conditions-modal-responsive"
    >
      <Row>
        <Title>
          Termos de Uso e <br />
          Política de Privacidade
        </Title>
        <Icon src={exit} onClick={() => setOpenModal(false)} />
      </Row>
      <Description>{privacyPolicy}</Description>
    </Modal>
  );
};
