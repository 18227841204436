import { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'react-modal';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import api from '../../../../../services/api';
import { useAuth } from '../../../../../hooks/Auth';

import {
  phoneNumberMask,
  removePhoneNumberMask,
} from '../../../../../utils/Masks';

import './modal-responsive.css';

import { Input } from '../../../../../shared/components/Input/Input';
import { Button } from '../../../../../shared/components/Button/Button';

import {
  Title,
  Description,
  InputContainer,
  ResendCodeText,
} from './_AuthenticationModal';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  actionAfterConfirmationNumber: () => void;
  setCode: Dispatch<SetStateAction<string>>;
  loadingPhoneCode: boolean;
  allowResendCode: boolean;
}

export const AuthenticationModal = ({
  openModal,
  setOpenModal,
  actionAfterConfirmationNumber,
  setCode,
  loadingPhoneCode,
  allowResendCode,
}: Props) => {
  const [showChangeNumberForm, setShowChangeNumberForm] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [loadingChangePhoneNumber, setLoadingChangePhoneNumber] =
    useState(false);
  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const { data } = useAuth();

  const handleChangePhoneNumber = async () => {
    if (newPhoneNumber.trim().length < 11) {
      toast.error('Digite o número do seu celular corretamente');
      return;
    }

    setLoadingChangePhoneNumber(true);
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    await api
      .patch('/users/phones', { phone: newPhoneNumber }, { headers })
      .then(() => {
        setShowChangeNumberForm(false);
      })
      .catch((error) => {
        setLoadingChangePhoneNumber(false);
        handleError(error);
      });
  };

  const handleResendCode = async () => {
    setLoadingResendCode(true);
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    await api
      .post('/codes/resend', {}, { headers })
      .then(() => {
        toast.success('Código reenviado com sucesso');
        setLoadingResendCode(false);
      })
      .catch((error) => {
        setLoadingResendCode(false);
        handleError(error);
      });
  };

  const handleError = (error: any) => {
    toast.error(error.response?.data?.message || error.toString());
  };

  const renderForm = () => {
    if (!showChangeNumberForm) {
      return (
        <>
          <Description>
            Enviamos um código para fazer a autenticação do seu telefone
            celular.
          </Description>
          <Description>Insira abaixo o código recebido:</Description>
          <InputContainer>
            <Input onChange={(event) => setCode(event.target.value)} />
          </InputContainer>
          <Button
            onClick={() => actionAfterConfirmationNumber()}
            style={{
              backgroundColor: 'var(--greenText)',
              color: '#fff',
              width: '80%',
              margin: '0 auto',
            }}
          >
            {loadingPhoneCode ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Enviar'
            )}
          </Button>
          <Button
            onClick={() => setShowChangeNumberForm(true)}
            style={{
              backgroundColor: 'var(--greenText)',
              color: '#fff',
              width: '80%',
              margin: '10px auto',
            }}
          >
            Trocar número do celular
          </Button>
          {loadingResendCode ? (
            <LoadingOutlined
              style={{
                fontSize: 24,
                color: 'var(--greenText)',
                margin: '20px auto',
                width: '100%',
              }}
              spin
            />
          ) : (
            <Description
              onClick={() => handleResendCode()}
              style={{ marginTop: '20px', cursor: 'pointer' }}
            >
              Reenviar código
            </Description>
          )}
        </>
      );
    }

    return (
      <>
        <Description>Digite abaixo o número do seu celular</Description>
        <InputContainer>
          <Input
            value={phoneNumberMask(newPhoneNumber)}
            onChange={(event) =>
              setNewPhoneNumber(removePhoneNumberMask(event.target.value))
            }
          />
        </InputContainer>
        <Button
          onClick={() => handleChangePhoneNumber()}
          style={{
            backgroundColor: 'var(--greenText)',
            color: '#fff',
            width: '80%',
            margin: '10px auto',
          }}
        >
          {loadingChangePhoneNumber ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            'Reenviar código'
          )}
        </Button>
        <Description
          style={{ cursor: 'pointer' }}
          onClick={() => setShowChangeNumberForm(false)}
        >
          Voltar
        </Description>
      </>
    );
  };

  return (
    <Modal
      isOpen={openModal}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="authentication-modal-responsive"
    >
      <Title>Código de autenticação</Title>
      {renderForm()}
    </Modal>
  );
};
