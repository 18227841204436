import React from 'react';

import { AuthProvider } from './Auth';
import { CreateAccountProvider } from './CreateAccount';
import { FinalizaRegistrationProvider } from './FinalizeRegistration';
import { FoodMenuProvider } from './FoodMenu';
import { SocketProvider } from './Socket';
import { TableManagementProvider } from './TableManagement';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <SocketProvider>
      <CreateAccountProvider>
        <FinalizaRegistrationProvider>
          <TableManagementProvider>
            <FoodMenuProvider>{children}</FoodMenuProvider>
          </TableManagementProvider>
        </FinalizaRegistrationProvider>
      </CreateAccountProvider>
    </SocketProvider>
  </AuthProvider>
);

export default AppProvider;
