import styled from 'styled-components';

interface TableStatusProps {
  tableStatus: boolean;
}

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  > button {
    background: #21e270;
    color: #fff;
    font-size: 1.125rem;
  }
`;

export const SelectedTable = styled.div`
  color: #fff;
  font-size: 24px;
  background-color: #21e270;
  width: 86px;
  font-family: 'FontsFreeNetSFProTextLight';
  height: 86px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  font-size: 18px;
  color: #8e8e93;
  font-family: 'FontsFreeNetSFProTextLight';
`;

export const SwitchContainer = styled.div<TableStatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 40px;
  padding: 8px;
  margin-top: 20px;

  > span {
    font-family: 'FontsFreeNetSFProTextMedium';
    color: ${(props) => (props.tableStatus ? 'var(--greenText)' : '#C7C7CC')};
    margin-left: 10px;
  }

  .ant-switch-checked {
    background-color: ${(props) =>
      props.tableStatus ? 'var(--greenText)' : '#C7C7CC'}!important;
  }
`;
