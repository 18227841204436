import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.4rem;
  color: var(--greenText);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1.2rem;
  color: var(--descripionText);
  text-align: center;
  margin-top: 40px;
`;

export const InputContainer = styled.div`
  > div {
    width: 80%;
    margin: 20px auto;
  }
`;

export const ResendCodeText = styled(Description)`
  margin-top: 8px;
  margin-bottom: 14px;
  font-size: 1rem;
  cursor: pointer;
`;
