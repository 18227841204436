import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { Button } from '../../../../shared/components/Button/Button';

import './modal-responsive.css';

import trashIcon from '../../../../shared/icons/trash-icon.png';
import pauseButton from '../../../../shared/icons/pause-button.png';
import exit from '../../../../shared/icons/exit.png';

import { MenuItemsProps, useFoodMenu } from '../../../../hooks/FoodMenu';

import { Text, Icon, Row, ButtonContainer } from './_DeleteCategoryModal';
import { toast } from 'react-toastify';
import { MenuItemProps } from 'antd';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  categoryId: string;
  handleDeleteCategoryEstablishment(categoryId: string): void;
}

export const DeleteCategoryModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  categoryId,
  handleDeleteCategoryEstablishment,
}) => {
  const { handleDeleteItemMenu, handlePauseItem } = useFoodMenu();

  const history = useHistory();

  const handleDeleteItem = (cateogoryId: string) => {
    handleDeleteCategoryEstablishment(categoryId);
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="handle-item-modal-responsive"
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenModal(false)}
        />
      </Row>
      <Text>Tem certeza que deseja excluir essa categoria?</Text>
      <Icon src={trashIcon} />
      <ButtonContainer>
        <Button
          onClick={() => handleDeleteItem(categoryId)}
          style={{ background: '#21E270' }}
        >
          Confirmar
        </Button>
        <Button
          onClick={() => setOpenModal(false)}
          style={{ background: 'red', marginTop: '0px' }}
        >
          Cancelar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
