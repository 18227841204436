//React
import { useState, useEffect } from 'react';

//Services
import api from '../../services/api';

//Components
import { WrapperPage } from '../../shared/components/WrapperPage/WrapperPage';
import { Spinner } from '../../shared/components/Spinner/Spinner';
import { toast } from 'react-toastify';

//Styles
import { Container } from './_PrivacyPolicies';

export const PrivacyPolicies = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPrivacePolicy();
  }, []);

  const getPrivacePolicy = async () => {
    try {
      setLoading(true);
      const res = await api.get('/privacy-policy');

      setPrivacyPolicyData(res.data.content);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <WrapperPage
      title="Políticas de Privacidade e Termos de Uso"
      showMenu={false}
    >
      {loading ? <Spinner /> : <Container>{privacyPolicyData}</Container>}
    </WrapperPage>
  );
};
