import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleModal = styled.h1`
  color: #21e270;
  font-size: 21px;
  font-weight: bold;
  margin: 10px;
`;

export const NameTitle = styled.p`
  color: #21e270;
  font-size: 22px;
  font-weight: bold;
  margin: 10px;
`;

export const DateTitle = styled.p`
  color: #8e8e93;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
`;

export const TableNumber = styled.p`
  color: #8e8e93;
  font-size: 14px;
  font-family: 'FontsFreeNetSFProTextLight';
`;

export const FooterModal = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px;
  gap: 15px;
`;

export const AcceptModal = styled.button`
  padding: 11px;
  background-color: #21e270;
  border-radius: 32px;
  border: none;
  color: #fff;
`;

export const IconModal = styled.div`
  font-size: 12px;
  color: #21e270;
`;

export const RefuseModal = styled.button`
  background-color: #ff2d55;
  color: #fff;
  border-radius: 32px;
  border: none;
  padding: 11px;
`;

export const ImgAvatar = styled.img`
  width: 103px;
  border-radius: 20px;
`;
