import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';

import socketio from 'socket.io-client';

import menu from '../../icons/menu.png';
import editUser from '../../icons/edit-user.png';
import solicitations from '../../icons/solicitations.png';
import requests from '../../icons/requests.png';
import settings from '../../icons/settings.png';
import logo from '../../icons/logo.png';
import table from '../../icons/table-icon.png';

import { Container, MenuIcon, Items, Logo } from './_SideBar';
import { useAuth } from '../../../hooks/Auth';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useSocketIo } from '../../../hooks/Socket';
interface Props extends HTMLAttributes<HTMLDivElement> {
  showSideBar: boolean;
  setShowSideBar: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
}

export const SideBar = ({ showSideBar, setShowSideBar, showMenu }: Props) => {
  const actualPath = window.location.pathname;

  const { iconsFlags } = useSocketIo();

  const renderMenu = () => {
    if (showMenu) {
      return (
        <Items
          pedingCount={iconsFlags.total_reservation_pending}
          orderCount={iconsFlags.total_order_pending}
        >
          <Link to="/cardapio/cadastro">
            <img
              data-tut="menu-register"
              src={editUser}
              alt="Ícone de usuário"
              className={actualPath.includes('cardapio') ? 'active' : ''}
            />
          </Link>
          <Link
            className={
              iconsFlags.total_reservation_pending > 0
                ? 'solicitation-icon'
                : ''
            }
            to="/solicitacoes"
          >
            <img
              data-tut="solicitations"
              src={solicitations}
              alt="Ícone de solicitações"
              className={actualPath.includes('solicitacoes') ? 'active' : ''}
            />
          </Link>
          <Link to="/gestao-de-mesas">
            <img
              data-tut="table-management"
              src={table}
              alt="Ícone de mesas"
              className={actualPath.includes('mesas') ? 'active' : ''}
            />
          </Link>
          <Link
            className={iconsFlags.total_order_pending > 0 ? 'orders-icon' : ''}
            to="/pedidos"
          >
            <img
              data-tut="requests"
              src={requests}
              alt="Ícone de pedidos"
              className={actualPath.includes('pedidos') ? 'active' : ''}
            />
          </Link>
          <Link to="/configuracoes">
            <img
              data-tut="config"
              src={settings}
              alt="Ícone de configurações"
              className={actualPath.includes('configuracoes') ? 'active' : ''}
            />
          </Link>
        </Items>
      );
    }
  };

  return (
    <Container showSideBar={showSideBar} showMenu={showMenu}>
      <div>
        <MenuIcon
          onClick={() => setShowSideBar(false)}
          src={menu}
          alt="Ícone de menu"
        />
        {renderMenu()}
        <Logo src={logo} alt="Logo do Payment" />
      </div>
    </Container>
  );
};
