import React, { Dispatch, SetStateAction, FormEvent, useState } from 'react';
import Modal from 'react-modal';
import { Input } from '../../../../shared/components/Input/Input';
import { Button } from '../../../../shared/components/Button/Button';

import exit from '../../../../shared/icons/exit.png';

import {
  Title,
  Row,
  Form,
  SaveButton,
  InputContainer,
} from './_AlterPasswordModal';
import {
  CreateNewPassword,
  useFinalizeRegistration,
} from '../../../../hooks/FinalizeRegistration';
import { toast } from 'react-toastify';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
}

export const AlterPasswordModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  setOpenSuccessModal,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: 'fit-content',
      width: '263px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setOpenModal(false);
  }

  const { changePassword } = useFinalizeRegistration();

  const [newPassword, setNewPassword] = useState({} as CreateNewPassword);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewPassword({ ...newPassword, [name]: value });
  };

  const sendChangePassword = async () => {
    if (!newPassword.oldPassword) {
      toast.error('O campo senha atual é obrigatório');
      return;
    }

    if (!newPassword.password) {
      toast.error('O campo nova senha é obrigatório.');
      return;
    }

    if (!newPassword.passwordConfirmation) {
      toast.error('O campo confirmar senha é obrigatório.');
      return;
    }

    try {
      const sendNewPass = await changePassword(newPassword);
      setOpenSuccessModal(true);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={customStyles}
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenModal(false)}
        />
      </Row>

      <Form onSubmit={handleSubmit}>
        <Title>Alterar senha</Title>
        Senha Atual:
        <InputContainer>
          <Input
            name="oldPassword"
            type="password"
            onChange={(e) => handleChangePassword(e)}
          />
        </InputContainer>
        Nova Senha:
        <InputContainer>
          <Input
            name="password"
            type="password"
            onChange={(e) => handleChangePassword(e)}
          />
        </InputContainer>
        Confirmar Senha:
        <InputContainer>
          <Input
            name="passwordConfirmation"
            type="password"
            onChange={(e) => handleChangePassword(e)}
          />
        </InputContainer>
        <SaveButton>
          <Button
            onClick={sendChangePassword}
            style={{ background: '#21E270', color: '#fff' }}
          >
            Salvar alterações
          </Button>
        </SaveButton>
      </Form>
    </Modal>
  );
};
