import { useTableManagement } from '../../hooks/TableManagement';
import React, { useState } from 'react';
import { WrapperPage } from '../../shared/components/WrapperPage/WrapperPage';
import AddTables from './components/AddTables/AddTables';
import DeleteTables from './components/DeleteTables/DeleteTables';
import TablesPreview from './components/TablesPreview/TablesPreview';
import { Content, Wrapper } from './_TablesManagement';
import { NotificationsModal } from '../../shared/components/NotificationsModal/NotificationsModal';

const TablesManagement = () => {
  const [tableNumber, setTableNumber] = useState(0);
  const [selectedToDelete, setSelectedToDelete] = useState<string[]>([]);

  return (
    <WrapperPage title="Gerenciamento de mesas">
      <Content>
        <Wrapper>
          <AddTables
            setTableNumber={setTableNumber}
            tableNumber={tableNumber}
            selectedToDelete={selectedToDelete}
            setSelectedToDelete={setSelectedToDelete}
          />
          <DeleteTables
            selectedToDelete={selectedToDelete}
            setSelectedToDelete={setSelectedToDelete}
          />
        </Wrapper>
        <TablesPreview
          tableNumber={tableNumber}
          selectedToDelete={selectedToDelete}
          setSelectedToDelete={setSelectedToDelete}
        />
      </Content>
      <NotificationsModal />
    </WrapperPage>
  );
};

export default TablesManagement;
