import { useHistory } from 'react-router-dom';

import potato from '../../../../../../shared/icons/potato.png';
import deleteMenu from '../../../../../../shared/icons/delete-menu.png';
import pauseMenu from '../../../../../../shared/icons/pause-menu.png';
import editMenu from '../../../../../../shared/icons/edit-menu.png';

import { Container, Header, Footer, Price } from './_Card';

import { MenuItemsProps, useFoodMenu } from '../../../../../../hooks/FoodMenu';

import { deflate } from 'zlib';
import { useState } from 'react';
import { Spinner } from 'shared/components/Spinner/Spinner';

import { moneyMask } from '../../../../../../utils/Masks';

interface Props {
  setShowHandleItemModal: (show: boolean) => void;
  setActionItem: (action: string) => void;
  setActiveStatus: (action: boolean) => void;
  data: MenuItemsProps;
  setItemID: (action: string) => void;
  isItemInPause: boolean;
  loading: boolean;
}

export const Card = ({
  setShowHandleItemModal,
  setActionItem,
  setItemID,
  setActiveStatus,
  isItemInPause,
  loading,
  data,
}: Props) => {
  const history = useHistory();

  const handleClickPauseButton = (id: string, isActive: boolean) => {
    setActionItem('pause');
    setShowHandleItemModal(true);
    setItemID(id);
    setActiveStatus(isActive);
  };

  const setStockText = () => {
    if (data.available_quantity > 1) {
      return `${data.available_quantity} unidades`;
    } else if (data.available_quantity == 0) {
      return `Sem estoque`;
    } else {
      return `${data.available_quantity} unidade`;
    }
  };

  const handleClickDeleteButton = (id: string) => {
    setActionItem('delete');
    setShowHandleItemModal(true);
    setItemID(id);
  };

  const renderStatus = () => {
    if (data.is_active) {
      return (
        <>
          <button
            id={`status-${data.is_active}`}
            style={{ color: '#C7C7CC' }}
            onClick={() => handleClickPauseButton(data.id, data.is_active)}
          >
            <img src={pauseMenu} alt="Ícone de pausar item do cardápio" />
            Pausar
          </button>
        </>
      );
    } else if (!data.is_active) {
      return (
        <>
          <button
            id={`status-${data.is_active}`}
            style={{ color: '#FFCC00' }}
            onClick={() => handleClickPauseButton(data.id, data.is_active)}
          >
            <img src={pauseMenu} alt="Ícone de pausar item do cardápio" />
            Pausado
          </button>
        </>
      );
    }
  };

  return (
    <Container>
      <Header>
        {loading ? (
          <Spinner />
        ) : (
          <img src={data.image_url} alt="Ícone da comida do cardápio" />
        )}
        <h4>{data.name}</h4>
        <p>Tempo de Preparo: {data.time_in_minutes}</p>
        <p>Estoque: {setStockText()}</p>
      </Header>
      <Footer>
        <Price>
          <div>
            <p>Valor:</p>
            <p>R$ {moneyMask(data.price)}</p>
          </div>
        </Price>
        <div>
          <div>
            <button onClick={() => handleClickDeleteButton(data.id)}>
              <img src={deleteMenu} alt="Ícone de deletar item do cardápio" />
              Excluir
            </button>
          </div>
          <div>{renderStatus()}</div>
          <div>
            <button
              onClick={() =>
                history.push(
                  `/cardapio/${data.establishment_id}/item/${data.id}`,
                )
              }
            >
              <img src={editMenu} alt="Ícone de editar item do cardápio" />
              Editar
            </button>
          </div>
        </div>
      </Footer>
    </Container>
  );
};
