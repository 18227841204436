import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '../../../../services/api';

import { WrapperPage } from '../../../../shared/components/WrapperPage/WrapperPage';
import { Button } from '../../../../shared/components/Button/Button';
import { toast } from 'react-toastify';

import {
  Title,
  Row,
  FormContainer,
  ListButtons,
  Divider,
  ButtonGreen,
} from './_TermsOfUseAndPrivacy';
import { NotificationsModal } from '../../../../shared/components/NotificationsModal/NotificationsModal';

export const TermsOfUseAndPrivacy = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPrivacePolicy();
  }, []);

  const getPrivacePolicy = async () => {
    try {
      setLoading(true);
      const res = await api.get('/privacy-policy');

      setPrivacyPolicyData(res.data.content);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <WrapperPage title="Configurações">
      <Title>Termos de Uso e Política de Privacidade</Title>
      <Row>
        <FormContainer>
          <textarea value={privacyPolicyData} disabled />
        </FormContainer>
        <ListButtons>
          <Button>
            <Link to="/configuracoes">Dados Pessoais</Link>
          </Button>
          <Button>
            <Link to="/configuracoes/perfil-do-estabelecimento">
              Estabelecimento
            </Link>
          </Button>
          <Button>
            <Link to="/configuracoes/dados-bancarios">Dados Bancários</Link>
          </Button>
          <ButtonGreen>Termos de Uso</ButtonGreen>
          <Divider />
        </ListButtons>
      </Row>
      <NotificationsModal />
    </WrapperPage>
  );
};
