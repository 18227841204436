import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { Modal } from 'antd';
import { Button } from 'shared/components/Button/Button';
import { useTableManagement } from '../../../../hooks/TableManagement';
import { LoadingOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import {
  SelectedTable,
  Title,
  WrapperModal,
  SwitchContainer,
} from './_ChangeTableInfosModal';
import { Input } from 'shared/components/Input/Input';
import { ReactComponent as TableEditIcons } from '../../../../shared/icons/edit-table-details.svg';

import './modal-responsive.css';
import { toast } from 'react-toastify';

interface ITableInfos {
  amountChairs: string;
  name: string;
  isAvailable: boolean;
}

interface TableInfosProps {
  setTableModalInfos: Dispatch<SetStateAction<boolean>>;
  tableModalInfos: boolean;
  nameOfTable: string;
  isTableAvaliable: boolean;
  setIsTableAvaliable: Dispatch<SetStateAction<boolean>>;
  setAmountOfChair: Dispatch<SetStateAction<number>>;
  amountOfChair: number;
  tableId: string;
}

const ChangeTableInfosModal = ({
  setTableModalInfos,
  tableModalInfos,
  nameOfTable,
  isTableAvaliable,
  setAmountOfChair,
  amountOfChair,
  tableId,
  setIsTableAvaliable,
}: TableInfosProps) => {
  useEffect(() => {
    setTablesInfos({
      amountChairs: amountOfChair.toString(),
      isAvailable: isTableAvaliable,
    });
  }, []);

  const handleCancel = () => {
    setTableModalInfos(false);
    Modal.destroyAll();
  };

  const { updateTableInfos, getEstablishmentTables } = useTableManagement();

  const [loading, setLoading] = useState(false);
  const [tableInfos, setTablesInfos] = useState({
    amountChairs: '',
    isAvailable: false,
  });

  const handleOnChangeStatusEstablichment = (value: boolean) => {
    setIsTableAvaliable(value);
    setTablesInfos({ ...tableInfos, isAvailable: value });
  };

  const changeNumberOfChairs = (e: React.FormEvent<HTMLInputElement>) => {
    setAmountOfChair(Number(e.currentTarget.value));
    setTablesInfos({ ...tableInfos, amountChairs: e.currentTarget.value });
  };

  async function changeFullTableInfos() {
    setLoading(true);

    try {
      await updateTableInfos(tableId, tableInfos, nameOfTable);
      await getEstablishmentTables();
      setTableModalInfos(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  }

  const renderTableStatus = () => {
    if (isTableAvaliable) {
      return 'Disponível';
    }

    return 'Reservado (Indisponível)';
  };

  return (
    <Modal
      visible={tableModalInfos}
      footer={null}
      title={''}
      onCancel={handleCancel}
    >
      <SelectedTable>
        {nameOfTable}
        <TableEditIcons />
      </SelectedTable>
      <WrapperModal>
        <Title>Alterar quantidade de cadeiras:</Title>
        <Input
          name="amountChairs"
          value={String(amountOfChair)}
          onChange={(e) => changeNumberOfChairs(e)}
        />
        <SwitchContainer tableStatus={isTableAvaliable}>
          <Switch
            defaultChecked={isTableAvaliable}
            onChange={(value) => handleOnChangeStatusEstablichment(value)}
          />
          <span>{renderTableStatus()}</span>
        </SwitchContainer>
        <Button onClick={() => changeFullTableInfos()}>
          {loading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : 'Ok'}
        </Button>
      </WrapperModal>
    </Modal>
  );
};

export default ChangeTableInfosModal;
