import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 256px;
  height: fit-content;
  margin: 20px;
  border-radius: 10%;
  box-shadow: lightgrey 0px 2px 8px -3px;
  padding-bottom: 20px;

  button {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  @media (max-width: 460px) {
    margin: 0px 0px 42px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background: #f0f0f3;
  border-radius: 10%;

  > img {
    margin-top: 10px;
    border-radius: 14px;
    max-width: 147px;
  }

  > h4 {
    font-family: 'FontsFreeNetSFProTextBold';
    color: var(--greenText);
    font-size: 20px;
    overflow-wrap: anywhere;
    width: 80%;
    text-align: center;
  }

  p:nth-of-type(1) {
    font-family: 'FontsFreeNetSFProTextBold';
    color: #707070;
    font-size: 13px;
    margin: 5px 0 5px 0;
  }

  p:nth-of-type(2) {
    font-family: 'FontsFreeNetSFProTextBold';
    color: lightgrey;
    font-size: 14px;
    margin-bottom: 25px;
  }
`;

export const Price = styled.div`
  margin-bottom: 15px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    min-width: 100px;
    padding: 10px;
    border-radius: 9px;
    margin-top: -15px;

    > p:nth-of-type(1) {
      font-family: 'FontsFreeNetSFProTextLight';
      font-size: 0.8rem;
      color: #363e55;
    }

    > p:nth-of-type(2) {
      font-family: 'FontsFreeNetSFProTextBold';
      color: #21e270;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 0 0 40% 40%;
  > div {
    display: flex;
    justify-content: space-around;
    > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      button {
        border: 0;
        background: #fff;
        color: lightgrey;
        font-family: 'FontsFreeNetSFProTextBold';
        cursor: pointer;
        outline: transparent;
      }
    }
  }
`;
