import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { WrapperPage } from '../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../shared/components/Input/Input';
import { Button } from '../../shared/components/Button/Button';
import { Select } from './components/Select/Select';
import { SuccessModal } from '../../shared/components/SuccessModal/SuccesModal';
import { SendFileModal } from '../../shared/components/SendFileModal/SendFileModal';
import { AlterPasswordModal } from './components/AlterPasswordModal/AlterPasswordModal';

import { toast } from 'react-toastify';

import { useFoodMenu } from '../../hooks/FoodMenu';

import editIcon from '../../shared/icons/editIcon.png';
import fileIcon from '../../shared/icons/file-icon.png';
import reduTutorialIcon from '../../shared/icons/redo-tutorial.png';

import { NotificationsModal } from '../../shared/components/NotificationsModal/NotificationsModal';

import {
  Title,
  Row,
  RowIcons,
  FormContainer,
  Form,
  InputContainer,
  ListButtons,
  SelectContainer,
  Divider,
  ContainerRowIcons,
  ButtonGreen,
} from './_Settings';
import {
  CreateFullName,
  useFinalizeRegistration,
} from '../../hooks/FinalizeRegistration';
import { useAuth } from '../../hooks/Auth';

export const Settings = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [partnerDocument, setPartnerDocument] = useState<File | undefined>();
  const [showSendFileModal, setShowSendFileModal] = useState(false);
  const [showAlterPasswordModal, setShowAlterPasswordModal] = useState(false);
  const [newFullName, setNewFullName] = useState({} as CreateFullName);
  const [modalCheckinOpen, setModalCheckinOpen] = useState(false);
  const { handleTutorialDone } = useFoodMenu();
  const { changeFullName } = useFinalizeRegistration();

  const { data } = useAuth();

  const handleNewFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewFullName({ ...newFullName, [name]: value });
  };

  const sendNewName = async () => {
    if (!newFullName.firstName) {
      toast.error('O campo nome é obrigatório,');
      return;
    }

    if (!newFullName.lastName) {
      toast.error('O campo sobrenome é obrigatório.');
      return;
    }

    try {
      const sendName = await changeFullName(newFullName);
      setShowSuccessModal(true);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const history = useHistory();

  const identificationOptions = [
    { id: '1', code: '1', description: 'RG e CPF' },
    { id: '2', code: '2', description: 'CNH' },
  ];

  const handleUpdateData = () => {
    sendNewName();
  };

  const handleTutorial = async () => {
    try {
      handleTutorialDone(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      history.push('/cardapios');
    }
  };

  const renderPartnerDocumentName = () => {
    if (partnerDocument?.name) {
      return partnerDocument.name;
    }

    return 'Anexar Documento';
  };

  return (
    <WrapperPage title="Configurações">
      <Title>Dados do Responsável</Title>
      <Row>
        <FormContainer>
          <Form>
            <label>Nome completo:</label>
            <InputContainer>
              <Input
                placeholder={data.user.first_name}
                name="firstName"
                iconRight={editIcon}
                onChange={(e) => handleNewFullName(e)}
              />
              <Input
                placeholder={data.user.last_name}
                name="lastName"
                iconRight={editIcon}
                onChange={(e) => handleNewFullName(e)}
              />
            </InputContainer>
            <SelectContainer>
              <Select
                defaultValue={identificationOptions[0].code}
                data={identificationOptions}
              />
            </SelectContainer>
            <Button
              iconLeft={fileIcon}
              type="button"
              onClick={() => setShowSendFileModal(true)}
            >
              {renderPartnerDocumentName()}
            </Button>
            <label>Telefone Celular</label>
            <InputContainer>
              <Input placeholder="(12) 98703-0735" iconRight={editIcon} />
            </InputContainer>

            <ContainerRowIcons>
              <RowIcons onClick={() => setShowAlterPasswordModal(true)}>
                <img src={editIcon} alt="Ícone de alterar senha" />
                <label>Alterar senha</label>
              </RowIcons>

              <RowIcons>
                <img src={reduTutorialIcon} alt="Ícone de alterar senha" />
                <label onClick={() => handleTutorial()}>Refazer Tutorial</label>
              </RowIcons>
            </ContainerRowIcons>
          </Form>
        </FormContainer>
        <ListButtons>
          <ButtonGreen>Dados Pessoais</ButtonGreen>
          <Button>
            <Link to="/configuracoes/perfil-do-estabelecimento">
              Estabelecimento
            </Link>
          </Button>
          <Button>
            <Link to="/configuracoes/dados-bancarios">Dados Bancários</Link>
          </Button>
          <Button>
            <Link to="/configuracoes/termos-de-uso-e-privacidade">
              Termos de Uso
            </Link>
          </Button>
          <Divider />
          <ButtonGreen onClick={() => handleUpdateData()}>
            Salvar alterações
          </ButtonGreen>
        </ListButtons>
      </Row>
      <SuccessModal
        openModal={showSuccessModal}
        setOpenModal={setShowSuccessModal}
        text="Alterações salvas com sucesso!"
      />
      <AlterPasswordModal
        openModal={showAlterPasswordModal}
        setOpenModal={setShowAlterPasswordModal}
        setOpenSuccessModal={setShowSuccessModal}
      />
      <SendFileModal
        openModal={showSendFileModal}
        setOpenModal={setShowSendFileModal}
        setFile={setPartnerDocument}
      />
      <NotificationsModal />
    </WrapperPage>
  );
};
