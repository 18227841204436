import styled from 'styled-components';

export const Text = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.2rem;
  color: var(--greenText);
  text-align: center;
`;

export const Icon = styled.img`
  height: 90px;
  display: block;
  margin-top: 20px;
  margin: 20px auto 0px auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;

  > img {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    width: 210px;
    height: 35px;
    color: #fff;
    font-size: 1rem;
  }
`;
