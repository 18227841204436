import { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import './modal-responsive.css';

import { Button } from '../../../shared/components/Button/Button';
import { SuccessModal } from '../../../shared/components/SuccessModal/SuccesModal';

import uploadFileIcon from '../../../shared/icons/upload-file-icon.png';
import exit from '../../icons/exit.png';

import {
  Title,
  Description,
  SubTitle,
  Icon,
  Row,
  ContainerUpload,
} from './_SendFileModal';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setFile: Dispatch<SetStateAction<File | undefined>>;
}

export const SendFileModal = ({ openModal, setOpenModal, setFile }: Props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileDocument = e.target.files;

    if (fileDocument && fileDocument[0].name) {
      if (fileDocument[0].size > 3145728) {
        toast.error('O arquivo deve ter no máximo 3MB');
      } else {
        setFile(fileDocument[0]);
        setOpenModal(false);
        setShowSuccessModal(true);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
        className="send-file-modal-responsive"
      >
        <Row>
          <img
            src={exit}
            alt="Ícone de sair"
            onClick={() => setOpenModal(false)}
          />
        </Row>
        <Title>Anexe seu documento</Title>
        <SubTitle>Documentos em Frente e Verso</SubTitle>
        <Description>
          São aceitos os fomatos: JPEG e PDF com o máximo de 3MB
        </Description>
        <Icon src={uploadFileIcon} alt="Ícone de anexar documento" />
        <ContainerUpload>
          <Button
            style={{
              width: '100%',
              height: '40px',
              color: '#fff',
              backgroundColor: 'var(--greenText)',
            }}
          >
            <label htmlFor="upload-document">
              <input
                id="upload-document"
                accept="*"
                type="file"
                onChange={(e) => handleDocument(e)}
              />
              Anexar Documento
            </label>
          </Button>
        </ContainerUpload>
      </Modal>
      <SuccessModal
        openModal={showSuccessModal}
        setOpenModal={setShowSuccessModal}
        text="Documento anexado com sucesso!"
      />
    </>
  );
};
