import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import { Container, InputField, IconLeft, IconRight } from './_Input';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  iconLeft?: string;
  iconRight?: string;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export const Input = ({
  iconLeft,
  iconRight,
  containerProps,
  inputProps,
  ...props
}: Props) => {
  const renderIconLeft = () => {
    if (iconLeft) {
      return <IconLeft src={iconLeft} />;
    }
  };

  const renderIconRight = () => {
    if (iconRight) {
      return <IconRight src={iconRight} />;
    }
  };

  return (
    <Container {...containerProps}>
      {renderIconLeft()}
      <InputField {...inputProps} {...props} />
      {renderIconRight()}
    </Container>
  );
};
