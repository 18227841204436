import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { useCreateAccount } from '../../hooks/CreateAccount';
import {
  cpfMask,
  removeCpfMask,
  phoneNumberMask,
  removePhoneNumberMask,
} from '../../utils/Masks';
import { cpfValidation } from '../../utils/Validations';

import { Input } from '../../shared/components/Input/Input';

import loginLogo from '../../shared/icons/login-logo.png';
import loginAnimation from '../../shared/icons/login-animation.png';

import {
  Container,
  FormContainer,
  Form,
  ImageContainer,
  Description,
  InputContainer,
  Next,
} from './_SignUp';

export const SignUp = () => {
  const { data, setData } = useCreateAccount();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFormFiels = () => {
    if (!data.firstName.trim()) {
      toast.error('Preencha seu nome corretamente');
      return false;
    }

    if (!data.lastName.trim()) {
      toast.error('Preencha seu sobrenome corretamente');
      return false;
    }

    if (!cpfValidation(data.cpf)) {
      toast.error('Preencha seu CPF corretamente');
      return;
    }

    if (data.phone.length < 11) {
      toast.error('Preencha seu celular corretamente');
      return;
    }

    return true;
  };

  const handleNextClick = () => {
    if (validateFormFiels()) {
      history.push('/confirmar-senha');
    }
  };

  return (
    <Container>
      <FormContainer>
        <Form>
          <img src={loginLogo} alt="Login do Payment" />
          <h1>Cadastre-se</h1>
          <Description>Informe os dados do responsável:</Description>
          <InputContainer>
            <Input
              placeholder="Nome"
              value={data.firstName}
              onChange={(event) =>
                setData({ ...data, firstName: event.target.value })
              }
            />
          </InputContainer>
          <InputContainer>
            <Input
              placeholder="Sobrenome"
              value={data.lastName}
              onChange={(event) =>
                setData({ ...data, lastName: event.target.value })
              }
            />
          </InputContainer>
          <InputContainer>
            <Input
              placeholder="CPF"
              value={cpfMask(data.cpf)}
              onChange={(event) =>
                setData({
                  ...data,
                  cpf: removeCpfMask(event.target.value),
                })
              }
            />
          </InputContainer>
          <InputContainer>
            <Input
              placeholder="Celular"
              value={phoneNumberMask(data.phone)}
              onChange={(event) =>
                setData({
                  ...data,
                  phone: removePhoneNumberMask(event.target.value),
                })
              }
            />
          </InputContainer>

          <Next onClick={() => handleNextClick()}>
            Próximo <FaChevronRight />
          </Next>
        </Form>
      </FormContainer>
      <ImageContainer>
        <img src={loginAnimation} alt="Animação da tela de login" />
      </ImageContainer>
    </Container>
  );
};
