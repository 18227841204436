import React, {
  useState,
  useEffect,
  useMemo,
  SetStateAction,
  Dispatch,
} from 'react';
import socketio from 'socket.io-client';

import { ReactComponent as TableIcon } from '../../../shared/icons/table-icon-modal-checkin.svg';

import { useAuth } from '../../../hooks/Auth';

import ModalCheckin from './ModalCheckin/ModalCheckin';
import ModalReview from './ModalReview/ModalReview';
import ModalResume from './ModalResume/ModalResume';
import { useSocketIo } from '../../../hooks/Socket';

export const NotificationsModal = () => {
  const {
    notificationsCheckin,
    notificationsResume,
    notificationsReview,
    setNotificationsCheckin,
    setNotificationsResume,
    setNotificationsReview,
    setModalCheckinOpen,
    setModalResumeOpen,
    setModalReviewOpen,
    modalCheckinOpen,
    modalResumeOpen,
    modalReviewOpen,
  } = useSocketIo();
  const { data, setData } = useAuth();

  return (
    <>
      <ModalReview
        setModalReviewOpen={setModalReviewOpen}
        modalReviewOpen={modalReviewOpen}
        notifications={notificationsReview}
      />
      <ModalResume
        setModalResumeOpen={setModalResumeOpen}
        modalResumeOpen={modalResumeOpen}
        notifications={notificationsResume}
      />
      <ModalCheckin
        setModalCheckinOpen={setModalCheckinOpen}
        modalCheckinOpen={modalCheckinOpen}
        notifications={notificationsCheckin}
      />
    </>
  );
};
