import styled from 'styled-components';

interface StatusProps {
  isFinished: boolean;
}

export const Title = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.2rem;
  color: var(--greenText);
  text-align: center;
`;

export const Icon = styled.img`
  height: 90px;
  display: block;
  margin-top: 20px;
  margin: 20px auto 0px auto;
`;

export const IconCloseModal = styled.img`
  position: absolute;
  right: 20px;
  top: 0px;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #707070;
  margin: 20px 0px;
`;

export const Wrapper = styled.section`
  max-height: 70vh;
  overflow: auto;
`;

export const SelectedUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > img {
    max-width: 100px;
  }

  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

export const SelectedUserInformations = styled.div`
  margin-left: 20px;

  @media (max-width: 520px) {
    margin-left: 0px;
    text-align: center;
  }
`;

export const Name = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.2rem;
  color: var(--greenText);

  > span {
    color: #ffcc00;
    margin-left: 6px;
    font-size: 16px;
  }
`;

export const Scheduling = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 0.8rem;
  color: var(--descripionText);
  margin: 10px 0px;
`;

export const TableNumber = styled.div`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 0.8rem;
  color: var(--descripionText);
`;

export const GuestsContainer = styled.div`
  padding: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 20px;
  margin: 8px;
  max-width: 90%;

  @media (max-width: 520px) {
    margin: 8px auto;
  }
`;

export const NumberGuests = styled(Name)`
  margin-bottom: 20px;

  > span {
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 1.2rem;
    color: #9f9fa3;
  }

  @media (max-width: 520px) {
    text-align: center;
  }
`;

export const Guest = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }

  > div {
    margin: 0px 10px;
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1rem;
    color: #9f9fa3;
    cursor: pointer;

    > span {
      color: #ffcc00;
      margin-left: 6px;
      font-size: 16px;
    }
  }

  @media (max-width: 520px) {
    flex-direction: column;
    margin: 10px 0px;
  }
`;

export const Status = styled.span<StatusProps>`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 0.8rem;
  color: ${(props) => (props.isFinished ? '#21e270' : '#A5A5A9')};
`;

export const OrderSummaryContainer = styled.div`
  @media (max-width: 520px) {
    button {
      margin: 40px auto !important;
      width: 90%;
    }
  }
`;

export const TitleOrderSummary = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1rem;
  color: var(--greenText);
  margin: 40px 0px;

  > span {
    color: var(--descripionText);
  }

  @media (max-width: 520px) {
    text-align: center;
  }
`;

export const WrapperOrderSumamary = styled.div`
  padding: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 20px;
  margin: 8px;
  max-width: 90%;
`;

export const InformationOrder = styled.div`
  > div:nth-of-type(1) {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: var(--descripionText);

    > span {
      color: var(--greenText);
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 0.7rem;
    color: var(--medium-gray);
    margin-top: 8px;

    > img {
      margin-right: 4px;
    }
  }

  @media (max-width: 520px) {
    text-align: center;
    > div:nth-of-type(2) {
      justify-content: center;
    }
  }
`;

export const DescriptionOrder = styled.div`
  > div {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: var(--greenText);
    margin: 20px 0px 10px 0px;
  }

  > ul {
    li {
      display: flex;
      justify-content: space-between;
      font-size: 0.7rem;
      color: var(--descripionText);
      margin: 4px 0px;

      > div:nth-of-type(1) {
        font-family: 'FontsFreeNetSFProTextMedium';
      }

      > div:nth-of-type(2) {
        font-family: 'FontsFreeNetSFProTextBold';
        span {
          font-family: 'FontsFreeNetSFProTextMedium';
        }
      }
    }
  }

  @media (max-width: 520px) {
    > div {
      text-align: center;
    }

    > ul {
      li {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
      }
    }
  }
`;

export const TotalToPay = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  color: var(--descripionText);
  margin-top: 20px;

  > span {
    color: var(--greenText);
  }

  @media (max-width: 520px) {
    text-align: center;
  }
`;

export const TotalOrderValue = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  color: var(--descripionText);
  margin-top: 20px;
  padding-top: 20px;

  > span {
    color: var(--greenText);
  }

  @media (max-width: 520px) {
    text-align: center;
  }
`;
