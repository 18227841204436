import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  background-color: var(--greenText);
  min-height: 100vh;
`
export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (max-width: 1120px){
    width: 100%;
    padding: 20px 0px;
  }
`;

export const Form = styled.form`
  width: fit-content;

  > img {
    display: block;
    margin: 0 auto;
  }

  > h1 {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 2rem;
    color: #fff;
    margin-top: 40px;
  }

  > button {
    width: 100%;
  }

  @media (max-width: 400px){
    width: 90%;

    > h1 {
      text-align: center;
    }
  }
`;

export const AccessYourAccount = styled.div`
  color: #fff;
  margin-top: 40px;
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1.2rem;
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 342px) {
    flex-direction: column;
    align-items: center;
  }
`;
