import React, { useState, useEffect, SetStateAction } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { RequestProps } from '../../RequestManagement';

import { moneyMask } from '../../../../utils/Masks';

import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './modal-cancel-order.css';

import clock from 'shared/icons/clock_icon.png';
import check from 'shared/icons/white_check_icon.png';
import blueCheck from 'shared/icons/blue_check_icon.png';
import refuse from 'shared/icons/refuse_icon.png';
import shop from 'shared/icons/shop_icon.png';
import request from 'shared/icons/request_icon.png';
import finished_request from 'shared/icons/filled_request_icon.png';
import time from 'shared/icons/time_icon.png';
import finished from 'shared/icons/filled_time_icon.png';
import customer from 'shared/img/customer_photo.png';
import emptyStar from 'shared/icons/empty_star_icon.png';
import star from 'shared/icons/filled_star_icon.png';
import PaidApp from 'shared/icons/paid-app.png';

import {
  Order,
  OrderNumber,
  Row,
  OrderContent,
  OrderDescription,
  Column,
  ContainerItens,
  Total,
  MainOrderList,
  SingleOrderList,
  FinishButton,
  ButtonsWrapper,
  OrderStatus,
  TimeInfo,
  StatusFeedback,
  CircleIcon,
  Feedback,
  CustomerSection,
  Customer,
  Evaluation,
  Stars,
  AcceptButton,
  RefuseButton,
  WarningTitle,
  WarningSubtitle,
  WarningContent,
  WarningYes,
  WarningNo,
  AcceptIcon,
  RefuseIcon,
  DateCheckin,
  ObservationContainer,
  PaidForTheApp,
} from './_Orders';
import { useFoodMenu } from '../../../../hooks/FoodMenu';
import { toast } from 'react-toastify';
import { SuccessModal } from 'shared/components/SuccessModal/SuccesModal';

interface Props {
  data: RequestProps;
  handleRequest(
    id: string,
    status: string,
    paymentCash?: boolean,
    paymentCashData?: {
      reservationId: string;
      customerId: string;
    },
  ): Promise<void>;
}

export const Orders = ({ data, handleRequest }: Props) => {
  const { handleCancelingOrder } = useFoodMenu();

  const [loading, setLoading] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [orderId, setOrderId] = useState('');

  function handleOrderToCancel(id: string) {
    setOrderId(id);
    setWarningModal(true);
  }

  async function handleCancelOrder(id: string) {
    setLoading(true);
    try {
      await handleCancelingOrder(id);
      setWarningModal(false);
      setSuccessModalOpen(true);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  }

  const renderButtons = () => {
    const { status, id, establishment, reservation } = data;

    if (!establishment.have_tables) {
      if (status === 'paid' && reservation.status === 'accept') {
        return (
          <FinishButton onClick={() => handleRequest(id, 'finished')}>
            <img src={blueCheck} alt="ícone finalizar" />
            Finalizar pedido
          </FinishButton>
        );
      }

      if (status === 'paid' && reservation.status === 'finished') {
        return (
          <AcceptButton style={{ alignSelf: 'flex-end', cursor: 'auto' }}>
            Pedido finalizado
          </AcceptButton>
        );
      }
    }

    if (status === 'pending') {
      return (
        <>
          <AcceptButton
            onClick={() => {
              const paymentCash = {
                reservationId: data.reservation_id,
                customerId: data.user_id,
              };

              if (!data.reservation.table) {
                handleRequest(id, 'paid', true, paymentCash);
              } else {
                handleRequest(id, 'accept');
              }
            }}
          >
            <img src={check} alt="ícone aceitar" />
            {!data.reservation.table
              ? 'Confirmar Recebimento do Pagamento'
              : 'Aceitar pedido'}
          </AcceptButton>
          <RefuseButton onClick={() => handleRequest(id, 'refuse')}>
            <img src={refuse} alt="ícone recusar" /> Recusar pedido
          </RefuseButton>
        </>
      );
    }

    if (status === 'accept') {
      return (
        <>
          <RefuseButton
            onClick={() => handleOrderToCancel(id)}
            style={{ alignSelf: 'flex-end', cursor: 'auto' }}
          >
            Cancelar pedido
          </RefuseButton>
          <FinishButton onClick={() => handleRequest(id, 'finished')}>
            <img src={blueCheck} alt="ícone finalizar" />
            Finalizar pedido
          </FinishButton>
        </>
      );
    }

    if (status === 'refuse') {
      return (
        <RefuseButton style={{ alignSelf: 'flex-end', cursor: 'auto' }}>
          Pedido recusado
        </RefuseButton>
      );
    }

    if (status === 'finished') {
      return (
        <>
          <AcceptButton style={{ alignSelf: 'flex-end', cursor: 'auto' }}>
            Pedido finalizado
          </AcceptButton>
        </>
      );
    }

    if (status === 'paid') {
      return (
        <AcceptButton style={{ alignSelf: 'flex-end', cursor: 'auto' }}>
          Pedido pago
        </AcceptButton>
      );
    }
  };

  const renderDeliveryForecast = () => {
    const date = new Date(data.closing_time);
    return date.toLocaleTimeString().substring(0, 5);
  };

  const renderDateCreateRequest = () => {
    const date = new Date(data.created_at);
    return date.toLocaleDateString();
  };

  const renderListProducts = () => {
    let index = 0;
    return data.order_items.map((order) => {
      index = index + 1;
      return (
        <li>
          <span>{order.quantity}</span> - {order.product.name}
        </li>
      );
    });
  };

  const renderListProductsWithPrice = () => {
    return data.order_items.map((order) => {
      let orderItems = [];
      for (let i = 0; i < order.quantity; i++) {
        orderItems.push(
          <li>
            {order.product.name}: <span>R$ {moneyMask(order.item_price)}</span>
          </li>,
        );
      }

      return orderItems;

      // return (
      //   <li>
      //     {order.product.name} <span>R$ {moneyMask(order.item_price)}</span>
      //   </li>
      // );
    });
  };

  const renderDateCheckIn = () => {
    if (data.reservation.date_checkin) {
      const date = new Date(data.reservation.date_checkin);
      return (
        <DateCheckin>
          Chegada em: {date.toLocaleDateString()} às{' '}
          {date.toLocaleTimeString().substring(0, 5)}
        </DateCheckin>
      );
    }
  };

  const renderTableNumber = () => {
    if (data.reservation.table) {
      return <b>Mesa número: {data.reservation.table.name}</b>;
    }
  };

  const renderObservationContainer = () => {
    if (data.description) {
      return (
        <ObservationContainer>
          <div>Observação:</div>
          <p>{data.description}</p>
        </ObservationContainer>
      );
    }
  };

  return (
    <Order>
      <OrderNumber>
        <small>
          Nº do Pedido: <b>{data.order_number}</b>
        </small>
        <span>
          <img src={clock} /> {renderDateCreateRequest()}
        </span>
      </OrderNumber>
      <Row>
        <OrderContent>
          {(data.form_of_payment === 'credit_card' || data.form_of_payment === 'pix') && (
            <PaidForTheApp>
              <img src={PaidApp} alt="Ícone de pedido pago pelo app" />
              <div>Pago pelo App</div>
            </PaidForTheApp>
          )}

          <OrderDescription>
            <Column>
              <h3>Descrição</h3>
              <ContainerItens>
                <MainOrderList>{renderListProducts()}</MainOrderList>
                <SingleOrderList>
                  {renderListProductsWithPrice()}
                </SingleOrderList>
              </ContainerItens>
              <div />
              {renderObservationContainer()}
              <Total>
                Total: <b>R$ {moneyMask(data.final_price)}</b>
              </Total>
            </Column>
            <ButtonsWrapper>{renderButtons()}</ButtonsWrapper>
          </OrderDescription>
          <OrderStatus>
            <TimeInfo>
              <b>Informações</b>
              <span>Previsão de entrega: {renderDeliveryForecast()}</span>
            </TimeInfo>
            <StatusFeedback
              inProgress={false}
              finished={false}
              status={data.status}
            >
              <Feedback>
                <CircleIcon>
                  <img src={shop} alt="icone loja" />
                </CircleIcon>
                <small>Recebido</small>
              </Feedback>
              <Feedback>
                <CircleIcon>
                  <img
                    src={
                      data.status === 'accept' ||
                      data.status === 'finished' ||
                      data.status === 'paid'
                        ? finished
                        : time
                    }
                    alt="icone em andamento"
                  />
                </CircleIcon>
                <small>Em andamento</small>
              </Feedback>
              <Feedback>
                <CircleIcon>
                  <img
                    src={
                      (data.establishment.have_tables &&
                        (data.status === 'finished' ||
                          data.status === 'paid')) ||
                      (!data.establishment.have_tables &&
                        data.status === 'paid' &&
                        data.reservation.status === 'finished')
                        ? finished_request
                        : request
                    }
                    alt="icone finalizado"
                  />
                </CircleIcon>
                <small>Finalizado</small>
              </Feedback>
            </StatusFeedback>
          </OrderStatus>
        </OrderContent>
        <CustomerSection>
          <Customer>
            <img src={data.user.avatar_url} alt="foto docliente" />
            <h3>
              {data.user.first_name} {data.user.last_name}
            </h3>
            <span>{renderTableNumber()}</span>
            <Evaluation>
              Avaliação
              <Stars>
                <AiFillStar
                  className={parseInt(data.user.rating) > 0 ? 'active' : ''}
                />
                <AiFillStar
                  className={parseInt(data.user.rating) > 1 ? 'active' : ''}
                />
                <AiFillStar
                  className={parseInt(data.user.rating) > 2 ? 'active' : ''}
                />
                <AiFillStar
                  className={parseInt(data.user.rating) > 3 ? 'active' : ''}
                />
                <AiFillStar
                  className={parseInt(data.user.rating) > 4 ? 'active' : ''}
                />
              </Stars>
            </Evaluation>
          </Customer>
          {renderDateCheckIn()}
        </CustomerSection>
      </Row>
      <SuccessModal
        openModal={successModalOpen}
        setOpenModal={setSuccessModalOpen}
        text="Pedido Cancelado
com sucesso!"
      />
      <Modal
        footer={null}
        visible={warningModal}
        onCancel={() => setWarningModal(false)}
        bodyStyle={{ borderRadius: 20 }}
        style={{ borderRadius: 20 }}
        wrapClassName="modal-warning"
      >
        <WarningTitle>
          Tem certeza que gostaria de cancelar esse pedido?
        </WarningTitle>
        <WarningSubtitle>
          Caso tenha passado 1 minuto desde o momento do pedido, será cobrado
          uma multa de cancelamento fora do prazo.
        </WarningSubtitle>
        <WarningContent>
          <WarningYes onClick={() => handleCancelOrder(orderId)}>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              <>
                <AcceptIcon />
                SIM
              </>
            )}
          </WarningYes>
          <WarningNo onClick={() => setWarningModal(false)}>
            <RefuseIcon />
            NÃO
          </WarningNo>
        </WarningContent>
      </Modal>
    </Order>
  );
};
