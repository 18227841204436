import React, {
  useContext,
  createContext,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { idText } from 'typescript';
import api from '../services/api';

import { useAuth } from './Auth';

interface TableManagementContextProps {
  handleNewNumberOfTables(tableNumber: number): Promise<void>;
  handleDeleteNumberOfTables(tableNumber: number): void;
  deleteEstablishmentTables(items: string[]): void;
  updateTableInfos(
    tableId: string,
    tableInfos: TableInfosProps,
    nameOfTable: string,
  ): void;
  getEstablishmentTables(): Promise<void>;
  establishmentTables: EstablishmentTables[];
  setEstablishmentTables: Dispatch<SetStateAction<EstablishmentTables[]>>;
  setTableIdAllocation: Dispatch<SetStateAction<string>>;
  setTableReallocation: Dispatch<SetStateAction<boolean>>;
  tableReallocation: boolean;
  tableIdAllocation: string;
}
interface EstablishmentTables {
  id: string;
  name: string;
  amount_chairs: number;
  is_available: boolean;
}

interface TableAllocationProp {
  id: string;
}

interface TableInfosProps {
  amountChairs: string;
  isAvailable: boolean;
}

const TableManagementContext = createContext<TableManagementContextProps>(
  {} as TableManagementContextProps,
);

const TableManagementProvider: React.FC = ({ children }) => {
  const { data, setData } = useAuth();

  const [establishmentTables, setEstablishmentTables] = useState<
    EstablishmentTables[]
  >([] as EstablishmentTables[]);

  const [tableIdAllocation, setTableIdAllocation] = useState('');
  const [tableReallocation, setTableReallocation] = useState(false);

  const handleNewNumberOfTables = async (tableNumber: number) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    const response = await api.post(
      `/tables`,
      {
        amountTables: tableNumber,
      },
      { headers },
    );
  };

  const handleDeleteNumberOfTables = async (items: number) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.delete(`/tables/amount`, {
      data: { amountTables: items },
      headers: { Authorization: `Bearer ${data.accessToken}` },
    });
  };

  const getEstablishmentTables = async () => {
    const today = new Date();

    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.get<EstablishmentTables[]>(
      `/tables/available?date=${today}&establishmentId=${data.user.establishment?.id}`,
      {
        headers,
      },
    );
    setEstablishmentTables(response.data);
  };

  const updateTableInfos = async (
    tableId: string,
    tableInfos: TableInfosProps,
    nameOfTable: string,
  ) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.put<EstablishmentTables[]>(
      `/tables/${tableId}`,
      {
        ...tableInfos,
        name: nameOfTable,
        amountChairs: Number(tableInfos.amountChairs),
      },
      {
        headers,
      },
    );
  };

  const deleteEstablishmentTables = async (items: string[]) => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.delete(`/tables`, {
      data: { tableIds: items },
      headers: { Authorization: `Bearer ${data.accessToken}` },
    });
  };

  return (
    <TableManagementContext.Provider
      value={{
        updateTableInfos,
        handleNewNumberOfTables,
        getEstablishmentTables,
        establishmentTables,
        setEstablishmentTables,
        deleteEstablishmentTables,
        setTableIdAllocation,
        tableIdAllocation,
        handleDeleteNumberOfTables,
        setTableReallocation,
        tableReallocation,
      }}
    >
      {children}
    </TableManagementContext.Provider>
  );
};

const useTableManagement = (): TableManagementContextProps => {
  const context = useContext(TableManagementContext);

  if (!context) {
    throw new Error('useFoodMenu must be used within an TableManagement');
  }

  return context;
};

export { TableManagementProvider, useTableManagement };
