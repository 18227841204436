import styled from 'styled-components';

export const ForgotTitle = styled.h1`
  color: #21e270;
  font-size: 22px;
  font-weight: bold;
`;

export const ForgotSubtitle = styled.p`
  padding: 4rem;
  text-align: center;
`;
