import { TimePicker } from 'antd';
import moment from 'moment';

import './customize.css';

import { Container } from './_SelectHour';

interface Props {
  defaultHour?: string;
}

export const SelectHour = ({ defaultHour = '10:00', ...rest }: Props) => {
  const format = 'HH:mm';

  return (
    <Container>
      <TimePicker
        defaultValue={moment(defaultHour, format)}
        showNow={false}
        placeholder={'Selecione a hora'}
        format={format}
        {...rest}
      />
    </Container>
  );
};
