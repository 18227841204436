import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/Auth';
import { Select, Switch } from 'antd';
import { toast } from 'react-toastify';

import api from '../../services/api';

import { SuccessModal } from 'shared/components/SuccessModal/SuccesModal';
import { Orders } from './components/Orders/Orders';
import { WrapperPage } from 'shared/components/WrapperPage/WrapperPage';
import { Spinner } from 'shared/components/Spinner/Spinner';
import { SpinnerModal } from 'shared/components/SpinnerModal/SpinnerModal';
import NonAutomaticOrderIcon from 'shared/icons/non-automatic-order-icon.svg';
import AutomaticOrderIcon from 'shared/icons/automatic-order-icon.svg';

import {
  RequestsWrapper,
  OrdersTitleContainer,
  ShowMore,
  ContainerAutomaticallyAcceptOrdes,
} from './_RequestManagement';
import { NotificationsModal } from 'shared/components/NotificationsModal/NotificationsModal';
import { useSocketIo } from '../../hooks/Socket';

interface FetchRequestProps {
  results: RequestProps[];
  page: number;
  limit: number;
  total: number;
}

export interface RequestProps {
  id: string;
  order_number: number;
  final_price: string;
  status: string;
  closing_time: string;
  user: UserProps;
  order_items: OrderItem[];
  created_at: string;
  reservation: ReservationProps;
  description: string;
  establishment: {
    have_tables: boolean;
  };
  reservation_id: string;
  user_id: string;
  form_of_payment: string;
}

interface ReservationProps {
  date: string;
  date_checkin: string | null;
  table: {
    name: string;
  };
  status: string;
}

interface UserProps {
  id: string;
  first_name: string;
  last_name: string;
  rating: string;
  avatar_url: string;
}

interface OrderItem {
  id: string;
  description: string;
  time_in_minutes: number;
  quantity: number;
  item_price: string;
  final_price: string;
  product_id: string;
  order_id: string;
  product: ProductProps;
}

interface ProductProps {
  name: string;
}

export const RequestManagement = () => {
  const { data, setData } = useAuth();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMoreSolicitations, setLoadingMoreSolicitations] =
    useState(false);
  const [page, setPage] = useState<number>(1);
  const [requests, setRequests] = useState<RequestProps[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [filterSolicitations, setFilterSolicitations] = useState<string>('');
  const [loadingAccepOrDenyRequest, setLoadingAccepOrDenyRequest] =
    useState(false);
  const [automateOrders, setAutomateOrders] = useState(
    data.user.establishment?.automate_orders,
  );
  const [loadingAutomateOrders, setLoadingAutomateOrders] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');

  const { handleOrderPending, setRecallOrderList, recallOrderList } =
    useSocketIo();

  const { Option } = Select;

  useEffect(() => {
    if (recallOrderList) {
      setRecallOrderList(false);
      fetchRequests(true, statusSelected, false);
    }
  }, [recallOrderList]);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async (reset = false, status = '', addPage = false) => {
    if (addPage) {
      setLoadingMoreSolicitations(true);
    } else {
      setLoading(true);
    }

    try {
      const headers = { Authorization: `Bearer ${data.accessToken}` };

      let index = page;

      if (addPage) {
        index = index + 1;
        setPage(index);
      }

      if (reset) index = 1;

      let route = `/orders/establishments?establishmentId=${data.user.establishment?.id}&page=${index}&limit=20`;

      if (status) {
        route += `&status=${status}`;
      }

      const response = await api.get<FetchRequestProps>(route, {
        headers,
      });

      if (reset) {
        setRequests(response.data.results);
      } else {
        setRequests([...requests, ...response.data.results]);
      }

      setTotal(response.data.total);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      if (addPage) {
        setLoadingMoreSolicitations(false);
      } else {
        setLoading(false);
      }
    }
  };

  const handleRequest = async (
    id: string,
    status: string,
    paymentCash = false,
    paymentCashData = {
      reservationId: '',
      customerId: '',
    },
  ) => {
    try {
      setLoadingAccepOrDenyRequest(true);

      const headers = { Authorization: `Bearer ${data.accessToken}` };
      if (!paymentCash) {
        await api.post(
          `/orders/${id}/${status}`,
          {
            establishmentId: data.user.establishment?.id,
          },
          { headers },
        );
      } else {
        await api.post(
          `/payments/cash`,
          {
            reservationId: paymentCashData.reservationId,
            customerId: paymentCashData.customerId,
          },
          { headers },
        );
      }

      const newRequests = requests.map((request) => {
        if (request.id === id) {
          return { ...request, status };
        }
        return request;
      });

      setRequests(newRequests);

      setLoadingAccepOrDenyRequest(false);

      if (status === 'accept') {
        setModalText('Pedido aceito com sucesso!');
      }

      if (status === 'refuse') {
        setModalText('Pedido recusado com sucesso!');
      }

      if (status === 'finished') {
        setModalText('Pedido finalizado com sucesso!');
      }

      setOpenModal(!openModal);
      handleOrderPending();
    } catch (error) {
      setLoadingAccepOrDenyRequest(false);
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const handleLoadMore = () => {
    fetchRequests(false, filterSolicitations, true);
  };

  const handleAutomaticallyOrders = async (value: boolean) => {
    setLoadingAutomateOrders(true);
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    try {
      await api.patch(
        `/establishments/automate-orders?id=${data.user.establishment?.id}`,
        {
          automateOrders: value,
        },
        {
          headers,
        },
      );

      if (value) {
        toast.success('Os pedidos serão aceitos automaticamente');
      } else {
        toast.success('Os pedidos não serão mais aceitos automaticamente');
      }
      setAutomateOrders(value);
      setData({
        ...data,
        user: {
          ...data.user,
          establishment: {
            ...data.user.establishment,
            automate_orders: value,
          },
        },
      });
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoadingAutomateOrders(false);
    }
  };

  const renderOrders = () => {
    if (loading) {
      return <Spinner />;
    }

    return requests.map((request) => (
      <Orders data={request} handleRequest={handleRequest} />
    ));
  };

  const renderShowMoreRequests = () => {
    if (loading) {
      return;
    }

    if (loadingMoreSolicitations) {
      return <Spinner />;
    }

    if (requests.length < total) {
      return (
        <ShowMore
          onClick={() => {
            handleLoadMore();
          }}
        >
          Carregar mais solicitações
        </ShowMore>
      );
    }
  };

  const renderIconAcceptOrders = () => {
    if (loadingAutomateOrders) {
      return <Spinner />;
    }

    if (automateOrders) {
      return (
        <img
          src={AutomaticOrderIcon}
          alt="Ícone de que o estabelecimento está aceitando pedidos automaticamente"
        />
      );
    }

    return (
      <img
        src={NonAutomaticOrderIcon}
        alt="Ícone de que o estabelecimento não está aceitando pedidos automaticamente"
      />
    );
  };

  return (
    <WrapperPage title="Cadastro">
      <RequestsWrapper>
        <OrdersTitleContainer>
          <h2>Gestão de Pedidos</h2>
          <div>
            <ContainerAutomaticallyAcceptOrdes>
              {renderIconAcceptOrders()}
              <Switch
                checked={automateOrders}
                onChange={(value) => handleAutomaticallyOrders(value)}
              />
            </ContainerAutomaticallyAcceptOrdes>
            <Select
              defaultValue=""
              bordered={false}
              onChange={(value) => {
                setStatusSelected(value);
                setPage(1);
                setFilterSolicitations(value);
                fetchRequests(true, value);
              }}
            >
              <Option value="">Solicitações</Option>
              <Option value="pending">Pendentes</Option>
              <Option value="accept">Em progresso</Option>
              <Option value="finished">Finalizados</Option>
              <Option value="refuse">Recusados</Option>
            </Select>
          </div>
        </OrdersTitleContainer>
        {renderOrders()}
        {renderShowMoreRequests()}
        <SuccessModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          text={modalText}
        />
      </RequestsWrapper>
      <SpinnerModal openModal={loadingAccepOrDenyRequest} />
      <NotificationsModal />
    </WrapperPage>
  );
};
