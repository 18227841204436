import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';

import success from '../../icons/success.png';
import fail from '../../icons/fail.png';
import exit from '../../icons/exit.png';

import { Button } from '../Button/Button';

import { Text, Icon, Row, ButtonContainer } from './_SolicitationModal';

export interface solicitationDataModalProps {
  idSolicitation: string;
  idEstablishment: string;
  idTable?: string;
}

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  text: string;
  modalText: string;
  solicitationDataModal: solicitationDataModalProps;
  handleSolicitation(status: string): void;
}

export const SolicitationModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  text,
  modalText,
  handleSolicitation,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: '403px',
      width: '280px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };

  const handleConfirm = () => {
    if (modalText === 'aceitar') {
      handleSolicitation('accept');
    } else if (modalText === 'recusar') {
      handleSolicitation('refuse');
    } else {
      handleSolicitation('checkin');
    }

    setOpenModal(false);
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={customStyles}
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenModal(false)}
        />
      </Row>
      <Text>{text}</Text>
      <Icon src={modalText === 'aceitar' ? success : fail} />

      <ButtonContainer>
        <Button
          style={{ background: '#21E270' }}
          onClick={() => handleConfirm()}
        >
          Confirmar
        </Button>
        <Button
          onClick={() => setOpenModal(false)}
          style={{ background: 'red' }}
        >
          Cancelar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
