import { Select as SelecteAntd, SelectProps } from 'antd';
import { Container, Text } from './_Select';
import { BiChevronDown } from 'react-icons/bi';

interface Props extends SelectProps<string> {
  data: ElementProps[];
  actualCategory?: string;
}

interface ElementProps {
  id: string;
  name: string;
}

export const Select = ({ data, actualCategory, ...rest }: Props) => {
  const { Option } = SelecteAntd;

  const renderOptions = () => {
    return data.map((element, index) => (
      <Option
        selected={index}
        style={{
          color: 'var(--descripionText)',
          fontFamily: 'FontsFreeNetSFProTextLight',
        }}
        value={element.id}
        key={index}
      >
        {element.name}
      </Option>
    ));
  };

  return (
    <Container>
      <SelecteAntd
        suffixIcon={<BiChevronDown size={40} />}
        bordered={false}
        {...rest}
        placeholder={actualCategory}
      >
        {renderOptions()}
      </SelecteAntd>
    </Container>
  );
};
