import styled from 'styled-components';

export const Container = styled.div`
 @media (max-width: 440px) {
   width: 100%;
  }
`;

export const Card = styled.div`
  width: 350px;
  display: flex;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border-radius: 20px;
  padding: 10px;
  margin: 20px;

  @media (max-width: 440px) {
   width: 100%;
   margin: 20px auto;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #94FFC1;
  border-radius: 10px;

  @media (max-width: 310px) {
   padding: 8px;
  }
`;

export const Description = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.2rem;
  color: var(--descripionText);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 200px;
  overflow-wrap: anywhere;
`;

export const EditContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  cursor: pointer;

  span {
    font-family: 'FontsFreeNetSFProTextMedium';
    color: #C7C7CC;
    margin-left: 10px;
  }
`;
