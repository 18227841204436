import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { WrapperPage } from '../../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../../shared/components/Input/Input';
import { Button } from '../../../../shared/components/Button/Button';
import { Select as SelectMultiple } from './components/Select/Select';
import { SuccessModal } from '../../../../shared/components/SuccessModal/SuccesModal';
import { SelectHour } from '../../../../shared/components/SelectHour/SelectHour';

import { onlyNumberMask } from '../../../../utils/Masks';

import editIcon from '../../../../shared/icons/editIcon.png';
import profileEstablishment from '../../../../shared/icons/profile-establishment.png';

import {
  Title,
  Row,
  FormContainer,
  Form,
  InputContainer,
  ListButtons,
  SelectContainer,
  Divider,
  ButtonGreen,
  PersonalData,
  ProfilePhotoContainer,
  Fields,
  AdditionalInformation,
  RowInput,
  Operation,
  RowInputHour,
  Address,
  DividerRowIcon,
} from './_EstablishmentProfile';
import {
  useFinalizeRegistration,
  NewAddressProps,
  NewInfosProps,
  UploadNewImage,
} from '../../../../hooks/FinalizeRegistration';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/Auth';
import api from 'services/api';
import { SpinnerModal } from 'shared/components/SpinnerModal/SpinnerModal';
import { Select } from 'pages/Settings/components/Select/Select';
import BusinessHours from './components/BusinessHours/BusinessHours';
import image from 'antd/lib/image';
import { NotificationsModal } from '../../../../shared/components/NotificationsModal/NotificationsModal';
import { SwitchContainer } from 'pages/FoodMenu/pages/RegisterNewMenu/_RegisterNewMenu';
import { Switch } from 'antd';

interface ViaCepFields {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface EstablishmentImage {
  image_url: string;
}

export const EstablishmentProfile = () => {
  const { data, setData } = useAuth();
  console.log(data)

  const [establishmentImage, setEstablishmentImage] = useState(
    {} as EstablishmentImage,
  );
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [newAddressData, setNewAddressData] = useState({} as NewAddressProps);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [newImage, setNewImage] = useState({} as UploadNewImage);
  const [imgName, setImgName] = useState('');
  const [codeMCC, setCodesMCC] = useState([]);
  const [newInfos, setNewInfos] = useState({} as NewInfosProps);
  const [newImgLoaded, setNewImgLoaded] = useState('');
  const [erpEstablishmentCode, setErpEstablishmentCode] = useState('')
  const [tipEnabled, setTipEnabled] = useState(false);

  const [defaultCategorie, setDefaultCategorie] = useState('');

  const getEstablishmentImg = async () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    const response = await api.get(`/users/establishments/own`, { headers });
    setNewImgLoaded(response.data.image_url);
  };

  useEffect(() => {
    fetchMCC();
    getCategoriesOfEstablishment();
    getEstablishmentImg();
  }, []);

  useEffect(() => {
    if(data.user.establishment){
      setTipEnabled(Boolean(data?.user?.establishment?.tip_enabled))
    }
  },[data])

  const fetchMCC = () => {
    api
      .get('/establishments/mcc')
      .then((response) => setCodesMCC(response.data))
      .catch((error) =>
        toast.error(error.response?.data?.message || error.toString()),
      );
  };

  const {
    changeEstablishmentAddress,
    changeEstablishmentInfos,
    getCategoriesOfEstablishment,
    establishmentCategories,
    getEstablishmentBusinessHours,
    updateEstablishmentImg,
    createTOTVSCode,
    handleUpdateTip,
  } = useFinalizeRegistration();

  useEffect(() => {
    const getEstablishmentAddress = async () => {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const response = await api.get(`/addresses/`, { headers });
      setNewAddressData(response.data);
    };

    const getEstablishmentInfos = async () => {
      const headers = { Authorization: `Bearer ${data.accessToken}` };
      const response = await api.get(`/users/establishments/own`, { headers });
      setNewInfos({
        mcc: response.data.mcc,
        companyName: response.data.company_name,
        statementDescriptor: response.data.statement_descriptor,
        totalCapacity: response.data.total_capacity,
        categoryId: response.data.category_id,
      });
    };

    getEstablishmentInfos();
    getEstablishmentAddress();
    getEstablishmentBusinessHours();
  }, []);

  const fetchAddressData = () => {
    axios
      .get<ViaCepFields>(`https://viacep.com.br/ws/${newAddressData.cep}/json/`)
      .then((response) => {
        if (response.data.cep) {
          setNewAddressData({
            ...newAddressData,
            neighborhood: response.data.bairro,
            city: response.data.localidade,
            uf: response.data.uf,
            street: response.data.logradouro,
          });
        } else {
          toast.error('Preencha o CEP corretamente');
        }
      })
      .catch((error) =>
        toast.error(error.response?.message?.data || error.toString()),
      );
  };

  const validateFormFields = () => {
    if (newInfos.totalCapacity.toString().length === 0) {
      toast.error(
        'O campo capacidade não pode ser vazio, caso você não possua capacidade total, preencha 0',
      );
      return false;
    }

    if (!newInfos.companyName) {
      toast.error('Preencha o nome da empresa corretamente');
      return false;
    }

    if (!newInfos.statementDescriptor) {
      toast.error('Preencha a razão social corretamente');
      return false;
    }

    if (!newInfos.mcc) {
      toast.error('Preencha o código de atividade corretamente');
      return false;
    }

    if (!newAddressData.cep.trim()) {
      toast.error('Preencha o CEP corretamente');
      return false;
    }

    if (!newAddressData.street.trim()) {
      toast.error('Preencha a rua corretamente');
      return false;
    }

    if (!newAddressData.neighborhood.trim()) {
      toast.error('Preencha o bairro corretamente');
      return false;
    }

    if (!newAddressData.city.trim()) {
      toast.error('Preencha a cidade corretamente');
      return false;
    }

    if (!newAddressData.cep.trim()) {
      toast.error('Preencha o CEP corretamente');
      return false;
    }

    if (!newAddressData.number.trim()) {
      toast.error('Preencha o número do estabelecimento corretamente');
      return false;
    }

    return true;
  };

  // const handleDefaultCategorie = () => {
  //   return establishmentCategories.filter((item) => {
  //     if (item.id == data.user.establishment?.category_id) {
  //       setDefaultCategorie(item.name);
  //     }
  //   });
  // };

  const handleSendNewInformations = async (image: UploadNewImage) => {
    if (!validateFormFields()) {
      return;
    }
    setLoadingAddress(true);
    try {
      if (Object.keys(image).length !== 0) {
        await updateEstablishmentImg(image);
      }

      await changeEstablishmentAddress(newAddressData);

      if(Object.entries(newInfos).length !== 0) {
        const { categoryId, ...rest } = newInfos;

        const formDataNewInfos = {
          ...rest,
        } as NewInfosProps;

        if(categoryId){
          formDataNewInfos.categoryId = categoryId;
        }

        await changeEstablishmentInfos(formDataNewInfos);
      }

      if (!!erpEstablishmentCode) {
        await createTOTVSCode(erpEstablishmentCode);
      }

      setLoadingAddress(false);
      setShowSuccessModal(true);
    } catch (e: any) {
      setLoadingAddress(false);
      toast.error(e?.response?.data?.message || e.toString());
    }
  };

  const handleOnBlurCepField = () => {
    if (newAddressData.cep) {
      if (newAddressData.cep.length === 8) {
        fetchAddressData();
      } else {
        toast.error('Preencha o CEP corretamente');
      }
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileList = e.target.files;
    if (fileList) {
      setNewImage({ image: fileList[0] });
      setImgName(fileList[0].name);
      const objectUrl = URL.createObjectURL(fileList[0]);
      var image = new Image();
      image.src = objectUrl;
      setNewImgLoaded(objectUrl);
    }
  };



  return (
    <WrapperPage title="Configurações">
      <Title>Perfil do Estabelecimento</Title>
      <Row>
        <FormContainer>
          <Form>
            <PersonalData>
              <ProfilePhotoContainer>
                <div>
                  <img
                    src={newImgLoaded}
                    alt="Foto de perfil do estabelecimento"
                  />
                </div>
                <div>
                  <img src={editIcon} alt="Ícone de editar foto de perfil" />
                  <Button type="button">
                    <label htmlFor="upload-document">
                      <input
                        id="upload-document"
                        accept="*"
                        type="file"
                        onChange={(e) => handleImageChange(e)}
                      />
                      Alterar foto
                    </label>
                  </Button>
                </div>
              </ProfilePhotoContainer>
              <Fields>
                <label>Nome do Estabelecimento:</label>
                <InputContainer>
                  <Input
                    value={newInfos.companyName}
                    onChange={(event) =>
                      setNewInfos({
                        ...newInfos,
                        companyName: event.target.value,
                      })
                    }
                    iconRight={editIcon}
                  />
                </InputContainer>
                <label>Razão Social:</label>
                <InputContainer>
                  <Input
                    placeholder={data.user.establishment?.statement_descriptor}
                    value={newInfos.statementDescriptor}
                    iconRight={editIcon}
                    onChange={(event) =>
                      setNewInfos({
                        ...newInfos,
                        statementDescriptor: event.target.value,
                      })
                    }
                  />
                </InputContainer>
                <label>Código de Atividade:</label>
                <InputContainer>
                  <Select
                    showCodeIntoOption
                    value={newInfos.mcc}
                    placeholder={newInfos.mcc}
                    data={codeMCC}
                    onChange={(value) =>
                      setNewInfos({ ...newInfos, mcc: value })
                    }
                  />
                </InputContainer>
              </Fields>
            </PersonalData>

            <AdditionalInformation>
              <p>Informações Adicionais</p>
              <RowInput>
                <div>
                  <label>Categoria de Serviço:</label>
                  <InputContainer>
                    <SelectMultiple
                      placeholder={`${defaultCategorie}`}
                      actualCategory={defaultCategorie}
                      value={newInfos.categoryId}
                      data={establishmentCategories}
                      onChange={(value) =>
                        setNewInfos({ ...newInfos, categoryId: value })
                      }
                    />
                  </InputContainer>
                </div>
                <div>
                  <label>Capacidade:</label>
                  <InputContainer>
                    <Input
                      iconRight={editIcon}
                      value={newInfos.totalCapacity}
                      onChange={(event) =>
                        setNewInfos({
                          ...newInfos,
                          totalCapacity: onlyNumberMask(event.target.value),
                        })
                      }
                    />
                  </InputContainer>
                </div>
              </RowInput>
            </AdditionalInformation>

            <BusinessHours />

            <Address>
              <p>Endereço</p>
              <RowInput>
                <div>
                  <label>CEP:</label>
                  <InputContainer>
                    <Input
                      value={newAddressData.cep}
                      iconRight={editIcon}
                      onChange={(event) =>
                        setNewAddressData({
                          ...newAddressData,
                          cep: event.target.value,
                        })
                      }
                      onBlur={() => handleOnBlurCepField()}
                    />
                  </InputContainer>
                </div>
                <DividerRowIcon />
                <div>
                  <label>Rua:</label>
                  <InputContainer>
                    <Input
                      value={newAddressData.street}
                      iconRight={editIcon}
                      onChange={(event) =>
                        setNewAddressData({
                          ...newAddressData,
                          street: event.target.value,
                        })
                      }
                    />
                  </InputContainer>
                </div>
              </RowInput>

              <RowInput>
                <div>
                  <label>Bairro:</label>
                  <InputContainer>
                    <Input
                      value={newAddressData.neighborhood}
                      onChange={(event) =>
                        setNewAddressData({
                          ...newAddressData,
                          neighborhood: event.target.value,
                        })
                      }
                      iconRight={editIcon}
                    />
                  </InputContainer>
                </div>
                <DividerRowIcon />
                <div>
                  <label>Cidade:</label>
                  <InputContainer>
                    <Input
                      value={newAddressData.city}
                      iconRight={editIcon}
                      onChange={(event) =>
                        setNewAddressData({
                          ...newAddressData,
                          city: event.target.value,
                        })
                      }
                    />
                  </InputContainer>
                </div>
              </RowInput>

              <RowInput>
                <div>
                  <label>Estado:</label>
                  <InputContainer>
                    <Input
                      value={newAddressData.uf}
                      iconRight={editIcon}
                      onChange={(event) =>
                        setNewAddressData({
                          ...newAddressData,
                          uf: event.target.value,
                        })
                      }
                    />
                  </InputContainer>
                </div>
                <DividerRowIcon />
                <div>
                  <label>Número:</label>
                  <InputContainer>
                    <Input
                      value={newAddressData.number}
                      iconRight={editIcon}
                      onChange={(event) =>
                        setNewAddressData({
                          ...newAddressData,
                          number: event.target.value,
                        })
                      }
                    />
                  </InputContainer>
                </div>
              </RowInput>
            </Address>

            <Address>
            <p>Informe a chave para integração com a TOTVS</p>
              <RowInput>
                <DividerRowIcon />
                <div>
                  <label>Código:</label>
                  <InputContainer>
                    <Input
                      value={erpEstablishmentCode}
                      iconRight={editIcon}
                      onChange={(event) =>
                        setErpEstablishmentCode(event?.target?.value)
                      }
                    />
                  </InputContainer>

                  <SwitchContainer statusEstablishment={tipEnabled} style={{width: '200px'}}>
                    <Switch
                      checked={tipEnabled}
                      onChange={() => handleUpdateTip(!tipEnabled)}
                    />
                    <span>Taxa de serviço</span>
                  </SwitchContainer>
                </div>
              </RowInput>
            </Address>
          </Form>
        </FormContainer>
        <ListButtons>
          <Button>
            <Link to="/configuracoes">Dados Pessoais</Link>
          </Button>
          <ButtonGreen>Estabelecimento</ButtonGreen>
          <Button>
            <Link to="/configuracoes/dados-bancarios">Dados Bancários</Link>
          </Button>
          <Button>
            <Link to="/configuracoes/termos-de-uso-e-privacidade">
              Termos de Uso
            </Link>
          </Button>
          <Divider />
          <ButtonGreen onClick={() => handleSendNewInformations(newImage)}>
            Salvar alterações
          </ButtonGreen>
        </ListButtons>
      </Row>
      <SuccessModal
        openModal={showSuccessModal}
        setOpenModal={setShowSuccessModal}
        text="Alterações salvas com sucesso!"
      />
      <SpinnerModal openModal={loadingAddress} />
      <NotificationsModal />
    </WrapperPage>
  );
};
