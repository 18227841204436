import { Select as SelecteAntd, SelectProps } from 'antd';
import { Container, Text } from './_Select';
import { BiChevronDown } from 'react-icons/bi';

interface Props extends SelectProps<string> {
  data: ElementProps[];
  showCodeIntoOption?: boolean;
}

interface ElementProps {
  id: string;
  code: string;
  description: string;
}

export const Select = ({ data, showCodeIntoOption, ...rest }: Props) => {
  const { Option } = SelecteAntd;

  const renderOptions = () => {
    if (showCodeIntoOption) {
      return data.map((element) => (
        <Option
          style={{
            color: 'var(--descripionText)',
            fontFamily: 'FontsFreeNetSFProTextLight',
          }}
          value={element.code + '&' + element.id}
          key={element.id}
        >
          {element.code} - {element.description}
        </Option>
      ));
    }

    return data.map((element) => (
      <Option
        style={{
          color: 'var(--descripionText)',
          fontFamily: 'FontsFreeNetSFProTextLight',
        }}
        value={element.code}
        key={element.id}
      >
        {element.description}
      </Option>
    ));
  };

  return (
    <Container>
      <SelecteAntd
        suffixIcon={<BiChevronDown size={40} />}
        bordered={false}
        {...rest}
      >
        {renderOptions()}
      </SelecteAntd>
    </Container>
  );
};
