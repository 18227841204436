import styled from 'styled-components';

interface Props {}

export const RequestsWrapper = styled.div`
  margin: 2em 0;
  animation: entering 0.8s;
`;

export const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  @media (min-width: 569px) {
    padding: 0 3em;
  }
  @media (min-width: 991px) {
    margin-left: 285px;
  }
`;

export const OrdersTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 965px;
  margin-bottom: 20px;

  h2 {
    width: 100%;
    min-width: 240px;
    max-width: 300px;
    font-size: 1.8rem;
    color: var(--gray);
    margin-bottom: 15px;
  }

  > div {
    display: flex;
    gap: 10px;
  }

  .ant-select {
    margin: 0 5px;
    min-width: 173px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.19);
    min-height: 47px;
    border-radius: 30px;
  }
  .ant-select-selector {
    min-width: 173px;
  }
  .ant-select-selection-item {
    padding: 7px !important;
    font-size: 16px;
    color: var(--light-gray);
    height: 47px;
  }
  .ant-select-arrow {
    color: var(--greenText);
    font-size: 14px;
    width: 26px;
  }

  @media (max-width: 696px) {
    justify-content: center;

    h2 {
      text-align: center;
    }
  }
`;

export const ShowMore = styled.p`
  font-family: 'FontsFreeNetSFProTextBold';
  color: var(--greenText);
  margin: 20px auto;
  text-align: center;
  cursor: pointer;
`;

export const ContainerAutomaticallyAcceptOrdes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 80px;
  background-color: white;
  padding: 8px 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 0px lightgrey;

  > img {
    width: 30px;
  }

  > div {
    width: 30px;
    height: 30px;
  }

  .ant-switch-checked {
    background-color: var(--greenText);
  }
`;
