import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';

import './modal-responsive.css';

import success from '../../icons/success.png';
import exit from '../../icons/exit.png';

import { Text, Icon, Row } from './_SuccessModal';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  text: string;
}

export const SuccessModal = ({
  openModal,
  setOpenModal,
  text,
  ...rest
}: Props) => {
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="success-modal-responsive"
      {...rest}
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenModal(false)}
        />
      </Row>
      <Text>{text}</Text>
      <Icon src={success} />
    </Modal>
  );
};
