import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { Button } from '../../../../../../shared/components/Button/Button';

import './modal-responsive.css';

import trashIcon from '../../../../../../shared/icons/trash-icon.png';
import pauseButton from '../../../../../../shared/icons/pause-button.png';
import exit from '../../../../../../shared/icons/exit.png';

import { MenuItemsProps, useFoodMenu } from '../../../../../../hooks/FoodMenu';

import { Text, Icon, Row, ButtonContainer } from './_HandleItem';
import { toast } from 'react-toastify';
import { MenuItemProps } from 'antd';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setShowDeletedItemSuccess: Dispatch<SetStateAction<boolean>>;
  setisItemInPause: Dispatch<SetStateAction<boolean>>;
  actionItem: string;
  itemID: string;
  activeStatus: boolean;
}

export const HandleItemModal: React.FC<Props> = ({
  openModal,
  itemID,
  activeStatus,
  setOpenModal,
  setisItemInPause,
  setShowDeletedItemSuccess,
  actionItem,
}) => {
  const { handleDeleteItemMenu, handlePauseItem } = useFoodMenu();

  const history = useHistory();

  const handleDeleteItem = (itemid: string, active: boolean) => {
    if (actionItem === 'delete') {
      handleDeleteItemSelected(itemid);
    } else {
      handlePauseItemSelected(itemid, active);
    }
  };

  const handleDeleteItemSelected = async (id: string) => {
    try {
      const deleteItem = await handleDeleteItemMenu(id);
      setOpenModal(false);
      setShowDeletedItemSuccess(true);
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const handlePauseItemSelected = async (id: string, active: boolean) => {
    try {
      const pauseeItem = await handlePauseItem(id, active);
      setOpenModal(false);
      setShowDeletedItemSuccess(true);
      setisItemInPause(!active);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  };

  const renderText = () => {
    if (actionItem === 'delete') {
      return <Text>Tem certeza que deseja excluir este item?</Text>;
    }

    if (actionItem === 'pause') {
      if (activeStatus) {
        return <Text>Tem certeza que deseja pausar este item?</Text>;
      } else {
        return <Text>Tem certeza que deseja despausar este item?</Text>;
      }
    }
  };

  const renderIcon = () => {
    if (actionItem === 'delete') {
      return trashIcon;
    }

    return pauseButton;
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={{ overlay: { backgroundColor: 'rgba(142,142,147, 0.6)' } }}
      className="handle-item-modal-responsive"
    >
      <Row>
        <img
          src={exit}
          alt="Ícone de sair"
          onClick={() => setOpenModal(false)}
        />
      </Row>
      {renderText()}
      <Icon src={renderIcon()} />
      <ButtonContainer>
        <Button
          onClick={() => handleDeleteItem(itemID, activeStatus)}
          style={{ background: '#21E270' }}
        >
          Confirmar
        </Button>
        <Button
          onClick={() => setOpenModal(false)}
          style={{ background: 'red', marginTop: '0px' }}
        >
          Cancelar
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
