import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button {
    background: #21e270;
    color: #fff;
    font-weight: bold;
  }
`;

export const ReviewTitle = styled.h1`
  color: #21e270;
  font-size: 20px;
  padding: 0rem 8rem;
  text-align: center;
  font-weight: bold;
`;

export const ReviewSubtitle = styled.p`
  color: #8e8e93;
  font-size: 15px;
  margin: 10px;
  font-family: 'FontsFreeNetSFProTextLight';
`;

export const ReviewText = styled.p`
  color: #8e8e93;
  font-size: 15px;
  font-family: 'FontsFreeNetSFProTextLight';
  margin: 10px;
`;

export const ReviewImg = styled.img`
  max-width: 150px;
  border-radius: 1rem;
`;

export const ReviewStars = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  img {
    width: 11px;
    height: 10px;
  }
  svg {
    font-size: 1.8rem;
    color: #c7c7cc;

    &.active {
      color: #ffcc00;
    }
  }
`;
