import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { WrapperPage } from '../../../../shared/components/WrapperPage/WrapperPage';
import { Input } from '../../../../shared/components/Input/Input';
import { Button } from '../../../../shared/components/Button/Button';
import { Select } from './components/Select/Select';
import { SuccessModal } from '../../../../shared/components/SuccessModal/SuccesModal';
import { RequestMachineModal } from './components/RequestMachineModal/RequestMachineModal';
import {
  CreateFullName,
  useFinalizeRegistration,
} from '../../../../hooks/FinalizeRegistration';

import {
  Title,
  Row,
  FormContainer,
  Form,
  InputContainer,
  ListButtons,
  SelectContainer,
  RequestMachine,
  RowInput,
  ButtonGreen,
  Divider,
} from './_BankData';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/Auth';
import { bankAgencyMask } from '../../../../utils/Masks';
import { onlyNumberMask } from '../../../../utils/Masks';
import { ConfirmPasswordModal } from './components/ConfirmPasswordModal/ConfirmPasswordModal';
import { NotificationsModal } from '../../../../shared/components/NotificationsModal/NotificationsModal';

interface UserBankProps {
  account_number: string;
  agency: string;
  bank_code_id: number;
  external_id: string;
  holder_name: string;
}

export const BankData = () => {
  const { saveTokenZOOP, handleDeleteAccount } =
    useFinalizeRegistration();

  const { data } = useAuth();

  const [modalPassword, setModalPassword] = useState(false);
  const [createDataBank, setCreateDataBank] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [excludeID, setExcludeID] = useState('');

  const [showSuccessRequestMachine, setShowSuccessRequestMachine] =
    useState(false);
  const [showRequestMachineModal, setShowRequestMachineModal] = useState(false);
  const [bankCodes, setBankCodes] = useState([]);
  const [typeAccount, setTypeAccount] = useState([
    { id: '1', code: 'checking', name: 'Conta corrente' },
    { id: '2', code: 'savings', name: 'Conta poupança' },
  ]);

  const [bankData, setBankData] = useState({
    holder_name: '',
    bank_code: '',
    routing_number: '',
    account_number: '',
    establishment_id: data.user.establishment?.id,
    type: '',
  });


  const fetchBanksCode = () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    api
      .get('/bank-codes', { headers })
      .then((response) => setBankCodes(response.data))
      .catch((error) =>
        toast.error(error.response?.data?.message || error.toString()),
      );
  };

  const fetchBankID = () => {
    const headers = { Authorization: `Bearer ${data.accessToken}` };
    api.get(`bank-accounts/?establishmentId=${data.user.establishment?.id}`, {
        headers,
      })
      .then((response) => {
        setExcludeID(response.data.id)
      })
      .catch((error) =>{
        if(error.response?.data?.message === 'Conta bancária não encontrada.'){
          return setCreateDataBank(true)
        }
        return toast.error(error.response?.data?.message || error.toString())
      })
  };

  const isThePasswordChecked = async () => {
    const response = await api.post('/sessions/establishments', {
      email: data.user.email,
      password: confirmPassword,
    });
  };

  const validateFormFields = () => {
    if (!bankData.bank_code.trim()) {
      toast.error('Escolha o banco corretamente');
      return false;
    }

    if (!bankData.routing_number.trim()) {
      toast.error(
        'Preencha sua agência bancária sem o dígito verificador corretamente',
      );
      return false;
    }

    if (!bankData.type.trim()) {
      toast.error('Escolha o tipo da sua conta corretamente');
      return false;
    }

    if (!bankData.account_number.trim()) {
      toast.error(
        'Preencha sua conta bancária com o dígito verificador corretamente',
      );
      return false;
    }

    return true;
  };

  const handleToChangeBanckInfos = () => {
    if (!validateFormFields()) {
      return;
    }

    setModalPassword(true);
  };

  const handleAttAccount = async () => {
    await isThePasswordChecked();

    try {
      if (!createDataBank) {
        await handleDeleteAccount(excludeID)
      }

      await saveTokenZOOP(bankData);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setCreateDataBank(false)
      setModalPassword(false)
      fetchBankID()
    }
  };

  useEffect(() => {
    fetchBankID();
    fetchBanksCode();
  }, []);

  return (
    <WrapperPage title="Configurações">
      <Title>Dados Bancários</Title>
      <Row>
        <FormContainer>
          <Form>
          <label>Nome do Portador:</label>
              <InputContainer>
                <Input
                  placeholder="Nome"
                  onChange={(event) =>
                    setBankData({
                      ...bankData,
                      holder_name: event.target.value,
                    })
                  }
                />
              </InputContainer>

            <label>Banco:</label>
            <SelectContainer>
              <Select
                placeholder="Nubank"
                showCodeIntoOption
                data={bankCodes}
                onChange={(value) =>
                  setBankData({ ...bankData, bank_code: value.split('&')[0] })
                }
              />
            </SelectContainer>

            <RowInput>
              <div>
                <label>Agência:</label>
                <InputContainer>
                  <Input
                    placeholder="0001"
                    onChange={(event) =>
                      setBankData({
                        ...bankData,
                        routing_number: bankAgencyMask(event.target.value),
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div>
                <label>Conta:</label>
                <InputContainer>
                  <Input
                    placeholder="238278"
                    onChange={(event) =>
                      setBankData({
                        ...bankData,
                        account_number: onlyNumberMask(event.target.value),
                      })
                    }
                  />
                </InputContainer>
              </div>
            </RowInput>

            <label>Tipo de Conta:</label>
            <SelectContainer>
              <Select
                placeholder="Tipo de conta"
                data={typeAccount}
                onChange={(value) => setBankData({ ...bankData, type: value })}
              />
            </SelectContainer>
          </Form>
        </FormContainer>
        <RequestMachine>
          <div>Maquininha</div>
          <p>Solicitar maquininha de cartão</p>
          <Button onClick={() => setShowRequestMachineModal(true)}>
            Solicitar
          </Button>
        </RequestMachine>
        <ListButtons>
          <Button>
            <Link to="/configuracoes">Dados Pessoais</Link>
          </Button>
          <Button>
            <Link to="/configuracoes/perfil-do-estabelecimento">
              Estabelecimento
            </Link>
          </Button>
          <ButtonGreen>Dados Bancários</ButtonGreen>
          <Button>
            <Link to="/configuracoes/termos-de-uso-e-privacidade">
              Termos de Uso
            </Link>
          </Button>
          <Divider />
          <ButtonGreen onClick={() => handleToChangeBanckInfos()}>
            Salvar alterações
          </ButtonGreen>
        </ListButtons>
      </Row>
      <SuccessModal
        openModal={showSuccessRequestMachine}
        setOpenModal={setShowSuccessRequestMachine}
        text="Maquininha solicitada com sucesso!"
      />
      <RequestMachineModal
        setShowSuccessModal={setShowSuccessRequestMachine}
        openModal={showRequestMachineModal}
        setOpenModal={setShowRequestMachineModal}
      />
      <ConfirmPasswordModal
        modalPassword={modalPassword}
        setModalPassword={setModalPassword}
        setConfirmPassword={setConfirmPassword}
        confirmPassword={confirmPassword}
        handleAttAccount={handleAttAccount}
      />
      <NotificationsModal />
    </WrapperPage>
  );
};
