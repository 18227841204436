import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 276px;
  height: 350px;
  margin: 0px 0 42px 26px;
  border-radius: 10%;
  box-shadow: lightgrey 0px 2px 8px -3px;

  @media (max-width: 460px) {
    margin: 0px 0px 42px 0;
  }

  .table-content {
    svg {
      font-size: 0.8rem;
      color: #c7c7cc;

      path {
        fill: #c7c7cc;
      }
    }
    button {
      color: #c7c7cc;
    }

    &.active {
      color: #21e270;

      svg {
        font-size: 0.8rem;
        color: #21e270;

        path {
          fill: #21e270;
        }
      }

      button {
        color: #21e270;
      }
    }
  }

  .checkin-content {
    &.active {
      color: #21e270;

      svg {
        font-size: 0.8rem;
        color: #21e270;

        path {
          fill: #21e270;
        }
      }

      button {
        color: #21e270;
      }
    }

    svg {
      font-size: 0.8rem;
      color: #c7c7cc;

      path {
        fill: #c7c7cc;
      }
    }
    button {
      color: #c7c7cc;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 276px;
  height: 269px;
  background: #f0f0f3;
  border-radius: 10%;
  cursor: pointer;

  > img {
    margin: 20px;
    max-width: 165px;
    max-height: 122px;
    margin-bottom: 15px;
  }

  > h4 {
    font-family: 'FontsFreeNetSFProTextBold';
    color: var(--greenText);
    font-size: 20px;
    overflow-wrap: anywhere;
    margin: 0px 10px;
    text-align: center;
  }

  p:nth-of-type(1) {
    font-family: 'FontsFreeNetSFProTextBold';
    color: #707070;
    font-size: 12px;
    margin: 5px auto 20px auto;
    text-align: center;
  }

  p:nth-of-type(2) {
    font-family: 'FontsFreeNetSFProTextBold';
    color: lightgrey;
    font-size: 13px;
    margin-bottom: 25px;

    text-align: center;
  }
`;

export const RateBox = styled.div`
  margin-bottom: 15px;

  svg {
    font-size: 0.8rem;
    color: #c7c7cc;
    &.active {
      color: #ffcc00;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 100px;
    padding: 10px;
    border-radius: 9px;
    margin-top: -15px;

    > p {
      font-family: 'FontsFreeNetSFProTextBold';
      color: lightgrey;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 0 0 40% 40%;

  > div {
    display: flex;
    justify-content: space-around;

    > div {
      display: flex;
      align-items: center;
      cursor: pointer;

      button {
        border: 0;
        background: #fff;
        color: lightgrey;
        font-family: 'FontsFreeNetSFProTextBold';
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;
