import React, { FormEvent, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import { useAuth } from '../../hooks/Auth';

import loginLogo from '../../shared/icons/login-logo.png';
import loginAnimation from '../../shared/icons/login-animation.png';

import { Input } from '../../shared/components/Input/Input';
import { Button } from '../../shared/components/Button/Button';

import {
  Container,
  FormContainer,
  Form,
  ImageContainer,
  Row,
  AccessYourAccount,
  InputContainer,
  SaveLogin,
  ForgotPassword,
  SignUp,
} from './_Login';
import { ForgotPasswordModal } from './components/ForgotPasswordModal/ForgotPasswordModal';
import api from 'services/api';

export const Login = () => {
  const history = useHistory();
  const { signIn, data } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveLogin, setSaveLogin] = useState(false);
  const [openForgotModal, setOpenForgotModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFormFields = () => {
    if (!email.trim() || !password.trim()) {
      return false;
    }
    return true;
  };

  async function handleForgetPasswordModal() {
    try {
      await api.post(`/password/forgot`, {
        email: email,
      });
      setOpenForgotModal(true);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  }

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!validateFormFields()) {
      toast.error('Digite corretamente seu e-mail e senha');
      return;
    }

    try {
      setLoading(true);

      await signIn({
        email,
        password,
        saveLogin,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  }

  const handleError = async (error: any) => {
    let message;
    if (error.response.data?.message) {
      message = error.response.data.message;
    } else {
      message = error.toString();
    }

    setLoading(false);
    toast.error(message);
  };

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <img src={loginLogo} alt="Login do Payment" />
          <h1>Bem-vindo(a) de volta!</h1>
          <AccessYourAccount>Acesse sua conta:</AccessYourAccount>
          <InputContainer>
            <Input
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Usuário"
            />
          </InputContainer>
          <InputContainer>
            <Input
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Senha"
              type="password"
            />
          </InputContainer>
          <Row>
            <SaveLogin>
              <input onClick={() => setSaveLogin(!saveLogin)} type="checkbox" />
              Salvar login
            </SaveLogin>
            <ForgotPassword onClick={() => handleForgetPasswordModal()}>
              Esqueci a senha
            </ForgotPassword>
          </Row>
          <Button>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              'Entrar'
            )}
          </Button>
          <SignUp>
            Ainda não possui acesso?{' '}
            <span onClick={() => history.push('/criar-conta')}>
              Cadastre-se
            </span>
          </SignUp>
        </Form>
      </FormContainer>
      <ImageContainer>
        <img src={loginAnimation} alt="Animação da tela de login" />
      </ImageContainer>
      <ForgotPasswordModal
        setOpenForgotModal={setOpenForgotModal}
        openForgotModal={openForgotModal}
      />
    </Container>
  );
};
