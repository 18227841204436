import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from 'antd';
import {
  ModalPassSubtitle,
  ModalPassTitle,
  ContentModal,
} from './_ConfirmPasswordModal';
import { Input } from 'shared/components/Input/Input';
import { Button } from 'shared/components/Button/Button';

interface PasswordModal {
  setModalPassword: Dispatch<SetStateAction<boolean>>;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
  modalPassword: boolean;
  confirmPassword: string;
  handleAttAccount(): Promise<void>;
}

export const ConfirmPasswordModal = ({
  modalPassword,
  setModalPassword,
  setConfirmPassword,
  confirmPassword,
  handleAttAccount,
}: PasswordModal) => {
  return (
    <Modal
      footer={null}
      visible={modalPassword}
      onCancel={() => setModalPassword(false)}
    >
      <ContentModal>
        <ModalPassTitle>Confirmar senha</ModalPassTitle>
        <ModalPassSubtitle>Digite sua Senha:</ModalPassSubtitle>
        <Input
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          onClick={() => handleAttAccount()}
          disabled={confirmPassword.length > 0 ? false : true}
        >
          Salvar alterações
        </Button>
      </ContentModal>
    </Modal>
  );
};
