import styled from 'styled-components';

interface StatusEstablishmentProps {
  statusEstablishment: boolean;
}

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin-top: 40px;

  @media (max-width: 440px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 40px;

  @media (max-width: 1024px) {
    margin: 20px auto;
  }

  @media (max-width: 430px) {
    padding: 20px;
    width: 100%;
  }
`;

export const RowInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 345px) {
    flex-direction: column;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }
`;

export const SelectContainer = styled.div`
  margin-top: 20px;

  > div > div {
    width: 100% !important;
  }
`;

export const Form = styled.form`
  > p {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.6rem;
    color: var(--greenText);
  }

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: var(--descriptionText);
    margin-top: 10px;
    display: block;
  }

  > button {
    background-color: var(--greenText);

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      cursor: pointer;
      > input {
        display: none;
      }
      > span {
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 1rem;
        color: #fff;
        overflow-wrap: anywhere;
      }
    }
  }

  @media (max-width: 440px) {
    > select {
      margin: 0;
    }

    > button {
      width: 100%;
    }
  }
`;

export const ListButtons = styled.div`
  > button {
    width: 260px;
  }

  > button:nth-of-type(1) {
    background-color: var(--greenText);
    color: #fff;
  }

  @media (max-width: 1140px) {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > button {
      margin: 20px;
    }
  }

  @media (max-width: 330px) {
    width: 100%;
  }
`;

export const Status = styled.div`
  width: 260px;
  padding: 20px;
  background-color: #efeff4;
  height: fit-content;
  border-radius: 30px;
  text-align: center;

  > div:nth-of-type(1) {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1.2rem;
    color: var(--descriptionText);
  }

  @media (max-width: 1024px) {
    margin: 20px auto;
  }

  @media (max-width: 330px) {
    width: 100%;
  }
`;

export const SwitchContainer = styled.div<StatusEstablishmentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 40px;
  padding: 8px;
  margin-top: 20px;

  > span {
    font-family: 'FontsFreeNetSFProTextMedium';
    color: ${(props) =>
      props.statusEstablishment ? 'var(--greenText)' : '#C7C7CC'};
    margin-left: 10px;

    > div {
      width: 22px;
      height: 22px;
    }
  }

  .ant-switch-checked {
    background-color: var(--greenText);
  }
`;

export const RegisterNewCategorieButton = styled.button`
  padding: 10px;
  border-radius: 14px;
  background-color: var(--greenText);
  border: 0;
  cursor: pointer;
`;
