import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextBold';
  font-size: 1.4rem;
  color: var(--greenText);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 1rem;
  color: var(--descripionText);
  text-align: center;
  margin-top: 40px;

  > span {
    font-family: 'FontsFreeNetSFProTextBold';
  }
`;

export const TextArea = styled.textarea`
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 0.8rem;
  border-radius: 10px;
  outline: transparent;
  margin: 20px auto;
  width: 100%;
  height: 70px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 8px 20px;
  border: none;
`;
