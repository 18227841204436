import { Modal, notification } from 'antd';
import { useAuth } from '../../../../hooks/Auth';
import React, { useState, useEffect, useMemo } from 'react';
import socketio, { io } from 'socket.io-client';
import { useTableManagement } from '../../../../hooks/TableManagement';
import { ReactComponent as TableIcon } from '../../../../shared/icons/table-icon-modal-checkin.svg';
import { useHistory } from 'react-router-dom';
import {
  AcceptModal,
  IconModal,
  RefuseModal,
  TitleModal,
  NameTitle,
  TableNumber,
  DateTitle,
  ImgAvatar,
  FooterModal,
  ModalContent,
} from './_ModalCheckin';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useSocketIo } from '../../../../hooks/Socket';

const ModalCheckin = ({
  modalCheckinOpen,
  setModalCheckinOpen,
  notifications,
}: any) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { data } = useAuth();
  const { setTableIdAllocation, tableIdAllocation } = useTableManagement();

  const { setRecallSolicitationList } = useSocketIo();

  const renderDate = (dateItem: string) => {
    const date = new Date(dateItem);

    const monthNumber = date.getMonth() + 1;
    const month =
      monthNumber.toString().length === 1 ? '0' + monthNumber : monthNumber;

    const day =
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate();

    const hours =
      date.getHours().toString().length === 1
        ? '0' + date.getHours()
        : date.getHours();

    const minutes =
      date.getMinutes().toString().length === 1
        ? '0' + date.getMinutes()
        : date.getMinutes();

    const dateFormated = day + '/' + month + '/' + date.getFullYear();

    return (
      <p>
        Agendado para: {dateFormated} às {hours}:{minutes}
      </p>
    );
  };

  async function acceptCheckIn(id: string) {
    const headers = { Authorization: `Bearer ${data.accessToken}` };

    try {
      await api.patch(
        `/reservations/${id}/checkin`,
        {
          establishmentId: data.user.establishment?.id,
        },
        { headers },
      );
      setRecallSolicitationList(true);
      setModalCheckinOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  }

  function renderCheckInModal() {
    return notifications.map((item: any, index: any) => (
      <ModalContent>
        <TitleModal>Solicitação de Check-in</TitleModal>
        <ImgAvatar src={item.user.avatar_url} alt="user avatar" />
        <NameTitle>{`${item.user.first_name} ${item.user.last_name}`}</NameTitle>
        <DateTitle>{renderDate(item.date)}</DateTitle>
        <TableNumber>Mesa número:{item.table.name}</TableNumber>
        <FooterModal>
          <AcceptModal onClick={() => acceptCheckIn(item.id)}>
            Aceitar Check-in
          </AcceptModal>

          <RefuseModal onClick={() => setModalCheckinOpen(false)}>
            Recusar Check-in
          </RefuseModal>
        </FooterModal>
      </ModalContent>
    ));
  }

  return (
    <Modal
      visible={modalCheckinOpen}
      footer={null}
      onCancel={() => setModalCheckinOpen(false)}
    >
      {renderCheckInModal()}
    </Modal>
  );
};

export default ModalCheckin;
