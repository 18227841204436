import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';

export const Title = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.6rem;
  color: var(--descripionText);
  margin-top: 40px;

  @media (max-width: 440px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 460px;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  padding: 20px 40px;

  @media (max-width: 1140px) {
    margin: 20px auto;
  }

  @media (max-width: 430px) {
    padding: 20px;
    width: 100%;
  }
`;

export const PersonalData = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: rgba(239, 239, 244, 0.33);
  padding: 20px;
  border-radius: 30px;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ProfilePhotoContainer = styled.div`
  > div:nth-of-type(1) {
    width: fit-content;
    border-width: 2px;
    border-style: solid;
    border-color: #21e270;
    overflow: hidden;
    border-radius: 20px;

    > img {
      width: 80px;

      margin-bottom: -3px;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    height: 20px;
    cursor: pointer;

    > button {
      width: 100px;
      min-height: 50px;
      box-shadow: unset;
      background: transparent;

      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        font-family: 'FontsFreeNetSFProTextMedium';
        font-size: 0.8rem;
        color: #8e8e93;
        cursor: pointer;
        margin-left: 4px;
        cursor: pointer;
        > input {
          display: none;
        }
        > span {
          font-family: 'FontsFreeNetSFProTextBold';
          font-size: 1rem;
          color: #fff;
          overflow-wrap: anywhere;
        }
      }
    }
  }
`;

export const Fields = styled.div`
  width: 60%;
  font-family: 'FontsFreeNetSFProTextLight';
  font-size: 0.8rem;
  color: #8e8e93;
  margin-top: 10px;
  display: block;

  > div > div {
    margin-top: 7px;
    margin-bottom: 20px;
    height: 40px;

    > input {
      font-size: 0.8rem;
    }
  }

  > div:nth-of-type(3) > div {
    width: 80%;
  }

  @media (max-width: 500px) {
    width: 100%;

    > div:nth-of-type(3) > div {
      width: 100%;
    }
  }
`;

export const AdditionalInformation = styled.div`
  margin-top: 20px;

  p {
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 1.4rem;
    color: #4cd964;
    margin-bottom: 8px;
  }

  > div > div {
    width: 45%;
  }

  @media (max-width: 500px) {
    > div > div {
      width: 100%;
    }
  }
`;

export const Operation = styled.div`
  margin-top: 20px;

  p {
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 1.4rem;
    color: #4cd964;
    margin-bottom: 8px;
  }

  label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    margin-top: 20px;
    display: block;
  }
`;

export const InputContainer = styled.div`
  > div {
    width: 100%;
  }

  .ant-select.ant-select-borderless.ant-select-single.ant-select-show-arrow {
    min-height: 40px;
    padding: 0;
    margin-top: 4px;
  }
  .ant-select-selection-item {
    font-size: 13px;
    color: #8e8e93;
  }

  .ant-select-selector {
    padding: 0 20px;
  }

  .ant-select-arrow {
    top: 10%;
  }
`;

export const SelectContainer = styled.div`
  margin-top: 20px;

  > div > div {
    width: 60% !important;
  }

  @media (max-width: 500px) {
    > div > div {
      width: 100% !important;
    }
  }
`;

export const Form = styled.form`
  > p {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 1.6rem;
    color: var(--greenText);
  }

  > label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    margin-top: 10px;
    display: block;
  }

  > button {
    font-family: 'FontsFreeNetSFProTextBold';
    font-size: 0.8rem;
    color: #fff;
    overflow-wrap: anywhere;
    overflow: hidden;
    background-color: var(--greenText);
    color: #fff;
    width: 70%;
    height: 40px;

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      cursor: pointer;
      > input {
        display: none;
      }
      > span {
        font-family: 'FontsFreeNetSFProTextBold';
        font-size: 1rem;
        color: #fff;
        overflow-wrap: anywhere;
      }
    }
  }

  @media (max-width: 440px) {
    > select {
      margin: 0;
    }

    > button {
      width: 100%;
    }
  }
`;

export const Next = styled.div`
  font-family: 'FontsFreeNetSFProTextMedium';
  font-size: 1.4rem;
  color: #4cd964;
  text-align: right;
  margin-top: 40px;
  cursor: pointer;
`;

export const ListButtons = styled.div`
  > button {
    width: 260px;
  }

  @media (max-width: 1140px) {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > button {
      margin: 20px;
    }
  }

  @media (max-width: 330px) {
    width: 100%;
  }
`;

export const ButtonGreen = styled(Button)`
  background-color: var(--greenText);
  color: #fff;
`;

export const Divider = styled.div`
  margin: 120px;

  @media (max-width: 1140px) {
    margin: 0px;
  }
`;

export const RowInput = styled.div`
  display: flex;
  justify-content: space-between;

  label {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    margin-top: 10px;
    display: block;
  }

  > div > div > div {
    margin-top: 7px;
    margin-bottom: 20px;
    height: 40px;

    > input {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RowInputHour = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 33px 1fr;

  > div {
    font-family: 'FontsFreeNetSFProTextLight';
    font-size: 1rem;
    color: #8e8e93;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }

  > div:nth-of-type(2) {
    margin: 0px 8px;
  }
`;

export const Address = styled.div`
  margin-top: 20px;

  p {
    font-family: 'FontsFreeNetSFProTextMedium';
    font-size: 1.4rem;
    color: #4cd964;
    margin-bottom: 8px;
  }

  > div {
    justify-content: flex-start;
  }

  > div:nth-of-type(1) > div {
    width: 45%;
  }

  > div:nth-of-type(2) {
    > div:nth-of-type(1) {
      width: 45%;
    }
    > div:nth-of-type(2) {
      width: 50%;
    }
  }

  > div:nth-of-type(3) {
    > div:nth-of-type(1) {
      width: 45%;
    }
    > div:nth-of-type(2) {
      width: 30%;
    }
  }

  @media (max-width: 500px) {
    > div:nth-of-type(1) > div {
      width: 100%;
    }

    > div:nth-of-type(2) {
      > div:nth-of-type(1) {
        width: 100%;
      }
      > div:nth-of-type(2) {
        width: 100%;
      }
    }

    > div:nth-of-type(3) {
      > div:nth-of-type(1) {
        width: 100%;
      }
      > div:nth-of-type(2) {
        width: 100%;
      }
    }
  }
`;

export const DividerRowIcon = styled.span`
  margin: 0px 8px;
`;
